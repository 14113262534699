import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const JournalHeader = () => {

    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const handleSignOut = (e) => {
        e.preventDefault();
        // Clear the token from local storage
        localStorage.removeItem('token');
        // Redirect to the home or login page
        navigate('/home');
    };

    const handleAccountClick = (e) => {
        e.preventDefault();
        navigate('/my-account/')
    }

    return(
        <div className="header-post">
            <header className="header-journal-entry">
                <a href="/home" className="brand-link"><h3><b>mapylon</b></h3></a>
                {!isMobile ? (
                    <div className="journal-nav-container">
                        <nav className="header-nav">
                            <ul>
                                <li><a style={{ color: '#171244' }} href="/home">Home</a></li>
                                <li><a style={{ color: '#171244' }} href="/home">Journals</a></li>
                                {/* <li><a style={{ color: '#171244' }} href="/about">About</a></li>
                                <li><a style={{ color: '#171244' }} href="/contact">Contact</a></li> */}
                                <li><button onClick={handleAccountClick} className="link-button-create" style={{ color: '#171244' }}>My Account</button></li>
                                <li><button onClick={handleSignOut} className="link-button-create" style={{ color: '#171244' }}>Sign Out</button></li>
                            </ul>
                        </nav>
                    </div>
                ) : (
                    <></>
                )}
            </header>
        </div>
    )
};
export default JournalHeader;