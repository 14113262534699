import React from 'react';

const PointIconModal = ({ isOpen, onSelect, onClose }) => {
    if (!isOpen) return null;

    const icons = [
        'archery.svg',
        'bike.svg',
        'binauculars.svg',
        'boat-ramp.svg',
        'bus.svg',
        'cable-car.svg',
        'camera.svg',
        'camp-tent.svg',
        'camper.svg',
        'campground.svg',
        'canoe.svg',
        'car.svg',
        'dog.svg',
        'downhill-ski.svg',
        'fire.svg',
        'fishing-pier.svg',
        'fishing.svg',
        'forest-camp.svg',
        'gas.svg',
        'helicopter.svg',
        'hike-up.svg',
        'hike.svg',
        'horse-riding.svg',
        'hospital.svg',
        // 'house.svg',
        'kayak.svg',
        // 'map-marker.svg',
        'mountain.svg',
        'ocean.svg',
        'park-2.svg',
        'park.svg',
        'picnic-site.svg',
        'plane.svg',
        'ranger-station.svg',
        'rv-park.svg',
        // 'sailing.svg',
        'shelter.svg',
        'skateboard.svg',
        'skating.svg',
        'sleeping-bag.svg',
        'snow-shoeing.svg',
        'snowboard.svg',
        'swimming.svg',
        'train.svg',
        'trash.svg',
        'water.svg',
        'wifi.svg',
        'xc-ski.svg',
    ]

    return (
        <div className="icon-modal-overlay" onClick={onClose}>
            <div className="icon-modal-content" onClick={e => e.stopPropagation()}>
                <div className="icon-grid">
                    {icons.map((icon, index) => (
                        <button key={index} className="icon-button" onClick={() => onSelect(icon)}>
                            <img src={`https://storage.googleapis.com/mapylon-images-static/map_icons/${icon}`} alt="" style={{ width: '5vh', height: '5vh', paddingTop: '0.5vh', paddingBottom: '0.5vh', marginLeft: '0' }} />
                        </button>
                    ))}
                </div>
                <button onClick={onClose} style={{ fontFamily: "'Chakra Petch', sans-serif", marginTop: '2vh' }}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default PointIconModal;
