//FormDate.js
const DateInput = ({ date, setDate, dateError }) => {
    return (
    <div className="form-group">
        <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
        />  
        {dateError && <div className="error-message" style={{ color: 'red' }}>{dateError}</div>}
    </div>
    );
  };
  
  export default DateInput;
  