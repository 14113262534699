// headerHome.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const HomeFooter = () => {

  const navigate = useNavigate();

  return (
    <div className="footer">
        <div className="footer-home">
            <div className="footer-logo">
                <img
                src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
                alt="Mapylon"
                className="small-image"
                />
            </div>
            <div className="footer-about">
                <h5>About</h5>
                <>
                    <li><a href="/about">About Mapylon</a></li>
                    <li><a href="/about/pricing">Pricing</a></li>
                    <li><a href="/about/terms-of-use">Terms of Use</a></li>
                    <li><a href="/about/privacy">Privacy Policy</a></li>
                </>
            </div>
            <div className="footer-help">
                <h5>Help</h5>
                <>
                    <li><a href="/help">Help Docs</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                </>
            </div>
        </div>
        <div className="footer-copyright">
            <p>Copyright 2024 Mapylon LLC. All Rights Reserved.</p>
        </div>
    </div>
  );
};

export default HomeFooter;
