// FormPhotos.js
import DisplayPhotoThumbnails from "./DisplayPhotoThumbnails";

const PhotosInput = ({ photos, setPhotos, openModal, initiateGeotagging, deletePhoto, handlePhotoChange }) => {
    return (
        <div className="form-group">
            <div className="custom-file-upload">
                <input type="file" id="photos" multiple onChange={handlePhotoChange} className="custom-file-input" />
                <button type="button" onClick={() => document.getElementById('photos').click()} className="custom-file-button">Upload Photos...</button>
                <label className="photo-upload-label" htmlFor="photos">Choose Photos...</label>
                {/* <span className="info-icon" data-tooltip="Once uploaded you can drag photos to change their order. Click the globe then click the map to geotag an image."> &#x3F;</span> */}
            </div>
            <div className="photo-thumbnails">
                <DisplayPhotoThumbnails
                    photos={photos}
                    setPhotos={setPhotos}
                    openModal={openModal}
                    initiateGeotagging={initiateGeotagging}
                    deletePhoto={deletePhoto}
                />
                </div>
        </div>
    );
  };
  
  export default PhotosInput;
  