import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import './MyAccount.css'; 
import HeaderHome from '../../HomePage/components/HeaderHome';

const MyAccount = () => {

    const navigate = useNavigate();
    const isLoggedIn = !!localStorage.getItem('token');
    const [showSessionTimeoutPopup, setShowSessionTimeoutPopup] = useState(false);
    const [userInfo, setUserInfo] = useState({
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        dateJoined: '',
        siteDesc: '',
        siteName: '',
    });
    const [isEditMode, setIsEditMode] = useState(false);
    //const [editableFields, setEditableFields] = useState({
    //    firstName: userInfo.firstName,
    //    lastName: userInfo.lastName,
    //    email: userInfo.email,
    //});

    const handleFieldChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const toggleEditMode = async(event) => {
        if (isEditMode) {

            event.preventDefault();

            try {
                console.log (userInfo)
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/update-account-info/`, userInfo, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Set state to true to show the session timeout popup
                    setShowSessionTimeoutPopup(true);
                } else {
                    console.error('Error updating post', error);
                }
            }


        }
        setIsEditMode(!isEditMode);
    };


    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/home');
        }
        const fetchUserSite = async () => {

            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-account-info/`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setUserInfo({
                        username: response.data.username,
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        email: response.data.email,
                        dateJoined: response.data.date_joined,
                        siteDesc: response.data.sitedesc,
                        siteName: response.data.sitename
                    });
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        // Set state to true to show the session timeout popup
                        setShowSessionTimeoutPopup(true);
                    } else {
                        console.error('Error fetching sitename', error);
                    }
                }
            }
        };
  
        if (isLoggedIn) {
            fetchUserSite();
        }
    }, [isLoggedIn, navigate]);

    const handleSessionTimeout = () => {
        // Close the popup
        setShowSessionTimeoutPopup(false);
    
        // Redirect to the home page
        localStorage.removeItem('token');
        navigate('/home');
    };

    const handleSignOut = (e) => {
        e.preventDefault();
        // Clear the token from local storage
        localStorage.removeItem('token');
        // Redirect to the home or login page
        navigate('/home');
      };

    const resetPw = () => {

    }

    const navigateHome = () => {
        navigate('/home');
    }

    const backgroundImageUrl = "https://storage.googleapis.com/mapylon-images-static/mapylon_bike1.jpg";
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover' 
    };

    return (
        <div className="d-flex flex-column vh-100" style={backgroundStyle}>
            <HeaderHome />
            <div class="home-1-container">
            <div className="vh-100 d-flex justify-content-center align-items-center" style={{
                backgroundImage: `url(${backgroundImageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                marginLeft: '25%',
            }}>
                
            </div>
            <div class="color-overlay-myacct"></div>
            </div>
            <div className="about-text-overlay" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="header-logo home-logo">
                    <img
                    src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
                    alt="Mapylon"
                    className="small-image home-logo-img about"
                    style={{ marginLeft: '0' }}
                    onClick={navigateHome}
                    />
                </div>
                <div className="centered-container">
                    <div style={{ position: 'absolute', left: '8vw', top: '30vh' }}>
                        <div>
                            <h2><b>My Account</b></h2>
                            <p><b>Username:&nbsp;</b>{userInfo.username}</p>
                            <p><b>Date Joined:&nbsp;</b>{userInfo.dateJoined}</p>
                            <p><b>First Name:&nbsp; </b>{isEditMode ? <input type="text" name="firstName" value={userInfo.firstName} onChange={handleFieldChange} /> : userInfo.firstName}</p>
                            <p><b>Last Name:&nbsp; </b>{isEditMode ? <input type="text" name="lastName" value={userInfo.lastName} onChange={handleFieldChange} /> : userInfo.lastName}</p>
                            <p><b>Email:&nbsp; </b>{isEditMode ? <input type="email" name="email" value={userInfo.email} onChange={handleFieldChange} /> : userInfo.email}</p>
                        </div>
                        <div className="account-action-buttons" style={{ display: 'flex', flexDirection: 'column' }}>
                            <button style={{ marginLeft: '0', width: '10vw' }} onClick={toggleEditMode}>{isEditMode ? "Save Changes" : "Edit Account Info"}</button>
                            <button style={{ marginLeft: '0', width: '10vw' }} onClick={resetPw}>Reset Password</button>
                        </div>
                    </div>
                </div>

            </div>
            {
                showSessionTimeoutPopup && (
                    <Modal show={true} onHide={() => setShowSessionTimeoutPopup(false)} centered>
                        <Modal.Header closeButton>
                        <Modal.Title>
                            <h3>Session Timeout</h3>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Session timed out! You will be redirected to the home page.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={handleSessionTimeout}>OK</button>
                        </Modal.Footer>
                    </Modal>
                )
            }
        </div>
    );
};

export default MyAccount;
