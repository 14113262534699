// src/components/Login.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import '../_Shared_UserAuth/AuthStyles.css';
import { useNavigation } from '../../../context/Context';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from 'react-responsive';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setIsAuthorized } = useNavigation();
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background22.jpg`;
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundPosition: '50% 60%'
    };

    const fetchUserSite = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-user-site/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data.sitename;
        } catch (error) {
            console.error('Error fetching sitename', error);
            return null;
        }
    };

    useEffect(() => {
        const checkLoginStatus = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-user-site/`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    const sitename = response.data.sitename;
                    if (sitename) {
                        navigate(`/site/${sitename}/manage-journals/`);
                    } else {
                        console.error('Sitename not found');
                    }
                } catch (error) {
                    console.error('Error fetching sitename', error);
                }
            }
        };
        
        checkLoginStatus();
    }, [navigate]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setIsLoading(true); 
        try {
            const username_or_email = username
            const loginResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/login/`, { username_or_email, password });
            const token = loginResponse.data.access;
            localStorage.setItem('token', token);
    
            const sitename = await fetchUserSite(token);
            if (sitename) {
                navigate(`/site/${sitename}/manage-journals/`, { replace: true });
            } else {
                alert("Please complete signup process.")
                setIsAuthorized(true);
                navigate('/additional-info', { state: { username, password } });
            }
        } catch (error) {
            if (error.response && [500, 502, 503].includes(error.response.status)) {
                setError('Server error, please try again later.'); 
            } else if (!error.response) {
                setError("Server Error, please try again later.")
            } else {
                setError('Invalid username or password'); 
            }
            console.error('Login error', error);
        } finally {
            setIsLoading(false); 
        }
    };
    


    return (
        <>
            {isLoading && (
                <div className="spinner-overlay">
                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`} alt="Loading..." className="spinner-logo" />
                </div>
            )}
        <div className="auth-container" style={backgroundStyle}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 centered-content">
                        { !isMobile ? (
                            <form onSubmit={handleSubmit} className="auth-form p-4 shadow rounded">
                                <h2 className="text-center mb-4">
                                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon4.png`} alt="" className="mb-2" style={{ width: '50px', height: '65px' }} />
                                    Login
                                </h2>
                                {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>} {/* Display the error message */}
                                <div className="mb-3">
                                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username or Email" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="form-control"/>
                                </div>
                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-primary login-button" disabled={isLoading}>Login</button>
                                </div>
                                <div className="text-center mt-3 forgot-pw">
                                    <a href="/reset-password">Forgot my password</a> 
                                </div>
                            </form> 
                        ) : ( 
                            <form onSubmit={handleSubmit} className="auth-form p-4 shadow rounded mobile-login">
                                <h2 className="text-center mb-4 login-title">
                                    mapylon
                                </h2>
                                {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>} {/* Display the error message */}
                                <div className="mb-3">
                                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username or Email" className="form-control"/>
                                </div>
                                <div className="mb-3">
                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="form-control"/>
                                </div>

                                <button type="submit" className="btn btn-primary login-button" disabled={isLoading}>Login</button>

                                <div className="text-center mt-3 forgot-pw">
                                    <a href="/reset-password">Forgot my password</a> 
                                </div>
                            </form> 
                        )}
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default Login;