import React from 'react';
import HeaderHome from '../../HomePage/components/HeaderHome';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/contactback6.jpg`;
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover' 
    };

    const navigate = useNavigate();

    const navigateHome = (e) => {
        navigate('/home')
    };

    return (
        <div className="d-flex flex-column vh-100" style={backgroundStyle}>
            <HeaderHome />
            <div class="home-1-container">
            <div className="vh-100 d-flex justify-content-center align-items-center" style={{
                backgroundImage: `url(${backgroundImageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                marginLeft: '25%',
            }}>
                
            </div>
            <div class="color-overlay-about"></div>
            </div>
            <div className="about-text-overlay" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="header-logo home-logo">
                    <img
                    src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
                    alt="Mapylon"
                    className="small-image home-logo-img"
                    style={{ marginLeft: '0' }}
                    onClick={navigateHome}
                    />
                </div>
                <div className="text-left text-white" style={{ position: 'absolute', top: '28vh', left: '8vw', width: '40vw', lineHeight: '1', height: '65vh' }}>
                    <h2 className="home-title about"><b>Contact Us</b></h2>
                    <p className="home-subheading about">Do you have questions, comments, or suggestions? We'd love to hear from you.</p>
                    <p className="home-subheading about"><u>tom@mapylon.com</u></p>
                </div>

            </div>
        </div>
    );
};

export default Contact;
