

const PointOptions = ({ featureColorRef, selectedIcon, openIconModal, shapeRef, sizeRef, borderRef }) => {
    return (
        <div className="point-options">
            <div className="color-picker-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <label htmlFor="feature-color-fill" className="feature-color-label">Select Icon Color:</label>
                <input 
                    type="color"
                    defaultValue='#ff0000'
                    ref={featureColorRef}
                    className="feature-color-fill"
                />
            </div>
            <div className="point-icon-container">
                <label className="feature-color-label">Select Point Icon:</label>
                <button className="icon-selector" onClick={openIconModal}>
                <img 
                    src={selectedIcon ? `https://storage.googleapis.com/mapylon-images-static/map_icons/${selectedIcon}` : `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/map_icons/park-2.svg`}
                    alt=''
                    style={{ width: '5vh', height: '5vh', padding: '0.7vh' }} 
                />
                </button>
                <label htmlFor="shape">Shape: </label>
                <select 
                    id="shape" 
                    ref={shapeRef} 
                    className="shape-control"
                >
                    <option value="circle">Circle</option>
                    <option value="round-square">Rounded Square</option>
                    <option value="square">Square</option>
                </select>
            </div>
            <div className="point-icon-container">
                <label htmlFor="shape">Icon Size: </label>
                <select 
                    id="border" 
                    ref={sizeRef} 
                    className="border-control"
                    defaultValue="1"
                >
                    <option value="0.5">X Small</option>
                    <option value="0.75">Small</option>
                    <option value="1">Regular</option>
                    <option value="1.25">Large</option>
                    <option value="1.5">X Large</option>
                </select>
                <label htmlFor="shape">Icon Border: </label>
                <select 
                    id="border" 
                    ref={borderRef} 
                    className="border-control"
                >
                    <option value="border">Solid Border</option>
                    <option value="no-border">No Border</option>
                </select>
            </div>
        </div>


    );
};

export default PointOptions;