const MapFooter = ({ isMobile, isUserSignedIn }) => (
    <div className="map-footer" style={{ width: '100vw' }}>
    <p><b>Ready to write your own adventure with Mapylon? Get started today!</b></p>
    <div className="footer-buttons">
    {!isMobile && <button onClick={() => window.open("https://mapylon.com/login", '_blank', 'noopener,noreferrer')}>Login</button>}
    <button onClick={() => window.open("https://mapylon.com/signup", '_blank', 'noopener,noreferrer')}>Signup</button>
    </div>
    </div>
);

export default MapFooter;