// HeaderManage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import './HeaderManage.css'

const HeaderManage = (e) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const handleSignOut = (e) => {
    e.preventDefault();
    // Clear the token from local storage
    localStorage.removeItem('token');
    // Redirect to the home or login page
    navigate('/home');
  };

  const handleAccountClick = (e) => {
    e.preventDefault();
    navigate('/my-account/')
  }

  const handleHomeClick = (e) => {
    e.preventDefault();
    navigate('/home/')
  }

  return (
    <div className="header-container">
      { !isMobile ? ( 
        <header className="header mng-header">
          {/* <a href="/home" className="manage-mapylon-link"><h3><b>mapylon</b></h3></a> */}
          <div className="manage-logo">
            <img
              src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
              alt="Mapylon"
              className="small-image mng"
              onClick={handleHomeClick}
            />
          </div>
          <div className="header-content">
            <nav className="header-nav mng">
              <ul>
                <li><a href="/home">Home</a></li>
                <li><button onClick={handleAccountClick} className="link-button-create">My Account</button></li>
                <li><button onClick={handleSignOut} className="link-button-create">Sign Out</button></li>
              </ul>
            </nav>
          </div>
        </header>
      ) : ( 
        <header className="manage-header">
          <div className="manage-logo">
            <img
              src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
              alt="Mapylon"
              className="small-image mobile-journal-img"
            />
          </div>
          <div className="mobile-nav-container">
            <button onClick={toggleMobileMenu} className="hamburger-icon-jrnl">☰</button>
            {isMobileMenuOpen && (
              <nav className="mobile-header-nav">
                <ul>
                    <>
                      <li style={{ animation: `slideIn 0.5s ease forwards ${0 * 0.1}s` }}><a href="/home">Home</a></li>
                      <li style={{ animation: `slideIn 0.5s ease forwards ${1 * 0.1}s` }}><a href="/my-account">My Account</a></li>
                      <li style={{ animation: `slideIn 0.5s ease forwards ${(3 + 1) * 0.1}s` }}>
                        <button onClick={handleSignOut} className="btn-mobile-nav">Sign Out</button>
                      </li>
                    </>
                </ul>
              </nav>
            )}
          </div>
        </header>
      )}
    </div>
  );
  };

export default HeaderManage;
