//ImageGallery.js

const ImageGallery = ({images, blogPost, currentImageIndex, isImageLoaded, handleImageLoaded, handleImageChange}) => {

    return (
        <div className="image-container">   
            {images && images.length > 0 ? (
                <>
                    <div className="image-gallery">
                        {!blogPost ? (
                            <div className="loading-indicator">
                                <div className="spinner-img"></div>
                                <p>Loading...</p>
                            </div>
                        ) : images[currentImageIndex] ? (
                            <>
                                {!isImageLoaded && (
                                    <div className="loading-indicator">
                                        <div className="spinner-img"></div>
                                        <p>Loading Image...</p>
                                    </div>
                                )}
                                <div className="image-title-container" style={{ position: 'relative', display: 'flex', justifyContent: 'center', height: '100%' }}>
                                    <img
                                        src={images[currentImageIndex].imgurl}
                                        alt={images[currentImageIndex].imgtitle || `thumbnail-${currentImageIndex}`}
                                        className="img-thumbnail img-fluid"
                                        onLoad={handleImageLoaded}
                                        style={{ display: isImageLoaded ? 'block' : 'none' }}
                                    />
                                </div>
                                {isImageLoaded && images[currentImageIndex].imgtitle && (
                                    <p className="photo-title" style={{
                                        bottom: 0,
                                        width: '89%',
                                        fontWeight: '500',
                                        // backgroundColor: 'rgba(0, 0, 0, 0.2)', 
                                        color: 'rgb(0,0,0)', 
                                        textAlign: 'center',
                                        padding: '1% 0'
                                    }}>{images[currentImageIndex].imgtitle}</p>
                                )}
                            </>
                        ) : (
                            <p>No image available</p>
                        )}
                    </div>
                    <div className="gallery-navigation">
                        <button 
                            className="gallery-button" 
                            onClick={() => {
                                if (images.length > 1) {
                                    handleImageChange(currentImageIndex > 0 ? currentImageIndex - 1 : images.length - 1)}
                                }
                            }
                        >
                            &#8592;
                        </button>
                        <p className="gallery-info">{`${currentImageIndex + 1} of ${images.length}`}</p> 
                        <button 
                            className="gallery-button" 
                            onClick={() => {
                                if (images.length > 1) {
                                    handleImageChange((currentImageIndex + 1) % images.length)}
                                }
                            }
                        >
                            &#8594;
                        </button>
                    </div>
                </>
            ) : (
                <>
                    {!blogPost ? (
                        <div className="loading-indicator">
                            <div className="spinner-img"></div>
                            <p>Loading Image...</p>
                        </div>
                    ) : (
                    <p>No images to display</p> 
                    )}
                </>
            )}
        </div>
    );
};

export default ImageGallery;