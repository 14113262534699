import React, { useEffect, useRef } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const SearchModal = ({ isOpen, onClose, mapboxAccessToken, map }) => {
  const geocoderContainerRef = useRef(null);

  useEffect(() => {
    let geocoder;
    if (isOpen && geocoderContainerRef.current) {
      geocoder = new MapboxGeocoder({
        accessToken: mapboxAccessToken,
      });

      // Append the geocoder's input to the ref'd div
      geocoderContainerRef.current.appendChild(geocoder.onAdd());

      // Event listener for when a user selects a location
      geocoder.on('result', (e) => {
        // Close the modal
        onClose();

        // Update the map location to the selected place
        if (map) {
          map.current.flyTo({
            center: e.result.geometry.coordinates,
            zoom: 10 // Adjust zoom level as needed
          });
        }
      });
    }
    return () => {
      if (geocoder) {
        const el = geocoder.onRemove();
        el && geocoderContainerRef.current && geocoderContainerRef.current.removeChild(el);
      }
    };
  }, [isOpen, mapboxAccessToken, map, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="search-modal">
      <div className="search-modal-content">
        <span className="close search-close" onClick={onClose}>&times;</span>
        <div ref={geocoderContainerRef}></div>
      </div>
    </div>
  );
};

export default SearchModal;
