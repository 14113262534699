// headerHome.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

const Header = () => {

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [sitename, setSitename] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);


  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  useEffect(() => {
    const fetchUserSite = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-user-site/`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setSitename(response.data.sitename);
        } catch (error) {
          if (error.response && error.response.data.code === 'token_not_valid') {
            localStorage.removeItem('token');
            setIsLoggedIn(false);
          } else {
            localStorage.removeItem('token');
            setIsLoggedIn(false);
            console.error('Error fetching sitename:', error);
          }
        }
      }
    };

    if (isLoggedIn) {
      fetchUserSite();
    }
  }, [isLoggedIn]);

  const handleSignOut = (e) => {
    e.preventDefault();
    // Clear the token from local storage
    localStorage.removeItem('token');
    window.location.reload();
  };

  const handleAccountClick = (e) => {
    e.preventDefault();
    navigate('/my-account/')
  }

  return (
    <header className="header-home">
      {!isMobile ? (
        <></>
        // <div className="header-logo">
        //   <img
        //     src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
        //     alt="Mapylon"
        //     className="small-image"
        //   />
        //   <p><b>beta v0.3.2</b></p>
        // </div>
      ) : ( 
        <div className="header-logo">
          <img
            src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
            alt="Mapylon"
            className="small-image"
          />
          <p><b>beta v0.3.2</b></p>
        </div>
      )}
      <div className="header-content">
          <div className="mobile-nav">
              <div className="mobile-nav-container">
                {isMobile ? (
                  <button onClick={toggleMobileMenu} className="hamburger-icon">☰</button>
                ):(
                  <button onClick={toggleMobileMenu} className="hamburger-icon" style={{ fontSize: '2rem' }}> 
                    <i class="fas fa-bars home-drop"></i>
                  </button>
                )}
                
                {isMobileMenuOpen && (
                  <nav className="mobile-header-nav">
                    <ul>
                      {!isLoggedIn ? (
                        <>
                          <li style={{ animation: `slideIn 0.5s ease forwards ${0 * 0.1}s` }}><a href="/home">Home</a></li>
                          <li style={{ animation: `slideIn 0.5s ease forwards ${1 * 0.1}s` }}><a href="/login">Login</a></li>
                          <li style={{ animation: `slideIn 0.5s ease forwards ${2 * 0.1}s` }}><a href="/signup">Sign Up</a></li>
                        </>
                      ) : (
                        <>
                          <li style={{ animation: `slideIn 0.5s ease forwards ${0 * 0.1}s` }}><a href="/home">Home</a></li>
                          {sitename && (
                            <li style={{ animation: `slideIn 0.5s ease forwards ${1 * 0.1}s` }}><a href={`/site/${sitename}/manage-journals`}>Manage Journals</a></li>
                          )}
                          {/* <li style={{ animation: `slideIn 0.5s ease forwards ${2 * 0.1}s` }}>
                            <button onClick={handleAccountClick} className="btn-mobile-nav">My Account</button>
                          </li> */}
                          <li style={{ animation: `slideIn 0.5s ease forwards ${(3 + 1) * 0.1}s` }}>
                            <button onClick={handleSignOut} className="btn-mobile-nav">Sign Out</button>
                          </li>

                        </>
                      )}
                    </ul>
                  </nav>
                )}
            </div>
          </div>
      </div>
    </header>
  );
};

export default Header;
