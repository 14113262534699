
const EditAndLoadIcon = (fillColor, userSelectedIcon, selectedShape, selectedSize, borderOption, newFeatureId, mapRef) => {


    // Function to update element colors selectively
    const updateElementColors = (svgElement, fillColor) => {
        const elementsToColor = svgElement.querySelectorAll('path, rect, circle, polygon, polyline, ellipse, style');
        elementsToColor.forEach(el => {
            if (el.tagName.toLowerCase() !== 'style') {
                el.setAttribute('fill', fillColor || '#ff0000');
            } else {
                // Update CSS fill color
                let updatedCssText = el.innerHTML.replace(/fill:\s*#[\da-fA-F]+;/g, `fill: ${fillColor || '#ff0000'};`);
                el.innerHTML = updatedCssText;
            }
        });
    };

    // Function to load the generated PNG into Mapbox
    const loadIntoMapbox = (pngDataURL, userSelectedIcon, newFeatureId, mapRef) => {
        const map = mapRef.current.getMap();
        map.loadImage(pngDataURL, (error, image) => {
            if (error) throw error;
            if (!map.hasImage(userSelectedIcon + newFeatureId)) {
                map.addImage(userSelectedIcon + newFeatureId, image);
            }
        });
    };

    fetch(`https://storage.googleapis.com/mapylon-images-static/map_icons/${userSelectedIcon}.svg`)
        .then(response => response.text())
        .then(svgText => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(svgText, "image/svg+xml");
            const svgElement = doc.documentElement;

            //size elements
            const userSelectedSize = selectedSize === 0 ? 1 : selectedSize;
            const strokeWidth = 20 * userSelectedSize;
            const iconSize = 450 * userSelectedSize;
            const backgroundSize = userSelectedSize === 'circle' ? 750 * 1.6 * userSelectedSize : 750 * userSelectedSize; 


            const style = document.createElementNS('http://www.w3.org/2000/svg', 'style');
            style.textContent = `
                .custom-fill { fill: ${fillColor || '#ff0000'}; }
                .custom-border { stroke: ${fillColor || '#000000'}; stroke-width: ${strokeWidth}; } 
            `;
            svgElement.prepend(style);

            // Update color for all elements to match the fill color, adjust CSS as needed
            updateElementColors(svgElement, fillColor);


            // Assuming initial viewBox is properly set to enclose the SVG icon
            let [x, y, width, height] = svgElement.getAttribute('viewBox') ? svgElement.getAttribute('viewBox').split(' ').map(Number) : [0, 0, width, height];

            // Calculate scale factor to fit the icon within the 300x300px area
            const scaleFactor = Math.min(iconSize / width, iconSize / height);

            // Calculate translation to center the scaled icon within the 500x500px background
            const adjustedX = (backgroundSize - (width * scaleFactor)) / 2;
            const adjustedY = (backgroundSize - (height * scaleFactor)) / 2;

            // Apply transformations to center and scale the SVG content
            const transformGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
            transformGroup.setAttribute("transform", `translate(${adjustedX}, ${adjustedY}) scale(${scaleFactor})`);

            // Wrap existing SVG contents in the transform group
            while (svgElement.firstChild) {
                transformGroup.appendChild(svgElement.firstChild);
            }
            svgElement.appendChild(transformGroup);

            // Set the viewBox to encompass the 500x500 area
            svgElement.setAttribute('viewBox', `0 0 ${backgroundSize} ${backgroundSize}`);
            svgElement.setAttribute('width', `${backgroundSize}px`);
            svgElement.setAttribute('height', `${backgroundSize}px`);

            // Create a white background rectangle

            if (selectedShape === 'circle') {
                // Create a circle that acts as a background/mask for the icon
                const circleRadius = (backgroundSize / 2) - (strokeWidth / 2);
                const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                circle.setAttribute('cx', backgroundSize / 2);
                circle.setAttribute('cy', backgroundSize / 2);
                circle.setAttribute('r', circleRadius);
                circle.setAttribute('fill', 'white');
                if (borderOption === 'border') {
                    circle.classList.add('custom-border'); // Apply border styling
                }
                svgElement.insertBefore(circle, svgElement.firstChild); // Ensure the circle is at the back
            } else {
                let background = svgElement.querySelector('.background');
                background = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                background.setAttribute('width', `${backgroundSize}px`);
                background.setAttribute('height', `${backgroundSize}px`);
                if (selectedShape === 'round-square') {
                    background.setAttribute('rx', 120); // Apply rounded corners
                }
                if (borderOption === 'border') {
                    background.classList.add('custom-border'); // Apply border styling
                }
                background.setAttribute('fill', 'white');
                svgElement.insertBefore(background, transformGroup); // Ensure background is first
                
            }



            // Serialize the modified SVG and encode it as a data URL
            const serializedSVG = new XMLSerializer().serializeToString(svgElement);
            const svgDataURL = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(serializedSVG)))}`;

            // Create an Image object and set the SVG data URL as the source
            const img = new Image();
            img.onload = () => {
                // Create a canvas and set dimensions
                const canvas = document.createElement('canvas');
                canvas.width = backgroundSize;
                canvas.height = backgroundSize;

                // Draw the image onto the canvas
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                // Convert the canvas to a PNG data URL and load it into Mapbox
                const pngDataURL = canvas.toDataURL('image/png');
                loadIntoMapbox(pngDataURL, userSelectedIcon, newFeatureId, mapRef);
            };
            img.src = svgDataURL;
        })
        .catch(error => console.error('Error fetching or customizing icon:', error));
};

export default EditAndLoadIcon;





// const EditAndLoadIcon = (fillColor, userSelectedIcon, selectedShape, selectedSize, borderOption, newFeatureId, mapRef) => {
//     console.log("EditAndLoadIcon ", userSelectedIcon)
//     fetch(`https://storage.googleapis.com/mapylon-images-static/map_icons/${userSelectedIcon}.svg`)
//     .then(response => response.text())
//     .then(svgText => {

//         const parser = new DOMParser();
//         const doc = parser.parseFromString(svgText, "image/svg+xml");
//         const svgElement = doc.documentElement;

//         // Assuming all icons initially fit within a 512px square viewBox
//         let [x, y, width, height] = svgElement.getAttribute('viewBox') ? svgElement.getAttribute('viewBox').split(' ').map(Number) : [0, 0, 512, 512]; 
//         console.log(svgElement.getAttribute('viewBox').split(' ').map(Number))
//         console.log(x, y, width, height)
//         // Target size for icons and background
//         const iconSize = 300;
//         const backgroundSize = 500;
//         const padding = (backgroundSize - iconSize) / 2; 

//         const scaleFactor = Math.min(iconSize / width, iconSize / height);
//         const adjustedX = (backgroundSize - (width * scaleFactor)) / 2;
//         const adjustedY = (backgroundSize - (height * scaleFactor)) / 2;

//         const svgGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
//         svgGroup.setAttribute("transform", `translate(${adjustedX}, ${adjustedY}) scale(${scaleFactor})`);

//         // Wrap existing SVG contents in the group
//         while (svgElement.firstChild) {
//             svgGroup.appendChild(svgElement.firstChild);
//         }
//         svgElement.appendChild(svgGroup);

//         // Set the viewBox to encompass the 500x500 area
//         svgElement.setAttribute('viewBox', `0 0 ${backgroundSize} ${backgroundSize}`);

//         // Update color to match users fille color
//         const elementsToColor = svgElement.querySelectorAll('path, rect, circle, polygon, polyline, ellipse, style');
//         elementsToColor.forEach(el => {
//             if (el.tagName.toLowerCase() !== 'style') {
//                 el.setAttribute('fill', fillColor || '#ff0000');
//             } else {
//                 // Update CSS fill color
//                 let updatedCssText = el.innerHTML.replace(/fill:\s*#[\da-fA-F]+;/g, `fill: ${fillColor || '#ff0000'};`);
//                 el.innerHTML = updatedCssText;
//             }
//         });
        
//         // Modify the SVG element as needed based on userPreferences
//         // const viewBox = svgElement.getAttribute('viewBox').split(' ').map(Number);
//         // const [x, y, width, height] = viewBox;

//         // const shapeType = selectedShape; // 'square', 'round-square', or 'circle'
//         // const background = document.createElementNS('http://www.w3.org/2000/svg', shapeType === 'circle' ? 'circle' : 'rect');
//         // if (shapeType === 'circle') {
//         //     const radius = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2 + padding;
//         //     const adjustedViewBox = {
//         //         x: x + (width / 2) - radius,
//         //         y: y + (height / 2) - radius,
//         //         width: radius * 2,
//         //         height: radius * 2
//         //     };
//         //     //svgElement.setAttribute('viewBox', `${adjustedViewBox.x} ${adjustedViewBox.y} ${adjustedViewBox.width} ${adjustedViewBox.height}`);

//         //     // Create the circle that surrounds the SVG content.
//         //     background.setAttribute('cx', x + (width / 2)); // Center of the original SVG content
//         //     background.setAttribute('cy', y + (height / 2)); // Center of the original SVG content
//         //     background.setAttribute('r', radius);
//         // } else { // For square or rounded-square
//         //     const adjustedWidth = width + padding * 2;
//         //     const adjustedHeight = height + padding * 2;
//         //     const adjustedX = x - padding;
//         //     const adjustedY = y - padding;
        
//         //     //svgElement.setAttribute('viewBox', `${adjustedX} ${adjustedY} ${adjustedWidth} ${adjustedHeight}`);
//         //     const iconBoundingBox = { x: 100, y: 100, width: 300, height: 300 };

//         //     // Calculate the new viewBox to center the icon with padding
//         //     const newViewBoxX = iconBoundingBox.x - padding;
//         //     const newViewBoxY = iconBoundingBox.y - padding;
//         //     const newViewBoxWidth = iconBoundingBox.width + (padding * 2);
//         //     const newViewBoxHeight = iconBoundingBox.height + (padding * 2);
        
//         //     svgElement.setAttribute('viewBox', `${newViewBoxX} ${newViewBoxY} ${newViewBoxWidth} ${newViewBoxHeight}`);
//         //     svgElement.setAttribute('width', `${standardSize}px`);
//         //     svgElement.setAttribute('height', `${standardSize}px`);

//         //     svgElement.setAttribute('x', 100);
//         //     svgElement.setAttribute('y', 100);
//         //     // background.setAttribute('width', adjustedWidth);
//         //     // background.setAttribute('height', adjustedHeight);
        
//         //     if (shapeType === 'round-square') {
//         //         background.setAttribute('rx', 120); // Apply rounded corners
//         //     }
//         // }
//         // background.setAttribute('x', 300);
//         // background.setAttribute('y', 300);
//         // background.setAttribute('width', width);
//         // background.setAttribute('height', height);
//         // background.setAttribute('fill', 'white'); // background color
//         // if (borderOption === 'border') { // add border if user selects one
//         //     background.setAttribute('stroke', fillColor || '#ff0000'); // border color
//         //     background.setAttribute('stroke-width', '5'); // border width
//         // }
//         // svgElement.insertBefore(background, svgElement.firstChild);

//         let background = svgElement.querySelector('.background');
//         if (!background) {
//             background = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
//             svgElement.insertBefore(background, svgElement.firstChild);
//         }
//         // background.setAttribute('x', `${-padding}`);
//         // background.setAttribute('y', `${-padding}`);
//         background.setAttribute('width', `${backgroundSize}px`);
//         background.setAttribute('height', `${backgroundSize}px`);
//         background.setAttribute('fill', 'white');
//         background.setAttribute('class', 'background');


//         // Serialize the modified SVG and encode it as a data URL
//         const serializedSVG = new XMLSerializer().serializeToString(svgElement);
//         const svgDataURL = `data:image/svg+xml;base64,${window.btoa(serializedSVG)}`;

//         // Create an Image object and set the SVG data URL as the source
//         const img = new Image();

//         img.onload = () => {
//             // Create a canvas and set dimensions
//             const canvas = document.createElement('canvas');
//             canvas.width = backgroundSize;
//             canvas.height = backgroundSize;

//             // Draw the image onto the canvas
//             const ctx = canvas.getContext('2d');
//             ctx.drawImage(img, 0, 0);

//             // Convert the canvas to a PNG data URL
//             const pngDataURL = canvas.toDataURL('image/png');
//             const map = mapRef.current.getMap();


//             // Load the PNG into Mapbox
//             map.loadImage(pngDataURL, (error, image) => {
//             if (error) throw error;
//             if (!map.hasImage(userSelectedIcon + newFeatureId)) {
//                 map.addImage(userSelectedIcon + newFeatureId, image);
//             }
//             });

//         };
//         img.src = svgDataURL;
//     })
//     .catch(error => console.error('Error fetching or customizing icon:', error));

// };

// export default EditAndLoadIcon;
