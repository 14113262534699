import React, { createContext, useState, useContext } from 'react';

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
    const [isAuthorized, setIsAuthorized] = useState(false);

    return (
        <NavigationContext.Provider value={{ isAuthorized, setIsAuthorized }}>
            {children}
        </NavigationContext.Provider>
    );
};