

const PolygonOptions = ({ featureColorRef, opacityRef }) => {
    return (
        <div className="polygon-options">
            <div className="color-picker-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <label htmlFor="feature-color-fill" className="feature-color-label">Select Color:</label>
                <input 
                    type="color"
                    defaultValue='#ff0000'
                    ref={featureColorRef}
                    className="feature-color-fill"
                />
            </div>
            <div className="point-icon-container">
                <label htmlFor="shape">Select Opacity: </label>
                <select 
                    id="border" 
                    ref={opacityRef} 
                    className="border-control"
                    defaultValue="60"
                >
                    <option value="10">10%</option>
                    <option value="20">20%</option>
                    <option value="30">30%</option>
                    <option value="40">40%</option>
                    <option value="50">50%</option>
                    <option value="60">60%</option>
                    <option value="70">70%</option>
                    <option value="80">80%</option>
                    <option value="90">90%</option>
                    <option value="100">100%</option>
                </select>
            </div>
        </div>


    );
};

export default PolygonOptions;