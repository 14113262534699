import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './BlogPopup.css'; 
import ViewJournalPopup from './ViewJournalPopup';
import { useMediaQuery } from 'react-responsive';
import ImageGallery from './popupcomponents/ImageGallery';
import ImageGalleryMobile from './popupcomponents/ImageGalleryMobile';

const BlogPopup = ({ data, coordinates, onClose, journalName, onUpdateSelectedGeometry, selectedPhotoIndex, currentPhotoIndex, onMarkersUpdate, onCurrentImageChange, photoMarkerClickHandlerRef, setBlogTitle, setBlogDate, lengthMiles, gainFeet, lossFeet, elevationPoints, handleElevationProfileClick, elevationLoading, setElevationLoading }) => {
    const [blogPost, setBlogPost] = useState(null);
    const [images, setImages] = useState([]);
    const [warningMessage, setWarningMessage] = useState('');
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [journalViewMode, setJournalViewMode] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(selectedPhotoIndex !== null ? selectedPhotoIndex : 0);
    const [username, setUsername] = useState();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [isLoading, setIsLoading] = useState(true);

    const scrollableRef = useRef(null); 

       useEffect(() => {
        if (images.length > 0) {
            setCurrentImageIndex(0);
        }
    }, [images]);

    useEffect(() => {
        if (selectedPhotoIndex !== null && selectedPhotoIndex !== currentImageIndex) {
            setCurrentImageIndex(selectedPhotoIndex);
        }
    }, [selectedPhotoIndex]);

    useEffect(() => {
        if (currentPhotoIndex !== undefined && currentPhotoIndex !== currentImageIndex) {
            handleImageChange(currentPhotoIndex);
        }
    }, [currentPhotoIndex]);

    const handlePhotoMarkerClick = (imageIndex) => {
        setCurrentImageIndex(imageIndex); 
        handleImageChange(imageIndex);

        // Use the handler from the ref
        if (photoMarkerClickHandlerRef.current) {
            photoMarkerClickHandlerRef.current(imageIndex);
        }
    };

    // useEffect(() => {
    //     if (setPhotoMarkerClickHandler) {
    //         setPhotoMarkerClickHandler(handlePhotoMarkerClick);
    //     }
    // }, [setPhotoMarkerClickHandler]);
    const handleImageLoaded = () => {
        setIsImageLoaded(true);
    };
    
    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    // Fetch blog post
    useEffect(() => {
        const fetchBlogPost = async () => {
            if (data && data.id) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/map-view/get-map-blog-post/${data.id}`);
                    
                    setBlogPost(response.data);
                    setUsername(response.data.username)
                    setImages(response.data.photos);
                    setBlogTitle(response.data.title);
                    setBlogDate(response.data.date_start)
                    const markers = response.data.photos.map((photo, index) => ({
                        id: `photo-marker-${index}`, 
                        longitude: photo.longitude,
                        latitude: photo.latitude,
                        title: photo.imgtitle
                    }));
                    onMarkersUpdate(markers);

                    if (response.data.photos.length > 0) {
                        onCurrentImageChange({
                            longitude: response.data.photos[0].longitude,
                            latitude: response.data.photos[0].latitude,
                            title: response.data.photos[0].imgtitle
                        });
                    }
                    setIsLoading(false);
                } catch (error) {
                    console.error("Error fetching blog post:", error);
                }
            }
        };
        fetchBlogPost();
    }, [data, onMarkersUpdate]);

    const handleViewJournalsClick = () => {
        //handle click on View Other Journals - display journal summary popup 
        setJournalViewMode(true);
    }

    useEffect(() => {
        if (blogPost && blogPost.photos) {
            blogPost.photos.forEach(photo => {
                const img = new Image();
                img.src = photo.imgurl;
            });
        }
    }, [blogPost]);

    const handleClose = () => {
        onClose(); // Existing close functionality
        onMarkersUpdate([]); // Clear photo markers
        onCurrentImageChange(null); // Clear current image marker (star marker)
    };

    const handleImageChange = (newIndex) => {
        if (!isMobile) {setIsImageLoaded(false);}
        setCurrentImageIndex(newIndex);
        if (images[newIndex]) {
            onCurrentImageChange({
                longitude: images[newIndex].longitude,
                latitude: images[newIndex].latitude,
                title: images[newIndex].imgtitle,
                imgUrl: images[newIndex].imgurl
            });
        }
    };


    const handleAdjacentPostClick = async (direction) => {
        try {
            const blogPostId = blogPost.id;
            setIsLoading(true);
            setElevationLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/map-view/get-next-prev-post/${blogPostId}/${direction}`);
    
            // Check if there's a message indicating no more posts
            if (response.data && response.data.message) {
                // Set a warning message based on the direction
                setWarningMessage(direction === 'previous' ? 'Oldest Post!' : 'Newest Post!');
            } else {
                setBlogPost(response.data);
                setImages(response.data.photos || []); 
                setWarningMessage(''); 
                onUpdateSelectedGeometry(response.data.id);
                setBlogTitle(response.data.title);
                setBlogDate(response.data.date_start)
                // Reset the image index and update the star marker for the first image of the new post
                setCurrentImageIndex(0);
                if (response.data.photos && response.data.photos.length > 0) {
                    const firstImage = response.data.photos[0];
                    onCurrentImageChange({
                        longitude: firstImage.longitude,
                        latitude: firstImage.latitude,
                        title: firstImage.imgtitle
                    });
                } else {
                    onCurrentImageChange(null); // Clear the star marker if there are no photos
                }
            }

            if (scrollableRef.current) {
                scrollableRef.current.scrollTop = 0;
            }
        } catch (error) {
            console.error("Error fetching adjacent blog post:", error);
        }
    };

    if (!data) return null;

    // Use blogPost data for displaying in the popup
    // Fall back to default data if blogPost is not loaded yet
    return (
        <div>
            <div className="blog-popup-container" >
                {!isMobile && (
                    <div className="custom-card-header">
                        <h3>{journalName}</h3>
                        <button className="btn-close-popup-header" onClick={() => {
                            handleClose(); // Existing close functionality
                            onMarkersUpdate([]); // Clear photo markers
                            onCurrentImageChange(null); // Clear current image marker (star marker)
                        }}>X</button>
                    </div>
                )}
                <div/>
                <div className="blog-popup-body-container" ref={scrollableRef}>
                    <div className="row g-3"> 
                        {isMobile ? (
                            <ImageGalleryMobile 
                                images={images}
                                blogPost={blogPost}
                                currentImageIndex={currentImageIndex}
                                isImageLoaded={isImageLoaded}
                                handleImageLoaded={handleImageLoaded}
                                handleImageChange={handleImageChange}
                            />
                        ) : (
                            <>
                                <div className="title-area-popup">
                                    <h5 className="card-title mb-0 custom-title">{blogPost || !isLoading ? blogPost.title : 'Loading...'}</h5>
                                    <p className="card-text custom-text">{blogPost || !isLoading ? blogPost.date_start : 'Loading blog content...'}</p>
                                    <div className="popupUserArea">
                                        <p className="card-text custom-text">{blogPost || !isLoading ? username : 'Loading user...'}</p>
                                        <p className="open-journals-link" onClick={() => {
                                            handleViewJournalsClick();
                                        }}><u>{blogPost || !isLoading ? `View ${username}'s Other Journals` : ''}</u></p>
                                    </div>
                                    {blogPost && !elevationLoading && (
                                        <div className = "popup-terrain-stats">
                                            <p className="custom-text" style = {{ fontWeight: '600'}}>{lengthMiles.toFixed(2)} miles</p>
                                            <p className="custom-text" style = {{ color: 'green', fontWeight: '600'}}>+{numberWithCommas(gainFeet.toFixed(0))}ft</p>
                                            <p className="custom-text" style = {{ color: 'red', fontWeight: '600'}}>-{numberWithCommas(lossFeet.toFixed(0))}ft</p>
                                            <button className="open-elevation-button" onClick={() => {
                                                handleElevationProfileClick(elevationPoints);
                                            }}>View Profile</button>
                                        </div>
                                    )}
                                </div>
                                <ImageGallery setBlogPost
                                    images={images}
                                    blogPost={blogPost}
                                    currentImageIndex={currentImageIndex}
                                    isImageLoaded={isImageLoaded}
                                    handleImageLoaded={handleImageLoaded}
                                    handleImageChange={handleImageChange}
                                />
                            </>
                        )}
                    </div>
                    <div className="card-body">
                        
                        <p className="card-text custom-text">{blogPost ? blogPost.content : 'Loading blog content...'}</p>
                    </div>
                </div>
                {!isMobile && (
                    <div className="card-footer bg-transparent border-0">
                        {warningMessage && (
                            <div className="warning-message-container">
                                <div className="warning-message">{warningMessage}</div>
                            </div>
                        )}
                        <div className="post-navigation-buttons">
                            <button className="btn-move-posts" onClick={() => handleAdjacentPostClick('previous')}>Last Post</button>
                            <button className="btn-move-posts" onClick={() => handleAdjacentPostClick('next')}>Next Post</button>
                        </div>
                    </div>
                )}
            </div>
            {journalViewMode && (
            <div className="journal-view-container">
                <ViewJournalPopup
                    blog_post={blogPost}
                    display_name={username}
                    onClose={() => {
                        setJournalViewMode(false)
                    }}
                />
            </div>
            )}
        </div>
    );
};

export default BlogPopup;
