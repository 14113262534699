// components/FrontEnd/PageNotFound/PageNotFound.js
import './ErrorPage.css'; 
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/404_1.jpg`;
    const navigate = useNavigate();

    const navigateHome = (e) => {
        navigate('/home')
    };
    const navigateLogin = (e) => {
        navigate('/login')
    };
    const navigateSignup = (e) => {
        navigate('/signup')
    };

    const pageStyle = {
        textAlign: 'center',
        height: '100vh', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundImage: `url(${backgroundImageUrl})`
    };

    return (
        <div style={pageStyle}>
            <div className="not-found-page">
                <h1 className="not-found-title">Access denied!</h1>
                <p className="error-page-text">Sorry, it doesn't seem like you have the necessary permissions to view this resource.</p>
                <div className="error-page-buttons">
                    <button className="error-page-button" onClick={navigateHome}>Go Home</button>
                    <button className="error-page-button" onClick={navigateLogin}>Login</button>
                    <button className="error-page-button" onClick={navigateSignup}>Signup</button>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
