import React from 'react';

const Help = () => {

    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon_sheep.jpg`;
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover' 
    };

    return (
        <div className="d-flex flex-column vh-100" style={backgroundStyle}>
            <div className="home-page-content text-white">
                {/* <div>
                    <br></br><br></br><br></br><br></br><br></br><br></br>
                    <br></br><br></br><br></br><br></br><br></br><br></br>
                    <h2 className="display-4">Welcome to Mapylon</h2>
                    <p className="display-8"><b>Mapylon is a map-based web journal to record your adventures and travels.</b></p>
                    <p></p>
                    <p className="display-8">Save photos where you took them, jot down thoughts about a hike, and more! All in an easy to use, easy to share app.</p>
                </div> */}
            </div>
        </div>
    );
};

export default Help;
