import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Map, { Source, Layer, Marker } from "react-map-gl";
import * as turf from '@turf/turf';
import axios from 'axios';
import "mapbox-gl/dist/mapbox-gl.css";
import BlogPopup from './components/BlogPopup';
import MapFooter from './components/MapFooter';
import EditAndLoadIcon from '../../SiteManagement/JournalEntryUtils/EditAndLoadIcon';
import './ViewMap.css'; 
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Chart from 'chart.js/auto';

const ViewMap = () => {
    const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const [geoJsonData, setGeoJsonData] = useState(null);
    const [journalname, setJournalName] = useState([]);
    const [blogTitle, setBlogTitle] = useState(null);
    const [blogDate, setBlogDate] = useState(null);
    const [selectedGeometry, setSelectedGeometry] = useState(null);
    const { sitename, slugifiedTitle, accessToken } = useParams();
    const mapRef = useRef();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedPostId = queryParams.get('post');
    const [initialViewCompleted, setInitialViewCompleted] = useState(false);
    const [isInitialPostView, setIsInitialPostView] = useState(true);
    const [photoMarkers, setPhotoMarkers] = useState([]);
    const [currentImageMarker, setCurrentImageMarker] = useState(null);
    //const [handleMarkerClick, setHandleMarkerClick] = useState(null);
    //const [markerClicked, setMarkerClicked] = useState(false);
    //const [photoMarkerClickHandler, setPhotoMarkerClickHandler] = useState(null);
    const photoMarkerClickHandlerRef = useRef(null);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
    const [isUserSignedIn, setIsUserSignedIn] = useState(localStorage.getItem('token') !== null);
    const navigate = useNavigate();
    const [is3D, setIs3D] = useState(false);
    const [pitch, setPitch] = useState(0);
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [lengthMiles, setLengthMiles] = useState(0);
    const [gainFeet, setGainFeet] = useState(0);
    const [lossFeet, setLossFeet] = useState(0);
    const [showChart, setShowChart] = useState(false);
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [elevationPoints, setElevationPoints] = useState([]);
    const [elevationLoading, setElevationLoading] = useState();


    const updateSelectedGeometry = useCallback((blogPostId) => {
        const relatedGeometries = geoJsonData.features.filter(f => f.properties.blogid === blogPostId);
        setSelectedGeometry(relatedGeometries);

        if (relatedGeometries) {
            // Create a FeatureCollection from the related geometries
            const featureCollection = turf.featureCollection(relatedGeometries.map(geometry => {
                return turf.feature(geometry.geometry);
            }));

            const handleBoundsRefresh = () => {
                mapRef.current.off('moveend', handleBoundsRefresh);
                setElevationLoading(true);
                calculateTerrainStats(relatedGeometries[0]).then(terrainStats => {
                    setLengthMiles(terrainStats.totalLength);
                    setGainFeet(terrainStats.totalGain * 3.28084); // Convert meters to feet
                    setLossFeet(terrainStats.totalLoss * 3.28084); // Convert meters to feet
                    setElevationPoints(terrainStats.elevationPoints);
            
                }).catch(error => console.error('Failed to calculate terrain stats:', error));
                setElevationLoading(false);
            }



            // Calculate the bounding box for the FeatureCollection
            const bounds = turf.bbox(featureCollection);
            if (bounds.every(coord => !isNaN(coord))) {
                mapRef.current.fitBounds([
                    [bounds[0], bounds[1]], // southwest coordinates
                    [bounds[2], bounds[3]]  // northeast coordinates
                ], {
                    padding: { top: 0, bottom: 120, left: 30, right: 30 },
                    essential: true,
                });
            
                // Add the event listener for moveend to trigger terrain stats calculation
                mapRef.current.on('moveend', handleBoundsRefresh);
            } else {
                console.error('Invalid bounds:', bounds);
            }


        } else {
            setSelectedGeometry([]);
            setPhotoMarkers([]); 
            setCurrentImageMarker(null); 
        }
    }, [geoJsonData]);

    const baseMapStyles = {
        Outdoors: "mapbox://styles/mapbox/outdoors-v11",
        Satellite: "mapbox://styles/mapbox/satellite-streets-v12",
        Streets: "mapbox://styles/mapbox/streets-v11",
        Vintage: "mapbox://styles/twalker29/cls9f3cak00gl01pv0ykjf81d",
    };
    const [currentMapStyle, setCurrentMapStyle] = useState(baseMapStyles.Outdoors); 

    const handleBaseMapChange = (event) => {
        const styleName = event.target.value;
        const newMapStyle = baseMapStyles[styleName];
        if (newMapStyle) {
            const map = mapRef.current.getMap();
            map.setStyle(newMapStyle);
    
            // Listen for the 'style.load' event to reapply 3D terrain settings
            map.once('style.load', () => {
                configure3DTerrain(map);
            });
        }
    };
    
    const configure3DTerrain = (map) => {
        if (!map.getSource('mapbox-dem')) {
            map.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
            });
        }
    
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
    
        if (!map.getLayer('sky')) {
            map.addLayer({
                'id': 'sky',
                'type': 'sky',
                'paint': {
                    'sky-type': 'atmosphere',
                    'sky-atmosphere-sun': [0.0, 0.0],
                    'sky-atmosphere-sun-intensity': 15
                }
            });
        }
    };

    // useEffect(() => {
    //     console.log("selectedGeometry changed:", selectedGeometry);
    // }, [selectedGeometry]);
    

    const MapLogoAndTitle = () => {
        return (
            <div className="logo-and-title" style={{ position: 'absolute', display: 'flex', alignItems: 'center' }}>
                <div className='map-logo'>
                    <img
                        src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
                        alt="Logo"
                        onClick={navigateHome}
                    />
                    <p>beta v0.4.0</p>
                </div>
                <h2 style={{ margin: '0 20px', fontWeight: 'bold', paddingBottom: '15px' }}>{journalname}</h2>
            </div>
        );
    }


    useEffect(() => {
        let initialSetupCompleted = false;

        const token = localStorage.getItem('token');

        const headers = {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...(accessToken && { 'X-Access-Token': accessToken })
        };
        

    
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/map-view/${sitename}/${slugifiedTitle}/`, {
                    headers: headers
                })
                if (response.data && response.data.geojson) {
                    setGeoJsonData(response.data.geojson);
                    setJournalName(response.data.journalname);
                    console.log(response.data.geojson)
                    const geometryCount = response.data.geojson.features.length;

                    const features = response.data.geojson.features;

                    features.forEach(feature => {
                        if (feature.properties.geometrytype === 'Point') {
                            EditAndLoadIcon(
                                feature.properties.fillColor,
                                feature.properties.svgIcon,
                                feature.properties.feature_shape,
                                feature.properties.feature_size,
                                feature.properties.border,
                                feature.properties.id,
                                mapRef
                            );
                        }
                    });
        
                    if (!initialSetupCompleted) {
                        if (geometryCount >= 2 && response.data.bounds) {
                            // Ensuring that all bounds properties are defined
                            if (response.data.bounds.west !== undefined && response.data.bounds.south !== undefined &&
                                response.data.bounds.east !== undefined && response.data.bounds.north !== undefined) {
                                const bounds = [
                                    [response.data.bounds.west, response.data.bounds.south],
                                    [response.data.bounds.east, response.data.bounds.north]
                                ];
                                mapRef.current.fitBounds(bounds, { padding: 60, duration: 1000 });
                            }
                        } else if (geometryCount === 1 && response.data.bounds) {
                            // For a single geometry, use flyTo
                            const centralPoint = [
                                response.data.bounds.west + (response.data.bounds.east - response.data.bounds.west) / 2,
                                response.data.bounds.south + (response.data.bounds.north - response.data.bounds.south) / 2
                            ];
                            mapRef.current.flyTo({
                                center: centralPoint,
                                zoom: 10,
                                essential: true
                            });
                        } else {
                            // Default view if no geometries or bounds are not defined
                            mapRef.current.flyTo({
                                center: [-98.4, 38.8],
                                zoom: 9,
                                essential: true
                            });
                        }
                        
                        initialSetupCompleted = true;
                    }
                } else {
                    console.error("Error: Geometries data is missing");
                }
            } catch (error) {
                if (error.response && error.response.data.error === 'access_denied') {
                    navigate('/error', { state: { message: error.response.data.message || 'Access denied' } });
                } else {

                }
            }
        };
    
        fetchData();
    }, [sitename, slugifiedTitle]);

    useEffect(() => {
        if (selectedPostId && geoJsonData && !initialViewCompleted) {
            const numericSelectedPostId = Number(selectedPostId);
            const matchingGeometry = geoJsonData.features.find(f => f.properties.blogid === numericSelectedPostId);
            if (matchingGeometry) {
                updateSelectedGeometry(numericSelectedPostId);
                setInitialViewCompleted(true);
                setIsInitialPostView(false); // Allow regular interactions after the initial view
            }
        }
    }, [selectedPostId, geoJsonData, updateSelectedGeometry, initialViewCompleted]);
    



    const getLayerStyle = (properties) => {
        switch (properties.geometrytype) {
            case 'Point':
                return {
                    id: `pointLayer-${properties.id}`,
                    type: 'circle',
                    paint: {
                        'circle-radius': 6,
                        'circle-color': '#ffef00'
                    }
                };
            case 'LineString':
                return {
                    id: `lineLayer-${properties.id}`,
                    type: 'line',
                    layout: {
                        'line-cap': 'round',
                        'line-join': 'round'
                    },
                    paint: {
                        'line-color': '#ffef00', 
                        'line-width': 2, 
                        'line-opacity': 1, 
                    }
                };
            case 'Polygon':
                return {
                    id: `polygonLayer-${properties.id}`,
                    type: 'fill',
                    paint: {
                        'fill-color': '#00ff00',
                        'fill-opacity': 0.5
                    }
                };
            default:
                return null;
        }
    };

    const HighlightedPoint = React.memo(({ coordinates }) => (
        <Marker longitude={coordinates[0]} latitude={coordinates[1]}>
            <div style={{ backgroundColor: 'red', borderRadius: '50%', width: '12px', height: '12px' }}></div>
        </Marker>
    ));
    
    
    const HighlightedLineString = React.memo(({ coordinates, index }) => {
        const lineData = {
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: coordinates
            }
        };
    
        return (
            <>
                <Source id={`highlighted-line-${index}`} type="geojson" data={lineData}>
                    <Layer
                        id={`highlighted-line-layer-${index}`}
                        type="line"
                        layout={{
                            'line-join': 'round',
                            'line-cap': 'round'  
                        }}
                        paint={{
                            'line-color': '#ff0000', 
                            'line-width': 7
                        }}
                    />
                </Source>
            </>
        );
    });
    
    const toggle3D = () => {
        const map = mapRef.current.getMap();
        const newPitch = is3D ? 0 : 60; 
        setPitch(newPitch)
        map.easeTo({ pitch: newPitch });
        setIs3D(!is3D);
    };
    
    
    const HighlightedPolygon = React.memo(({ coordinates, index }) => {
        const polygonData = {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: coordinates
            }
        };
    
        return (
            <>
                <Source id={`highlighted-polygon-${index}`} type="geojson" data={polygonData}>
                    <Layer
                        id={`highlighted-polygon-layer-${index}`}
                        type="fill"
                        paint={{
                            'fill-color': '#ff0000', 
                            'fill-opacity': 0.5
                        }}
                    />
                </Source>
            </>
        );
    });
    
    const calculateTerrainStats = async (lineStringRoute) => {
        
        if (lineStringRoute.geometry.type !== 'LineString') {
            console.error('The provided feature is not a LineString');
            return;
        }
        // If the check passes, proceed with your logic

        const lineStringFeature = lineStringRoute
        const interval = 0.025;
        const totalLength = turf.length(lineStringFeature, {units: 'miles'});
        const elevationData = []
        for (let i=0; i<=totalLength; i += interval) {
            const point = turf.along(lineStringFeature, i, {units: 'miles'});
            const elevation = await queryElevation(point, mapRef)
            elevationData.push(elevation);
        }
        console.log(mapRef)
        let totalGain = 0;
        let totalLoss = 0;

        for (let i = 1; i < elevationData.length; i++) {
            const delta = elevationData[i] - elevationData[i - 1];
                if (delta > 0) {
                    totalGain += delta;
                } else {
                    totalLoss += Math.abs(delta);
                }
        }

        return {
            totalLength: totalLength,
            totalGain: totalGain,
            totalLoss: totalLoss,
            elevationPoints: elevationData
        }
        
    }

    function queryElevation(point, map) {
        const lngLat = point.geometry.coordinates;
        const elevation = map.current.queryTerrainElevation(lngLat, { exaggerated: false });
        return elevation;
    }

    const handleElevationProfileClick = (elevationPoints) => {
        if (!showChart) {
            setShowChart(true); 
        } else {
            setShowChart(false);
        }
    };

    useEffect(() => {
        if (!elevationPoints.length || !showChart) return;
        const ctx = chartRef.current.getContext('2d');

        // Clear the canvas
        ctx.clearRect(0, 0, chartRef.current.width, chartRef.current.height);
    
        // If a chart instance exists, destroy it to clean up any attached event listeners
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
    
        // Proceed to create a new chart instance
        chartInstanceRef.current = new Chart(ctx, {// Corrected: ensure chartInstanceRef.current is assigned
            type: 'line', // Chart type
            data: {
                // Convert index to cumulative distance in miles and format it
                labels: elevationPoints.map((_, i) => `${(i * 0.025).toFixed(3)} mi`), 
                datasets: [{
                    label: 'Elevation',
                    data: elevationPoints,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    tension: 0.1,
                    borderWidth: 2,
                    pointRadius: 1,
                }]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: { display: false },
                    title: {
                        display: true,
                        text: 'Elevation Profile',
                        font: { size: 18 }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: false
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Elevation (m)',
                            font: { size: 16 }
                        },
                        beginAtZero: false,
                        ticks: {
                            callback: function(value) { return value + ' m'; }
                        }
                    }
                }
            }
        });
    }, [elevationPoints, showChart]);
    

    const interactiveLayerIds = geoJsonData ? geoJsonData.features.map(feature => {
        // Determine the layer type prefix based on the geometry type
        let layerTypePrefix = '';
        if (feature.geometry.type === 'Polygon') {
            layerTypePrefix = 'polygon-layer-';
        } else if (feature.geometry.type === 'LineString') {
            layerTypePrefix = 'line-layer-';
        } else if (feature.geometry.type === 'Point') {
            layerTypePrefix = 'point-layer-';
        }
    
        // Construct the layer ID using the determined prefix and feature's properties.id
        const layerId = `${layerTypePrefix}${feature.properties.id}`;
        return layerId;
    }) : [];
    
    
    
    const handleMapClick = (event) => {
        setShowChart(false);
        let feature = event.features && event.features[0];
        if (!feature) {
            const buffer = 10;
            const bbox = [
                [event.point.x - buffer, event.point.y - buffer],
                [event.point.x + buffer, event.point.y + buffer]
            ];
    
            const features = mapRef.current.queryRenderedFeatures(bbox, { layers: interactiveLayerIds });
            if (features.length > 0) {
                feature = features[0];
            }
        }

        if (feature) {
            const blogId = feature.properties.blogid;
            // Find all geometries with the same blogid
            const relatedGeometries = geoJsonData.features.filter(f => f.properties.blogid === blogId);
            
            setSelectedGeometry(relatedGeometries);
            // Create a FeatureCollection from the related geometries
            const featureCollection = turf.featureCollection(relatedGeometries.map(geometry => {
                return turf.feature(geometry.geometry);
            }));
    
            
            const handleBoundsUpdated = () => {
                // Remove the event listener to avoid recalculating terrain stats unintentionally on subsequent moves
                mapRef.current.off('moveend', handleBoundsUpdated);
                setElevationLoading(true);
                calculateTerrainStats(relatedGeometries[0]).then(terrainStats => {
                    setLengthMiles(terrainStats.totalLength);
                    setGainFeet(terrainStats.totalGain * 3.28084); // Convert meters to feet
                    setLossFeet(terrainStats.totalLoss * 3.28084); // Convert meters to feet
                    setElevationPoints(terrainStats.elevationPoints);
            
                }).catch(error => console.error('Failed to calculate terrain stats:', error));
                setElevationLoading(false);
            };

            // Calculate the bounding box for the FeatureCollection
            const bounds = turf.bbox(featureCollection);
            if (bounds.every(coord => !isNaN(coord))) {
                mapRef.current.fitBounds([
                    [bounds[0], bounds[1]], // southwest coordinates
                    [bounds[2], bounds[3]]  // northeast coordinates
                ], {
                    padding: { top: 0, bottom: 120, left: 30, right: 30 },
                    essential: true,
                });
            
                // Add the event listener for moveend to trigger terrain stats calculation
                mapRef.current.on('moveend', handleBoundsUpdated);
            } else {
                console.error('Invalid bounds:', bounds);
            }
            

            //mapRef.current.setPitch(pitch);
        } else {
            setSelectedGeometry(null);
            setPhotoMarkers([]); 
            setCurrentImageMarker(null); 
        }
    };
    
    
    
    const CameraMarker = ({ marker, index, onClick }) => (
        <Marker key={index} longitude={marker.longitude} latitude={marker.latitude}>
            <div 
                className="camera-marker" 
                style={{ backgroundColor: 'white', borderRadius: '10%' }}
                onClick={(e) => {
                    handlePhotoMarkerClick(index)
                    e.stopPropagation(); 
                }}
            >
                <i className="fas fa-camera" />
            </div>
        </Marker>
    );

    const navigateHome = () => {
        // Construct the full URL by appending the given path to the base URL
        const fullUrl = `${window.location.origin}/home`;

        // Open the new URL in a new tab
        window.open(fullUrl, '_blank');
    }
    
    const StarMarker = ({ marker }) => (
        <Marker longitude={marker.longitude} latitude={marker.latitude}>
            <div className="star-marker" style={{ backgroundColor: 'green', borderRadius: '10%', color: 'yellow' }}>
                <i className="fas fa-star"></i>
            </div>
        </Marker>
    );

    //const onPhotoMarkerClick = (handler) => {
    //    setHandleMarkerClick(() => handler);
    //};

    const renderPoints = () => {
        if (!geoJsonData || !geoJsonData.features) return null;
        return geoJsonData.features
            .filter(feature => feature.geometry.type === 'Point')
            .map((feature, index) => {
                const layerStyle = getLayerStyle(feature.properties);
                return <Layer key={`point-${index}`} {...layerStyle} />;
            });
    };

    const renderedPoints = useMemo(() => renderPoints(), [geoJsonData]);


    const renderLineStrings = () => {
        if (!geoJsonData || !geoJsonData.features) return null;
        return geoJsonData.features
            .filter(feature => feature.geometry.type === 'LineString')
            .map((feature, index) => {
                const layerStyle = getLayerStyle(feature.properties);
                return <Layer key={`line-${index}`} {...layerStyle} />;
            });
    };

    const renderedLineStrings = useMemo(() => renderLineStrings(), [geoJsonData]);

    const renderPolygons = () => {
        if (!geoJsonData || !geoJsonData.features) return null;
        return geoJsonData.features
            .filter(feature => feature.geometry.type === 'Polygon')
            .map((feature, index) => {
                const layerStyle = getLayerStyle(feature.properties);
                return <Layer key={`polygon-${index}`} {...layerStyle} />;
            });
    };

    const handleMapLoad = () => {
        const map = mapRef.current.getMap();
        configure3DTerrain(map);
    }

    const renderedPolygons = useMemo(() => renderPolygons(), [geoJsonData]);

    const handlePhotoMarkerClick = (index) => {
        if (photoMarkers[index]) {
            const selectedMarker = photoMarkers[index];
            setCurrentImageMarker({
                longitude: selectedMarker.longitude,
                latitude: selectedMarker.latitude,
                title: selectedMarker.title
            });
            setSelectedPhotoIndex(index); 
        }
    };

    photoMarkerClickHandlerRef.current = handlePhotoMarkerClick;
    

    return (
        <div className="view-map-container"> 
            <div className={`view-map ${!isUserSignedIn ? 'footer-layout' : ''}`}>
                <Map
                    ref={mapRef}
                    mapboxAccessToken={TOKEN}
                    initialViewState={{
                        longitude: -98.4,
                        latitude: 38.8,
                        zoom: 9
                    }}
                    mapStyle={currentMapStyle} 
                    onClick={handleMapClick}
                    interactiveLayerIds={interactiveLayerIds}
                    onLoad={handleMapLoad} //
                >
                    {geoJsonData && (
                        geoJsonData.features.filter(feature => feature.geometry.type === 'Polygon')
                            .map(feature => (
                                <Source key={feature.properties.id} id={`polygon-${feature.properties.id}`} type="geojson" data={feature}>
                                    <Layer
                                        id={`polygon-layer-${feature.properties.id}`}
                                        type="fill"
                                        source={`polygon-${feature.properties.id}`}
                                        paint={{
                                            'fill-color': feature.properties.fillColor || '#ff0000', // Default color if not specified
                                            'fill-opacity': feature.properties.opacity || 0.6, // Default opacity if not specified
                                        }}
                                    />
                                </Source>
                            ))
                    )}
                    {geoJsonData && (
                        geoJsonData.features.filter(feature =>  feature.geometry.type === 'LineString')
                            .map(feature => (
                                <Source key={feature.properties.id} id={`line-layer-${feature.properties.id}`} type="geojson" data={feature}>
                                   <Layer
                                        id={`line-layer-${feature.properties.id}`}
                                        type="line"
                                        source="route"
                                        layout={{
                                        'line-join': 'round',
                                        'line-cap': 'round'
                                        }}
                                        paint={{
                                        'line-color': feature.properties.fillColor || "#ffe600",
                                        'line-width': feature.properties.feature_size || 6,
                                        ...(feature.properties.feature_shape && { 'line-dasharray': feature.properties.feature_shape.split(',').map(Number) }),
                                        }}
                                    />
                                </Source>
                            ))
                    )}
                    {geoJsonData && (
                        geoJsonData.features.filter(feature =>  feature.geometry.type === 'Point')
                            .map(feature => (
                                <Source key={feature.properties.id} id={`point-layer-${feature.properties.id}`} type="geojson" data={feature}>
                                   <Layer
                                        id={`point-layer-${feature.properties.id}`}
                                        type="symbol"
                                        source={`point-${feature.properties.id}`}
                                        layout={{
                                            'icon-image': feature.properties.svgIcon + feature.properties.id,
                                            'icon-size': 0.05,
                                        }}
                                    />
                                </Source>
                            ))
                    )}

                    {/* COMMENTED OUT 3/12/24
                    REMOVE 3/20/24 */}
                    {/* {geoJsonData && (
                        <>
                            {/* <Source id="polygon-source" type="geojson" data={{ 
                                type: 'FeatureCollection', 
                                features: geoJsonData.features.filter(f => f.geometry.type === 'Polygon') 
                            }}>
                                {renderedPolygons}
                            </Source> */}
                            {/* <Source id="line-source" type="geojson" data={{ 
                                type: 'FeatureCollection', 
                                features: geoJsonData.features.filter(f => f.geometry.type === 'LineString') 
                            }}>
                                {renderedLineStrings}
                            </Source> 
                            <Source id="point-source" type="geojson" data={{ 
                                type: 'FeatureCollection', 
                                features: geoJsonData.features.filter(f => f.geometry.type === 'Point') 
                            }}>
                                {renderedPoints}
                            </Source>
                        </>
                    )} */}
                    <button className="toggle-3d-button" onClick={toggle3D} style={{position: 'absolute', bottom: '2vh', right: '0.5vw', zIndex: 1}}>
                        {is3D ? '2D' : '3D'}
                    </button>
                    {selectedGeometry && selectedGeometry.length > 0 && (
                        <BlogPopup
                            data={selectedGeometry[0].properties}
                            coordinates={turf.centroid(turf.feature(selectedGeometry[0].geometry)).geometry.coordinates} 
                            journalName={journalname}
                            onUpdateSelectedGeometry={updateSelectedGeometry}
                            onMarkersUpdate={setPhotoMarkers} 
                            photoMarkerClickHandlerRef={photoMarkerClickHandlerRef}
                            currentPhotoIndex={currentPhotoIndex}
                            selectedPhotoIndex={selectedPhotoIndex}
                            onCurrentImageChange={(marker) => {
                                if (marker) {
                                    setCurrentImageMarker(marker);
                                } else {
                                    setCurrentImageMarker(null); 
                                }
                            }}
                            onPhotoMarkerClick={handlePhotoMarkerClick}
                            onClose={() => {
                                setShowChart(false);
                                setSelectedGeometry(null)
                                setPhotoMarkers([]); 
                                setCurrentImageMarker(null); 
                            }}
                            setBlogTitle={setBlogTitle}
                            setBlogDate={setBlogDate}
                            lengthMiles={lengthMiles}
                            gainFeet={gainFeet}
                            lossFeet={lossFeet}
                            elevationPoints={elevationPoints}
                            handleElevationProfileClick={handleElevationProfileClick}
                            elevationLoading={elevationLoading}
                            setElevationLoading={setElevationLoading}
                        />
                    )}
                    {showChart && (
                        <div className="graph-container" style={{ display: 'block' }}>
                            <canvas className="graph-canvas" ref={chartRef}></canvas>
                        </div>
                    )}
                    {photoMarkers.map((marker, index) => (
                        <CameraMarker 
                            key={index}
                            marker={marker} 
                            index={index} 
                            onClick={(index) => {
                                if (photoMarkerClickHandlerRef.current) {
                                    photoMarkerClickHandlerRef.current(index);
                                }
                            }}
                        />
                    ))}
                    {currentImageMarker && <StarMarker marker={currentImageMarker} />}

                    {/* ADD BACK IN LATER - THIS IS FOR HIGHLIGHTING SELECTED GEOMETRIES */}
                    {/* {selectedGeometry && selectedGeometry.length > 0 && (
                        <>
                            {selectedGeometry.map((geometry, index) => {
                                if (geometry.geometry.type === 'Point') {
                                    return (
                                        <HighlightedPoint
                                            key={`highlighted-point-${index}`}
                                            coordinates={geometry.geometry.coordinates}
                                        />
                                    );
                                }
                                if (geometry.geometry.type === 'LineString') {
                                    return (
                                        <HighlightedLineString
                                            key={`highlighted-line-${index}`}
                                            coordinates={geometry.geometry.coordinates}
                                        />
                                    );
                                }
                                if (geometry.geometry.type === 'Polygon') {
                                    return (
                                        <HighlightedPolygon
                                            key={`highlighted-polygon-${index}`}
                                            coordinates={geometry.geometry.coordinates}
                                        />
                                    );
                                }
                                return null; // Return null for unsupported geometry types
                            })}
                        </>
                    )} */}

                </Map>
                {!isMobile && (
                    <select onChange={handleBaseMapChange} style={{ position: 'absolute', top: 20, right: 20, zIndex: 1 }}>
                        {Object.keys(baseMapStyles).map((style) => (
                        <option key={style} value={style}>{style}</option>
                        ))}
                    </select>
                )}
                {
                    (!isMobile || !selectedGeometry) ? (
                        MapLogoAndTitle()
                    ) : (
                        <div className="mobile-blog-header">
                        <h3>{blogTitle}</h3>
                        <p>{blogDate}</p>
                        </div>
                    )
                }
            </div>
            {!isUserSignedIn && <MapFooter isMobile={isMobile} isUserSignedIn={isUserSignedIn} />}
        </div>
    );
};

export default ViewMap;


