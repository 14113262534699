import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation, useNavigate, createRoutesFromChildren } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { useNavigation } from '../../../context/Context';
import  { Marker } from 'react-map-gl';

import axios from 'axios';

import * as turf from '@turf/turf';
import bbox from '@turf/bbox';
import imageCompression from 'browser-image-compression';
import WalkthroughPopup from '../../_Shared_ProjectWide/Popups/WalkthroughPopup';
import JournalHeader from './JournalHeader';
import TitleInput from './FormTitle';
import DateInput from './FormDate';
import PhotosInput from './FormPhotos';
import GeometryInput from './FormGeometry';
import SearchModal from './SearchModal';
import WalkthroughCreate from './WalkthroughCreate';
import JournalEntryMap from './FormMap';
import PhotoInfoPopup from './PhotoInfoPopup';
import HelpfulHint from '../../_Shared_ProjectWide/Popups/HelpfulHint';
import UploadMultiplePhotos from './UploadMultiplePhotos';
import MapGeometryInfo from './MapGeometryInfo';
import FormValidator from './FormValidator';
// import LoadIconImages from './LoadIconImages';
import EditAndLoadIcon from './EditAndLoadIcon';
import AddMapControls from './AddMapControls';
import PointIconModal from './PointIconModal';
import Modal from 'react-bootstrap/Modal';

import convertHEICToJPG from './HEICtoJPEG';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';



const CreateEditJournalEntry = (mode) => {
    const [title, setTitle] = useState('');
    const { postid } = useParams();
    const [post, setPost] = useState('');
    const [body, setBody] = useState('');
    const [date, setDate] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [sitename, setSiteName] = useState(queryParams.get('site') || '');
    const [slugifiedTitle, setSlugifiedTitle] = useState(queryParams.get('title') || '');
    const [featureNames, setFeatureNames] = useState({});
    const [features, setFeatures] = useState({});
    const [photos, setPhotos] = useState([]);
    const { isAuthorized, setIsAuthorized } = useNavigation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState({index: null, title: '', description: '', file: null});
    const [showSessionTimeoutPopup, setShowSessionTimeoutPopup] = useState(false);
    const mapRef = useRef();
    const [mapViewState, setMapViewState] = useState(null);
    const [titleError, setTitleError] = useState('');
    const [dateError, setDateError] = useState('');
    const [geometryError, setGeometryError] = useState('');
    const [geoTaggedPoints, setGeoTaggedPoints] = useState({});
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [editingGeoTagIndex, setEditingGeoTagIndex] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isSearchModalOpen, setSearchModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [routeMode, setRouteMode] = useState('none'); 
    const [waypoints, setWaypoints] = useState([]);
    const [routeSegments, setRouteSegments] = useState([]);
    const [routeGeoJSON, setRouteGeoJSON] = useState(null);
    const [uiMode, setUIMode] = useState('off');
    const [newFeatureName, setNewFeatureName] = useState('');
    const controlsRef = useRef({});
    const [controlsAdded, setControlsAdded] = useState(false);
    const bodyRef = useRef(null);
    const [currentMapStyle, setCurrentMapStyle] = useState("mapbox://styles/mapbox/outdoors-v11"); 
    const [backendUrl, setBackendUrl] = useState('');
    const currentClickListenerRef = useRef(null);
    const [isFormVisible, setIsFormVisible] = useState(true); 
    const [isGeoInfoVisible, setIsGeoInfoVisible] = useState(true); 
    const [pointFeatures, setPointFeatures] = useState({});
    const [polygonFeatures, setPolygonFeatures] = useState({});
    const [tempWaypoint, setTempWaypoint] = useState(null);
    const tempMarkerRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState('');
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

    // Return non-logged in users home
    
    // useEffect(() => {
    //     if (!isAuthorized) {
    //         navigate('/home');
    //     }
    // }, [isAuthorized, navigate]);


    useEffect(() => {
        updateInputLabel(photos.length);
    }, [photos]);

    const toggleFormVisibility = () => setIsFormVisible(!isFormVisible);
    const toggleGeoInfoVisibility = () => {
        setIsGeoInfoVisible(!isGeoInfoVisible);
    }

    // Fetch initial map view data from the backend
    useEffect(() => {
        const fetchCreateData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/get-map-start/?site=${encodeURIComponent(sitename)}&slugTitle=${encodeURIComponent(slugifiedTitle)}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = response.data;
                setMapViewState({
                    longitude: data.longitude,
                    latitude: data.latitude,
                    zoom: data.zoom
                });
            } catch (error) {
                console.error('Error fetching map data:', error.message || error);
            }
        };

        const fetchEditData = async () => {
            try {
                setIsProcessing(true)
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/edit-post/${postid}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
        
                const postData = response.data;
                
                setPost(postData); 
                setTitle(postData.post.title || '');
                setDate(postData.post.date || '');
                if (bodyRef.current) {
                    bodyRef.current.value = postData.post.content; 
                }
                setSiteName(postData.post.blog_site_name);
                setSlugifiedTitle(postData.post.blog_page_slug);
                

                if (postData.images && Array.isArray(postData.images)) {
                    try {
                        const formattedPhotosResponse = await Promise.all(postData.images.map(async photo => {
                            const fullUrl = photo.url;
                            const response = await fetch(fullUrl);
                            if (!response.ok) throw new Error('Network response was not ok.');
            
                            const blob = await response.blob();
                            const fileExtension = photo.url.split('.').pop();
                            const fileName = photo.title || `image.${fileExtension}`; 
                            const fileType = blob.type || `image/${fileExtension}`; 
                            const file = new File([blob], fileName, { type: fileType });
            
                            return {
                                id: photo.id, 
                                title: photo.title || '',
                                description: photo.description || '',
                                file: file,
                                longitude: photo.longitude|| '',
                                latitude: photo.latitude|| '',
                            };
                        }));
            
                        // Then filter out any null values
                        const formattedPhotos = formattedPhotosResponse.filter(photo => photo !== null);
                        setPhotos(formattedPhotos);
                        
                        const initialGeoTags = {};
                        postData.images.forEach((image) => {
                            if (image.longitude && image.latitude) {
                                initialGeoTags[image.id] = {
                                    id: image.id,
                                    longitude: image.longitude,
                                    latitude: image.latitude
                                };
                            }
                        });
                        setGeoTaggedPoints(initialGeoTags);
                        
                    } catch (error) {
                        console.error('Error fetching post details:', error);
                    }
                } else {
                    setPhotos([]);
                }
                const featureNamesInit = {};
                Object.keys(features).forEach(featureId => {
                    featureNamesInit[featureId] = ''; 
                });
                setFeatureNames(featureNamesInit);
        
                if (postData.geometries && Array.isArray(postData.geometries)) {
                    const formattedFeatures = postData.geometries.map(geometry => ({
                        id: geometry.id, 
                        type: 'Feature', 
                        geometry: {
                            type: geometry.type, 
                            coordinates: geometry.geometry.coordinates 
                        },
                        properties: {
                            name: geometry.name,
                            id: geometry.id,
                            color: geometry.fillColor,
                        }
                    }));
                    setFeatures(formattedFeatures);

                    // Calculate the bounding box for the new features
                    const featuresGeoJson = {
                        type: 'FeatureCollection',
                        features: formattedFeatures
                    };
                    const calculatedBbox = bbox(featuresGeoJson);

                    const centroid = [
                        (calculatedBbox[0] + calculatedBbox[2]) / 2, // Longitude
                        (calculatedBbox[1] + calculatedBbox[3]) / 2, // Latitude
                    ];

                    setMapViewState({
                        longitude: centroid[0],
                        latitude: centroid[1],
                        zoom: 12,
                    });

                    // Use mapRef to fly to the calculated bounding box
                    if (mapRef.current) {
                        mapRef.current.fitBounds([
                            [calculatedBbox[0], calculatedBbox[1]], 
                            [calculatedBbox[2], calculatedBbox[3]]  
                        ], {
                            padding: 150 
                        });
                    }

                    if (postData.geometries && Array.isArray(postData.geometries)) {
                        // Start an array of promises for calculating terrain stats for each geometry
                        const promises = postData.geometries.map(async (geometry) => {
                            if (geometry.type === 'Point') {
                                EditAndLoadIcon(
                                    geometry.fillColor, 
                                    geometry.svgIcon, 
                                    geometry.featureShape, 
                                    geometry.featureSize, 
                                    geometry.border, 
                                    geometry.id, 
                                    mapRef
                                )
                            }
                            const feature = {
                                id: geometry.id, 
                                type: 'Feature', 
                                geometry: {
                                    type: geometry.type, 
                                    coordinates: geometry.geometry.coordinates 
                                },
                                properties: {
                                    name: geometry.name,
                                    id: geometry.id,
                                    mode: geometry.routeMode,
                                    color: (geometry.fillColor ? geometry.fillColor : '#ff0000'),
                                    opacity: (geometry.opacity ? geometry.opacity : 0.6),
                                    shapeSize: (geometry.featureSize ? geometry.featureSize : 1),
                                    featureShape: geometry.type === "Point" ? geometry.featureShape : (geometry.featureShape ? geometry.featureShape.split(',').map(Number) : null),
                                    selectedSvgIcon: (geometry.type === 'Point') ? geometry.svgIcon + geometry.id : null,
                                    svgIcon: geometry.svgIcon ? geometry.svgIcon : null,
                                    borderOption: geometry.border ? geometry.border : null,
                                    // Initialize properties to avoid undefined errors
                                    length: 0,
                                    gain: 0, 
                                    loss: 0,
                                    
                                }
                            };
                           
                            setFeatureNames(prevFeatureNames => ({
                                ...prevFeatureNames,
                                [feature.id]: feature.properties.name,
                            }));

                            // Calculate terrain stats if geometry is a LineString
                            if (geometry.type === 'LineString') {
                                const elevationProfile = await calculateTerrainStats(turf.lineString(geometry.geometry.coordinates));
                                feature.properties.length = elevationProfile.length;
                                feature.properties.gain = elevationProfile.gain;
                                feature.properties.loss = elevationProfile.loss;
                            } 
                            // else if (geometry.type === 'Polygon') {
                            //     // Calculate the area in square meters
                            //     console.log(geometry.geometry.coordinates)
                            //     const areaInSquareMeters = turf.area(geometry.geometry.coordinates);
                            //     // Convert square meters to acres (1 square meter = 0.000247105 acres)
                            //     const areaInAcres = areaInSquareMeters * 0.000247105;
                            //     feature.properties.area = areaInAcres;
                            // }
                    
                            return feature;
                        });
                    
                        // Await all the promises to resolve
                        const formattedFeatures = await Promise.all(promises);
                    
                        setFeatures(formattedFeatures.reduce((acc, feature) => ({
                            ...acc,
                            [feature.id]: feature
                        }), {}));
                    
                        // Continue with bbox and map view state setting...
                    } 

                } else {
                    setFeatures([]);
                }
                
        
                //Initialize featureNames

                setIsProcessing(false);
            } catch (error) {
                setIsProcessing(false);
                if (error.response) {
                    const status = error.response.status;
                    switch (status) {
                        case 401:
                        // Set state to true to show the session timeout popup
                        setShowSessionTimeoutPopup(true);
                        break;
                    case 403:
                        // Handle forbidden access
                        alert('Forbidden Access! You do not have permission to view this resource.');
                        navigate('/home');
                        break;
                    case 404:
                        // Handle not found
                        alert('Resource not found. The post you are trying to access do not exist.');
                        navigate('/home');
                        break;
                    default:
                        // Handle other errors
                        console.error('Error fetching post:', error);
                        navigate('/home');
                        break;
                    }
                } else {
                    // Handle errors that don't have a response (like network issues)
                    console.error('Network error or other non-HTTP error:', error);
                    // Show a message or take action for network-related issues
                }
            }
        };
        


        if (mode.mode==='CREATE') {
            setBackendUrl('api/user-posts/create-post/')
            fetchCreateData();
        } else {
            setBackendUrl(`api/user-posts/update-post/${postid}`)
            fetchEditData();
            // setMapViewState({
            //     longitude: -122.45,
            //     latitude: 37.78,
            //     zoom: 12
            // });
        }
    }, [sitename, slugifiedTitle]);


  

    // render photo images
    const renderGeoTaggedPoints = () => {
        return Object.entries(geoTaggedPoints).map(([photoId, point]) => {
            // Treat both photoId and the id in photos as strings for comparison
            const photo = photos.find(p => p.id.toString() === photoId);
    
            // Since editingGeoTagIndex is being compared to photoId, ensure they are both treated as strings
            const isEditing = editingGeoTagIndex ? editingGeoTagIndex.toString() === photoId : false;
            const markerStyle = isEditing ? "camera-icon-marker-editing" : "camera-icon-marker";
    
            const coordinates = [point.longitude, point.latitude];
    
            return (
                <Marker 
                    key={photoId} 
                    longitude={coordinates[0]} 
                    latitude={coordinates[1]}
                    onClick={() => setSelectedMarker({
                        longitude: coordinates[0], 
                        latitude: coordinates[1], 
                        title: photo?.title || 'Untitled Photo'
                    })}
                >
                    <div className={markerStyle}>
                        <i className="fas fa-camera" />
                    </div>
                </Marker>
            );
        });
    };
    
    




    const generateUniqueId = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    const showProcessingPopup = () => {
        setIsProcessing(true);
    };
      



    const handleMapRef = (ref) => {
        //setMapRef(ref);
    }
    

    const handlePhotoChange = async (e) => {
        setIsUploadingPhotos(true);
        const selectedFiles = Array.from(e.target.files);
        const updatedFilesWithMeta = []; 
        const invalidFiles = [];
        let filesOver5MB = 0;
        const validImageTypes = ['image/jpeg', 'image/png'];

        for (let i = 0; i < selectedFiles.length; i++) {
            let file = selectedFiles[i];
            const meta = {
                id: generateUniqueId(),
                title: '',
                description: ''
            };
    
            // Convert HEIC to JPG if necessary
            if (file.type === 'image/heic' || file.name.endsWith('.heic')) {
                const convertedFile = await convertHEICToJPG(file);
                if (convertedFile) {
                    file = convertedFile; // Update the file reference to the converted file
                }
            }
        
            // Compress the file
            try {
                const options = {
                    maxSizeMB: 5, 
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                };
                const compressedFile = await imageCompression(file, options);
                file = compressedFile; // Update the file reference to the compressed file
            } catch (error) {
                console.error("Error compressing the image:", error);
            }
    
        
            // Check the file size after potential conversion and compression
            const fileSizeMB = file.size / 1024 / 1024;
        
            if (!validImageTypes.includes(file.type)) {
                invalidFiles.push(file);
            } else if (fileSizeMB > 5) {
                filesOver5MB++;
            } else {
                updatedFilesWithMeta.push({...meta, file});
            }
        }
    
        if (invalidFiles.length > 0 || filesOver5MB > 0) {
          let alertMessage = '';
      
          if (invalidFiles.length > 0) {
            alertMessage += `${invalidFiles.length} file(s) were not JPG, JPEG, PNG, or HEIC. `;
          }
      
          if (filesOver5MB > 0) {
            alertMessage += `${filesOver5MB} file(s) were over 5MB. `;
          }
      
          alert(alertMessage);
        }
      
        setPhotos(currentPhotos => {
            const updatedPhotos = [...currentPhotos, ...updatedFilesWithMeta];
            updateInputLabel(updatedPhotos.length);
            setIsUploadingPhotos(false);
            return updatedPhotos;
        });
      
        e.target.value = '';
    };
      
      
    
    // Function to update the input label
    const updateInputLabel = (numberOfPhotos) => {
        const label = document.querySelector('.photo-upload-label');
        label.textContent = numberOfPhotos === 0 ? "Choose files..." : `${numberOfPhotos} Photos Selected`;
    };
    
    
    const initiateGeotagging = (photoIndex, photoId) => {
        setIsFormVisible(false);
        setEditingGeoTagIndex(photoId);
        const map = mapRef.current.getMap();
    
        // Remove any existing click listener
        if (currentClickListenerRef.current) {
            map.off('click', currentClickListenerRef.current);
        }
    
        // Define a new click listener that captures the current photoId
        const handleClick = (event) => {
            const { lng, lat } = event.lngLat;
            updateGeotags(lng, lat, photoId); 
            setEditingGeoTagIndex(null);
            setIsFormVisible(true);
            if (currentClickListenerRef.current) {
                map.off('click', currentClickListenerRef.current);
            }
        };
    
        // Update the ref and set the new listener
        currentClickListenerRef.current = handleClick;
        map.on('click', handleClick);
    };
    

    const updateGeotags = (lng, lat, photoId) => {
        setGeoTaggedPoints(prevPoints => {
            const newPoints = { ...prevPoints };
            newPoints[photoId] = { id: photoId, longitude: lng, latitude: lat };
            return newPoints;
        });
    };
    
    const openIconModal = () => {
        setIsModalOpen(true);
    }

    const handleIconSelect = (icon) => {
        setSelectedIcon(icon);
        setIsModalOpen(false);
    };
    
    
    const updateCursorStyle = (cursorStyle) => {
        if (mapRef.current) {
          const map = mapRef.current.getMap();
          map.getCanvas().style.cursor = cursorStyle;
        }
    };

    const saveFeatureName = (featureId, newName, newColor) => {
        // Update the main state to reflect changes in the main table
        setFeatureNames(prevFeatureNames => ({
            ...prevFeatureNames,
            [featureId]: newName,
        }));

         // Update the features to change the color property
        setFeatures(prevFeatures => {
            // Assuming prevFeatures is an object where keys are feature IDs
            // If your structure is different (e.g., an array), you'll need to adjust this logic accordingly
            const updatedFeatures = { ...prevFeatures };
            if (updatedFeatures[featureId]) {
                updatedFeatures[featureId] = {
                    ...updatedFeatures[featureId],
                    properties: {
                        ...updatedFeatures[featureId].properties,
                        name: newName, // Update name here as well, if you store it within the feature properties
                        color: newColor,
                    },
                };
            }
            return updatedFeatures;
        });

        // After saving, reset UI mode and selected feature
        setUIMode('off');
        setSelectedFeature(null);
    };
    
    const cancelEdit = () => {
        // Simply reset UI mode and selected feature without applying changes
        setUIMode('off');
        setSelectedFeature(null);
    };

    ////// NEW MAP DRAWING
    const fetchSegmentDirections = async (startPoint, endPoint, mode) => {
        const waypointsString = `${startPoint};${endPoint}`;
        const profileMap = { walk: 'walking', bike: 'cycling', drive: 'driving' };
        const profile = profileMap[routeMode] || 'walking';
    
        const url = `https://api.mapbox.com/directions/v5/mapbox/${profile}/${waypointsString}?geometries=geojson&access_token=${TOKEN}&steps=true`;
        try {
            const response = await fetch(url);
            const data = await response.json();
    
            if (data.routes && data.routes.length > 0) {
                const route = data.routes[0].geometry;
                return route;
            }
        } catch (error) {
            console.error("Failed to fetch directions", error);
        }
    };
    
    const addWaypointAndUpdateRoute = async (newWaypoint, mode) => {
        if (waypoints.length >= 1) {
            const lastWaypoint = waypoints[waypoints.length - 1];
            
            let newSegmentGeometry;

            if (mode === 'free_draw' || mode === 'polygon') {
                // Create a straight line segment between the last waypoint and the new waypoint
                newSegmentGeometry = {
                    type: 'LineString',
                    coordinates: [lastWaypoint, newWaypoint],
                };
            } else {
                // Fetch the geometry for the new segment using directions API for other modes
                newSegmentGeometry = await fetchSegmentDirections(lastWaypoint, newWaypoint, mode);
            }
            // Directly append the geometry object as the segment
            if (newSegmentGeometry) {
                setRouteSegments(prevSegments => [...prevSegments, newSegmentGeometry]);
            }
        }
        // Update waypoints
        setWaypoints(prevWaypoints => [...prevWaypoints, newWaypoint]);
    };



    const updateFullRouteGeoJSON = () => {
        const fullRouteFeatures = routeSegments.filter(segment => segment !== undefined).map(segment => ({
            type: 'Feature',
            properties: {},
            geometry: segment, 
        }));
    
        const fullRoute = {
            type: 'FeatureCollection',
            features: fullRouteFeatures,
        };
    
        setRouteGeoJSON(fullRoute);
    };

    useEffect(() => {
        if (routeSegments.length > 0) {
            updateFullRouteGeoJSON();
        }
    }, [routeSegments]); 

    const handleMapClick = async (event) => {
        const { lng, lat } = event.lngLat;
        
        if (routeMode === 'none') {
            return;
        }

    
        // Fetch and draw route if there are at least two waypoints
        if (routeMode === 'point') {
            const { lng, lat } = event.lngLat;
            setTempWaypoint({longitude: lng, latitude: lat});
        } else {
            // For other modes, handle as before
            setTempWaypoint(null);
            const newWaypoint = [lng, lat];
            addWaypointAndUpdateRoute(newWaypoint, routeMode);
        }
    };


    const calculateTerrainStats = async (lineStringRoute) => {

        const lineStringFeature = lineStringRoute
        const interval = 0.005;
        const totalLength = turf.length(lineStringFeature, {units: 'miles'});
        const elevationData = []
        for (let i=0; i<=totalLength; i += interval) {
            const point = turf.along(lineStringFeature, i, {units: 'miles'});
            const elevation = await queryElevation(point, mapRef)
            elevationData.push(elevation);
        }

        let totalGain = 0;
        let totalLoss = 0;

        for (let i = 1; i < elevationData.length; i++) {
            const delta = elevationData[i] - elevationData[i - 1];
                if (delta > 0) {
                    totalGain += delta;
                } else {
                    totalLoss += Math.abs(delta);
                }
        }

        return {
            length: totalLength,
            gain: totalGain * 3.28084, //convert to feet
            loss: totalLoss * 3.28084 //convert to feet
        };
    }

    function queryElevation(point, map) {
        const lngLat = point.geometry.coordinates;
        const elevation = map.current.queryTerrainElevation(lngLat, { exaggerated: false });
        return elevation;
    }

    const finishRouteCreation = async(featureType, formattingData) => {
        if (featureType === 'polygon' && waypoints.length > 2) { // Ensure at least 3 points for a valid polygon
            const newFeatureId = generateUniqueId();
            
            const closedCoordinates = [...waypoints, waypoints[0]]; // Ensure the polygon is closed by repeating the first point at the end
            // Temporary polygon object for area calculation
            const tempPolygonForAreaCalc = turf.polygon([closedCoordinates]);
    
            // Calculate the area in square meters
            const areaInSquareMeters = turf.area(tempPolygonForAreaCalc);
            // Convert square meters to acres (1 square meter = 0.000247105 acres)
            const areaInAcres = areaInSquareMeters * 0.000247105;

            const newPolygonFeature = {
                id: newFeatureId,
                type: 'Feature',
                properties: {
                    name: formattingData.newFeatureName || 'Unnamed Polygon',
                    mode: 'polygon',
                    area: areaInAcres,
                    color: formattingData.fillColor,
                    opacity: parseFloat(formattingData.opacity),
                },
                geometry: {
                    type: 'Polygon',
                    coordinates: [closedCoordinates], // Polygons expect an array of linear rings
                },
            };
            setFeatures(currFeatures => ({
                ...currFeatures,
                [newFeatureId]: newPolygonFeature
            }));
        
            // Update state to include the new Polygon feature
            setPolygonFeatures(currPolygonFeatures => ({
                ...currPolygonFeatures,
                [newFeatureId]: newPolygonFeature
            }));
        
            setFeatureNames(currFeatureNames => ({
                ...currFeatureNames,
                [newFeatureId]: newFeatureName || 'Unnamed Polygon'
            }));
        } else if (featureType === 'point' && tempWaypoint) {
            // Now we create the point feature from the tempWaypoint
            const newFeatureId = generateUniqueId();
            const fillColor = formattingData.fillColor;
            const userSelectedIcon = formattingData.userSelectedIcon.replace('.svg', '');
            const selectedShape = formattingData.selectedShape;
            const selectedSize = formattingData.selectedSize;
            const borderOption = formattingData.borderOption;
            EditAndLoadIcon(fillColor, userSelectedIcon, selectedShape, selectedSize, borderOption, newFeatureId, mapRef)
            const newPointFeature = {
                id: newFeatureId,
                type: 'Feature',
                properties: {
                    name: newFeatureName || 'Unnamed Point',
                    mode: 'Point',
                    selectedSvgIcon: userSelectedIcon + newFeatureId, // used for rendering on map
                    //used for saving preferences to backend:
                    svgIcon: userSelectedIcon,
                    color: fillColor,
                    featureShape: selectedShape,
                    borderOption: borderOption,
                    shapeSize: selectedSize,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [tempWaypoint.longitude, tempWaypoint.latitude],
                },
            };
    
            // Update state to include the new Point feature
            setFeatures(currFeatures => ({
                ...currFeatures,
                [newFeatureId]: newPointFeature
            }));
    
            // Optionally, manage names similarly to routes if needed
            setFeatureNames(currFeatureNames => ({
                ...currFeatureNames,
                [newFeatureId]: newFeatureName || 'Unnamed Point'
            }));
            
            // Reset tempWaypoint after creating the feature
            setTempWaypoint(null);

        } else if (featureType === 'line' && routeGeoJSON && routeGeoJSON.features.length > 0) {

            const newFeatureId = generateUniqueId();
    
            // Assuming all features in the collection are LineStrings and connected end-to-end
            const combinedCoordinates = routeGeoJSON.features.flatMap(feature => 
                feature.geometry.coordinates
            );

            const elevationProfile = await calculateTerrainStats(turf.lineString(combinedCoordinates))

            const routeLengthMiles = elevationProfile.length;
            const totalGain = elevationProfile.gain;
            const totalLoss = elevationProfile.loss;
            const newFeature = {
                id: newFeatureId,
                type: 'Feature',
                properties: {
                    name: newFeatureName || 'Unnamed Route',
                    length: routeLengthMiles,
                    gain: totalGain,
                    loss: totalLoss,
                    mode: routeMode,
                    color: formattingData.fillColor,
                    shapeSize: parseInt(formattingData.selectedSize),
                    featureShape: formattingData.lineDashArray,
                },
                geometry: {
                    type: 'LineString', 
                    coordinates: combinedCoordinates,
                },
            };
            // console.log(newFeature)
            setFeatures(currFeatures => ({
                ...currFeatures,
                [newFeatureId]: newFeature
            }));
    
            setFeatureNames(currFeatureNames => ({
                ...currFeatureNames,
                [newFeatureId]: newFeatureName || 'Unnamed Route'
            }));
        }

        // Reset route creation states
        setRouteGeoJSON(null);
        setTempWaypoint(null);
        setWaypoints([]);
        setRouteSegments([]);
        resetModes();
        setNewFeatureName('');
    };
    


    


    const resetModes = () => {
        // Reset route mode to a default state
        setRouteMode('none');
        setUIMode('off');
        updateCursorStyle('');
        setSelectedFeature();
    };

    // makes free draw update to crosshair pointer
    useEffect(() => {
        //console.log(routeMode)
        //console.log(cursorStyle)
    }, [routeMode])
    
    // Remove last waypoint from
    const undoLastWaypoint = () => {
        if (waypoints.length > 0) {
            // Remove the last waypoint
            const newWaypoints = waypoints.slice(0, -1);
            setWaypoints(newWaypoints);
    
            // Remove the last route segment
            const newRouteSegments = routeSegments.slice(0, -1);
            setRouteSegments(newRouteSegments);
    
            if (newWaypoints.length < 2) {
                setRouteGeoJSON(null); // Clear the drawn route if less than 2 waypoints
            }
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
          // Determine whether the Cmd key is used for macOS or Ctrl key for others
          const isUndoShortcut = (event.ctrlKey || event.metaKey) && event.key === 'z';
          if (uiMode === 'create-feature') {
            if (isUndoShortcut) {
                undoLastWaypoint();
                event.preventDefault(); // Prevent the default undo functionality of the browser
            }
          }
        };
      
        document.addEventListener('keydown', handleKeyDown);
      
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [undoLastWaypoint]); 

      
    const cancelRouteCreation = () => {
        // Reset route creation states
        setRouteGeoJSON(null);
        setWaypoints([]);
        resetModes(); 
        setTempWaypoint(null);
        setRouteSegments([]);
        setNewFeatureName('');
    }

    const handleMapLoad = () => {
        const map = mapRef.current.getMap();
        // Initialize the Geocoder
        AddMapControls(setRouteMode, updateCursorStyle, setUIMode, mapRef, controlsAdded, setControlsAdded, handleSearchButtonClick, isMobile, TOKEN);
        // LoadIconImages(mapRef);
        configure3DTerrain(map);
    };

    const handleSearchButtonClick = () => {
        setSearchModalOpen(true);
    }

    const configure3DTerrain = (map) => {
        if (!map.getSource('mapbox-dem')) {
            map.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
                'tileSize': 512,
                'maxzoom': 14
            });
        }
    
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1 });
    
        if (!map.getLayer('sky')) {
            map.addLayer({
                'id': 'sky',
                'type': 'sky',
                'paint': {
                    'sky-type': 'atmosphere',
                    'sky-atmosphere-sun': [0.0, 0.0],
                    'sky-atmosphere-sun-intensity': 15
                }
            });
        }
    };

    const handleEditFeatureClick = (e) => {

    }

    const editFeature = (feature) => {

        if (uiMode === 'edit-feature' && feature === selectedFeature) {
            setUIMode('off');
            setSelectedFeature(null);
        } else {
            setUIMode('edit-feature');
            setSelectedFeature(feature);
        }
    }
    
    useEffect(() => {
        const mapInstance = mapRef.current;
        if (!controlsAdded && mapInstance) {
            AddMapControls(setRouteMode, updateCursorStyle, setUIMode, mapRef, controlsAdded, setControlsAdded, handleSearchButtonClick, isMobile, TOKEN);
        }
      
        // Cleanup function
        return () => {
          if (mapInstance && controlsAdded) {
            const map = mapInstance.getMap();
            // Access controls from the ref for removal
            Object.values(controlsRef.current).forEach(control => {
              if (control && typeof map.removeControl === 'function') {
                map.removeControl(control);
              }
            });
            setControlsAdded(false);
          }
        };
      }, [controlsAdded]);


    // const clearRoute = () => {
    //     setWaypoints([]);
    //     setRouteGeoJSON(null);
    // };

    


    // photos
    // delete photo and remove geotagged markers
    const deletePhoto = (id) => {
        setPhotos(currentPhotos => {
            const updatedPhotos = currentPhotos.filter(photo => photo.id !== id);
            updateInputLabel(updatedPhotos.length); 
            return updatedPhotos;
        });
        setGeoTaggedPoints(prevPoints => {
            const updatedPoints = Object.entries(prevPoints).reduce((acc, [key, value]) => {
                if (value.id !== id) {
                    acc[key] = value;
                }
                return acc;
            }, {});
            return updatedPoints;
        });
    };
    
    // open the photo title and description naming modal
    const openModal = (index) => {
        const photo = photos[index];
        if (photo && photo.file) {
            setCurrentPhoto({ 
                index, 
                title: photo.title || '', 
                description: photo.description || '', 
                file: photo.file 
            });
            setShowModal(true);
        }
    };

    // handle change of photo title name
    const handleTitleChange = (e) => {
        setCurrentPhoto(prev => ({ ...prev, title: e.target.value }));
    };
    
    // handle change of photo description
    const handleDescriptionChange = (e) => {
        setCurrentPhoto(prev => ({ ...prev, description: e.target.value }));
    };


    // save title and description information to photo
    const savePhotoInfo = () => {
        setPhotos(currentPhotos => {
            return currentPhotos.map((photo, index) => {
                if (index === currentPhoto.index) {
                    return { ...photo, title: currentPhoto.title, description: currentPhoto.description };
                }
                return photo;
            });
        });
        closeModal();
    };

    // close modal
    const closeModal = () => {
        setShowModal(false);
    };    
    


    // show the time out warning
    const handleSessionTimeout = () => {
        // Close the popup
        setShowSessionTimeoutPopup(false);
    
        // Redirect to the home page
        localStorage.removeItem('token');
        navigate('/home/');
    };


    // show the error modal when publishing/saving post fails
    const ErrorModal = ({ show, errorMessage, onClose, onRetry }) => {
        return (
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <button onClick={onClose}>Home</button>
                    <button onClick={onRetry}>Return to Post</button>
                </Modal.Footer>
            </Modal>
        );
    };



    // handle publish or save post
    const submitPost = async(status, e) => {
        e.preventDefault();
        if (!FormValidator({title, setTitleError, uiMode, setGeometryError, features, photos, geoTaggedPoints, date, setDateError})) {
            return;
        }

        const bodyValue = bodyRef.current.value;

        const formData = new FormData();
        formData.append('title', title);
        formData.append('date', date);
        formData.append('body', bodyValue);
        formData.append('site', sitename);
        formData.append('slugTitle', slugifiedTitle);
        formData.append('postStatus', status);
        showProcessingPopup();
        // const filesToUpload = photos.map(photo => photo.file);
        // const photoUrls = await UploadMultiplePhotos(filesToUpload);
        const idToUrlMap = await UploadMultiplePhotos(photos);

        photos.forEach((photo) => {
            const photoUrl = idToUrlMap[photo.id];
            // console.log("photoUrl: ", photoUrl)
            formData.append(`photos[${photo.id}][id]`, photo.id);
            formData.append(`photos[${photo.id}][url]`, photoUrl);
            formData.append(`photos[${photo.id}][title]`, photo.title);
            formData.append(`photos[${photo.id}][description]`, photo.description);

            if (geoTaggedPoints[photo.id]) {
                formData.append(`photos[${photo.id}][longitude]`, geoTaggedPoints[photo.id].longitude);
                formData.append(`photos[${photo.id}][latitude]`, geoTaggedPoints[photo.id].latitude);
            }   

        });

        Object.entries(features).forEach(([featureId, feature], index) => {
            const geometry = feature.geometry;
            const fillColor = feature.properties.color;
            const opacity = feature.properties.opacity;
            const svgIcon = feature.properties.svgIcon;
            const featureShape = feature.properties.featureShape;
            const mode = feature.properties.mode;
            const borderOption = feature.properties.borderOption;
            const shapeSize = feature.properties.shapeSize;
            const name = feature.properties.name;
            const id = feature.properties.id;
            //const name = featureNames[featureId] || ''; // Default to empty string if no name
            formData.append(`geometries[${index}][geometry]`, JSON.stringify(geometry));
            formData.append(`geometries[${index}][fillColor]`, fillColor ? fillColor : '');
            formData.append(`geometries[${index}][featureShape]`, featureShape ? featureShape : '');
            formData.append(`geometries[${index}][opacity]`, opacity ? opacity : 1);
            formData.append(`geometries[${index}][svgIcon]`, svgIcon ? svgIcon : '');
            formData.append(`geometries[${index}][borderOption]`, borderOption ? borderOption : '');
            formData.append(`geometries[${index}][shapeSize]`, shapeSize ? shapeSize : 1);
            formData.append(`geometries[${index}][routeMode]`, mode ? mode : '');
            formData.append(`geometries[${index}][name]`, name);
            formData.append(`geometries[${index}][feature_id]`, id);
        });

        try {

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/${backendUrl}`, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.data.status === 'success') {
                navigate(`/site/${sitename}/journals/${slugifiedTitle}/manage-posts`);
            }
        } catch (error) {
            setIsProcessing(false);
            if (error.response) {
                const message = error.response.data.error || 'An unexpected error occurred.';
                setErrorMessage(message);
                setShowErrorModal(true);
            } else {
                console.error('Error creating post', error);
                setErrorMessage('Network error or server is down.');
                setShowErrorModal(true);
            }
        } finally {
            setIsProcessing(false);
        }
    }

    // save as draft
    const saveAsDraft = async (e) => {
        e.preventDefault();
        submitPost('DRAFT', e);
    }

    // publish
    const handleSubmit = async (e) => {
        e.preventDefault();
        submitPost('LIVE', e);
    };

    return (
        <div className="responsive-container" style={{ overflow: 'hidden' }}>
            {!isMobile && (<JournalHeader />)}
            <div className="content">
                <div className="form-wrapper">
                    {isFormVisible && (
                        <div className="form-container">
                            <form onSubmit={handleSubmit}>
                                <TitleInput title={title} setTitle={setTitle} titleError={titleError} />
                                <DateInput date={date} setDate={setDate} dateError={dateError} />
                                <PhotosInput 
                                    photos={photos}
                                    setPhotos={setPhotos}
                                    openModal={openModal}
                                    initiateGeotagging={initiateGeotagging}
                                    deletePhoto={deletePhoto}
                                    handlePhotoChange={handlePhotoChange} 
                                />
                                <div className="form-group">
                                    <label htmlFor="body">Journal Entry Body:</label>
                                    <textarea
                                        ref={bodyRef}
                                        id="body"
                                        className="post-body-textarea"
                                        rows={10}
                                        spellCheck="true"
                                    />
                                </div>
                                <GeometryInput 
                                    geometryError={geometryError}
                                    generateUniqueId={generateUniqueId}
                                    setFeatureNames={setFeatureNames}
                                    setFeatures={setFeatures}
                                    mapRef={mapRef}
                                />
                                <div className="form-button-container">
                                    <button type="button" className="save-entry-btn" onClick={saveAsDraft} >Save as Draft</button>
                                    <button type="submit" className="publish-entry-btn">Publish</button>
                                </div>
                            </form>
                        </div>
                    )}
                    <div className={`toggle-form-arrow ${!isFormVisible ? 'minimized-form' : ''}`} onClick={toggleFormVisibility}>
                        {isFormVisible ? (
                            <i className="fas fa-window-minimize min"></i>
                        ) : (
                            <div className="min-form-create">
                                <i className="fas fa-expand-arrows-alt max"></i>
                                <p className="vertical-text">Journal Entry</p>
                            </div>
                        )} 
                        <span className="tooltip-text">Click to {isFormVisible ? 'hide' : 'show'} form</span>
                    </div>
                </div>
                <div className="map-container-post">
                    <div className="mapbox-container-box">

                        {mapViewState && (
                        <JournalEntryMap 
                            setCurrentMapStyle={setCurrentMapStyle}
                            mapViewState={mapViewState}
                            mapRef={mapRef}
                            handleMapClick={handleMapClick}
                            handleMapLoad={handleMapLoad}
                            features={features}
                            renderGeoTaggedPoints={renderGeoTaggedPoints}
                            currentMapStyle={currentMapStyle}
                            selectedMarker={selectedMarker}
                            setSelectedMarker={setSelectedMarker}
                            routeGeoJSON={routeGeoJSON}
                            pointFeatures={pointFeatures}
                            polygonFeatures={polygonFeatures}
                            onMapReady={handleMapRef}
                            configure3DTerrain={configure3DTerrain}
                            tempWaypoint={tempWaypoint}
                            />
                        )}
                    </div>
                    {/* <MapBasemapSelect setCurrentMapStyle={setCurrentMapStyle}/> */}
                   
                    <MapGeometryInfo 
                        saveFeatureName={saveFeatureName}
                        cancelEdit={cancelEdit}
                        features={features}
                        openIconModal={openIconModal}
                        selectedIcon={selectedIcon}
                        setFeatures={setFeatures}
                        editFeature={editFeature}
                        featureNames={featureNames}
                        selectedFeature={selectedFeature}
                        uiMode={uiMode}
                        resetModes={resetModes}
                        handleEditFeatureClick={handleEditFeatureClick}
                        newFeatureName={newFeatureName}
                        setNewFeatureName={setNewFeatureName}
                        finishRouteCreation={finishRouteCreation}
                        cancelRouteCreation={cancelRouteCreation}
                        undoLastWaypoint={undoLastWaypoint}
                        waypoints={waypoints}
                        isGeoInfoVisible={isGeoInfoVisible}
                        toggleGeoInfoVisibility={toggleGeoInfoVisibility}
                        routeMode={routeMode}
                    />
                </div>
            </div>
            <PhotoInfoPopup 
                showModal={showModal} 
                closeModal={closeModal} 
                currentPhoto={currentPhoto} 
                handleTitleChange={handleTitleChange} 
                savePhotoInfo={savePhotoInfo} 
                handleDescriptionChange={handleDescriptionChange} 
            />
            {
                showSessionTimeoutPopup && (
                    <Modal show={true} onHide={() => setShowSessionTimeoutPopup(false)} centered>
                        <Modal.Header closeButton>
                        <Modal.Title>
                            <h3>Session Timeout</h3>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Session timed out! You will be redirected to the home page.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={handleSessionTimeout}>OK</button>
                        </Modal.Footer>
                    </Modal>
                )
            }
            {showErrorModal && (
                <ErrorModal
                    show={showErrorModal}
                    errorMessage={errorMessage}
                    onClose={() => navigate('/home')}
                    onRetry={() => setShowErrorModal(false)}
                />
            )}
            <SearchModal
                isOpen={isSearchModalOpen}
                onClose={() => setSearchModalOpen(false)}
                mapboxAccessToken={TOKEN}
                map={mapRef}
            />
            <PointIconModal
                isOpen={isModalOpen}
                onSelect={handleIconSelect}
                onClose={() => setIsModalOpen(false)}
            />
            {isProcessing && (
                <div className="spinner-overlay" style={{display: "flex", flexDirection: "column"}}>
                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`} alt="Loading..." className="spinner-logo" />
                    {!isMobile && <HelpfulHint />}
                </div>
            )}
            {isUploadingPhotos && (
                <div className="spinner-overlay" style={{display: "flex", flexDirection: "column"}}>
                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`} alt="Loading..." className="spinner-logo" />
                    {!isMobile && <HelpfulHint />}
                </div>
            )}
            <WalkthroughCreate WalkthroughPopup={WalkthroughPopup} />
        </div>
    );
};

export default CreateEditJournalEntry;
