import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ViewJournalPopup.css'

const ViewJournalPopup = ({ blog_post, display_name, onClose }) => {

    const [journalsData, setjournalsData] = useState([]);
    const [expandedJournals, setExpandedJournals] = useState({});

    useEffect(() => {
        const fetchJournals = async() => {

            const token = localStorage.getItem('token');

            const headers = {
                ...(token && { Authorization: `Bearer ${token}` }),
            };

            try {
                const blogPostId = blog_post.id;
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/map-view/get-user-journals/${blogPostId}`, {
                    headers: headers
                })
                setjournalsData(response.data)
            } catch (error) {
                //console.log(error.response)
            }
        }

        fetchJournals();
    }, [blog_post])

    const handleClose = () => {
        onClose();
    }

    const toggleExpand = (journalId) => {
        setExpandedJournals(prevState => ({
          ...prevState,
          [journalId]: !prevState[journalId]
        }));
      };
    
    const handleTitleClick = (url) => {
        // Construct the full URL by appending the given path to the base URL
        const fullUrl = `${window.location.origin}/${url}`;

        // Open the new URL in a new tab
        window.open(fullUrl, '_blank');
    }
 
    return (
        <div className="view-journals-popup-container" >
            <div className="custom-card-header">
                <h3>{display_name}</h3>
                <button className="btn-close-popup-header" onClick={() => {
                    handleClose(); 
                }}>X</button>
            </div>
            <div/>
            <div className="viewjournal-popup-body-container">
                <h4 className="card-title custom-title">{journalsData ? 'My Journals' : 'Loading Journals...'}</h4>
                {journalsData.map((journal, index) => (
                <div key={index} className="row g-3">
                    <h5 className="card-title custom-title">
                        <button className="journal-expand-btn" onClick={() => toggleExpand(index)}>+</button>
                        <span onClick={() => handleTitleClick(journal.journal_url)} style={{cursor: 'pointer'}}>
                            {journal.journal_title}
                        </span>
                    </h5>
                    <p className="card-text custom-text">{journal.journal_description}</p>
                    {expandedJournals[index] && (
                    <ul>
                        {journal.posts.map((post, postIndex) => (
                        <li key={postIndex}>{post.post_date}: {post.post_title}</li>
                        ))}
                    </ul>
                    )}
                </div>
                ))}
            </div>
        </div>
        
    );
};

export default ViewJournalPopup;
