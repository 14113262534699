import React, { createContext, useContext, useState } from 'react';

const WalkthroughContext = createContext();

export const useWalkthrough = () => useContext(WalkthroughContext);

export const WalkthroughProvider = ({ children }) => {
  const [walkthroughStatus, setWalkthroughStatus] = useState(() => {
    // Retrieve the walkthrough status from local storage
    const saved = localStorage.getItem('walkthroughStatus');
    return saved ? JSON.parse(saved) : {};
  });

  // Function to update the walkthrough status and save it to local storage
  const updateWalkthroughStatus = (componentName, status) => {
    setWalkthroughStatus(prev => {
      const updated = { ...prev, [componentName]: status };
      localStorage.setItem('walkthroughStatus', JSON.stringify(updated));
      return updated;
    });
  };

  return (
    <WalkthroughContext.Provider value={{ walkthroughStatus, setWalkthroughStatus, updateWalkthroughStatus }}>
      {children}
    </WalkthroughContext.Provider>
  );
};
