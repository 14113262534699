import React from 'react';
import HeaderHome from '../../HomePage/components/HeaderHome';
import './About.css'
import { useNavigate } from 'react-router-dom';

const About = () => {
    const navigate = useNavigate();
    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon_sheep.jpg`;
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover', 
    };

    const navigateSignUp = (e) => {
        navigate('/signup')
    };

    const navigateHome = (e) => {
        navigate('/home')
    };

    return (
        <div className="d-flex flex-column vh-100" style={backgroundStyle}>
            <HeaderHome />
            <div class="home-1-container">
            <div className="vh-100 d-flex justify-content-center align-items-center" style={{
                backgroundImage: `url(${backgroundImageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                marginLeft: '25%',
            }}>
                
            </div>
            <div class="color-overlay-about"></div>
            </div>
            <div className="about-text-overlay" style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="header-logo home-logo">
                    <img
                    src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
                    alt="Mapylon"
                    className="small-image home-logo-img about"
                    style={{ marginLeft: '0' }}
                    onClick={navigateHome}
                    />
                </div>
                <div className="text-left text-white" style={{ position: 'absolute', top: '28vh', left: '8vw', width: '40vw', lineHeight: '1', height: '65vh' }}>
                    <h2 className="home-title about"><b>About Mapylon</b></h2>
                    <p className="home-subheading about">There are a lot of great trip planning tools out there, but Mapylon is designed to be an archive and record of your adventures.</p>
                    <p className="home-subheading about">Mapylon lets you organize your travels and adventures into Journals. These Journals are easy to fill with photos and adventures,
                    and even easier to share with friends and family.</p>
                    <p className="home-subheading about">With the click of a button Mapylon creates a beautiful and easy to share map that lets you relive your adventures, and 
                    show people what you've been up to.</p>
                    <p className="home-subheading about"><b>Ready to Write Your Adventure?</b></p>
                    <button className="home-signup-button about-main" style={{ marginLeft: '0' }} onClick={navigateSignUp}>Get Started...</button>
                </div>

            </div>
        </div>
    );
};

export default About;


