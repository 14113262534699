import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import debounce from 'lodash/debounce'; 

const JournalSettingsModal = ({ show, onHide, journal, onSave }) => {
    const [journalData, setJournalData] = useState(journal || {});
    const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isValidLocation, setIsValidLocation] = useState(true);
    const [locationChanged, setLocationChanged] = useState(false);


    useEffect(() => {
        setJournalData(journal || {});
        if (journal && journal.placeName) {
            setSearchTerm(journal.placeName); // Set searchTerm to the placeName if it exists
        } else {
            setSearchTerm(''); // Reset searchTerm if there is no placeName
        }
    }, [journal]);

    const handleInputChange = (e) => {
        setJournalData({ ...journalData, [e.target.name]: e.target.value });
    };

    const handleSave = () => {
        if ((!locationChanged || (journalData.latitude && journalData.longitude)) || searchTerm === '') {
            onSave(journal.id, journalData);
            onHide();
        } else {
            setIsValidLocation(false);
        }
    };

    useEffect(() => {
        debouncedSearch(searchTerm);
    }, [searchTerm]);

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        setIsValidLocation(true); // Reset validation status when user starts typing
        setLocationChanged(true);
        // If the user alters the search term, reset the journalData's location properties
        // unless the new search term exactly matches the previously selected placeName
        if (newSearchTerm !== journalData.placeName) {
            setJournalData(prevState => ({
                ...prevState,
                latitude: null,
                longitude: null,
                placeName: newSearchTerm // Update placeName to reflect the new search term
            }));
        }
    
        if (newSearchTerm.trim().length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const selectLocation = (suggestion) => {
        setJournalData({
            ...journalData,
            latitude: suggestion.center[1], 
            longitude: suggestion.center[0],
            placeName: suggestion.place_name,
        });
        setSearchTerm(suggestion.place_name);
        setShowSuggestions(false);
        setIsValidLocation(true); 
        setLocationChanged(false);
    };

    const handleBlur = () => {
        // Use a timeout to delay the hiding of suggestions so that the click event on suggestions can be registered
        setTimeout(() => {
            setShowSuggestions(false);
        }, 100);
    };

    const debouncedSearch = debounce((searchTerm) => {
        if (!searchTerm) return;

        axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchTerm)}.json`, {
            params: {
                access_token: TOKEN,
                // Add other parameters as needed, like 'limit' to control the number of suggestions
            },
        })
        .then(response => {
            setSuggestions(response.data.features);
        })
        .catch(error => {
            console.error('Error fetching location data:', error);
        });
    }, 300); // 300 ms debounce time

      
    

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Journal Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="journalName">Journal Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        id="journalName"
                        name="journalName"
                        value={journalData.journalName || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="journalDescription">Journal Description:</label>
                    <textarea
                        type="text"
                        className="form-control"
                        id="journalDescription"
                        name="journalDescription"
                        value={journalData.journalDescription || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="welcomeMessage">Welcome Message:</label>
                    <textarea
                        type="text"
                        className="form-control"
                        id="welcomeMessage"
                        name="welcomeMessage"
                        value={journalData.welcomeMessage || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="visibility">Visibility:</label>
                    <select 
                        className="form-control custom-select"
                        id="visibility"
                        name="visibility"
                        value={journalData.visibility || ''}
                        onChange={handleInputChange}
                    >
                        <option value="private">Private - Only Me</option>
                        <option value="public-url">Public - URL Only</option>
                        <option value="public">Public</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="locationSearch">Search for a location:</label>
                    {!isValidLocation && (
                        <div className="text-danger-loc">
                            Select a location from the list, or leave this field blank.
                        </div>
                    )}
                    <input
                        type="text"
                        className="form-control"
                        id="locationSearch"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onFocus={() => setShowSuggestions(true)}
                        onBlur={handleBlur}
                        placeholder="Start typing a location"
                    />
                    {showSuggestions && searchTerm && (
                        <ul className="list-group">
                            {suggestions.map((suggestion) => (
                                <li
                                    key={suggestion.id}
                                    className="list-group-item list-group-item-action"
                                    onClick={() => selectLocation(suggestion)}
                                >
                                    {suggestion.place_name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Discard Changes</Button>
                <Button variant="primary" onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default JournalSettingsModal;
