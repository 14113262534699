import React, { useState, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash/debounce';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import './CreateJournal.css'; 
import '../../_Shared_ProjectWide/Styles/ProjectStyles.css';
import HelpfulHint from '../../_Shared_ProjectWide/Popups/HelpfulHint';
import HeaderManage from '../../_Shared_ProjectWide/Headers/HeaderManage';
import WalkthroughPopup from '../../_Shared_ProjectWide/Popups/WalkthroughPopup';
import { useMediaQuery } from 'react-responsive';

const CreateJournal = () => {
    const [journalData, setJournalData] = useState({
        title: '',
        body: '',
        visibility: 'private',
        welcomeMessage: '',
        location: { latitude: null, longitude: null, placeName: '' },
    });
    const { sitename } = useParams();
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isTitleValid, setIsTitleValid] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isValidLocation, setIsValidLocation] = useState(true);
    const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const [showSessionTimeoutPopup, setShowSessionTimeoutPopup] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    
    const slugifyTitle = (title) => {
        return title
            .trim() // Remove leading and trailing spaces
            .toLowerCase()
            .replace(/ /g, '-') // Replace spaces with hyphens
            .replace(/[^\w-]+/g, '') // Remove all non-word chars except hyphens
            .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
    };


    const debouncedSearch = debounce((searchTerm) => {
        if (!searchTerm) return;
        fetchLocationSuggestions(searchTerm);
    }, 300); 

    useEffect(() => {
        if (searchTerm) {
            debouncedSearch(searchTerm);
        }
    }, [searchTerm, debouncedSearch]);

    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        setIsValidLocation(true); // Reset validation status when user starts typing
    
        // If the user alters the search term, reset the location's coordinates
        // unless the new search term exactly matches the previously selected placeName
        if (newSearchTerm !== journalData.location.placeName) {
            setJournalData(prevState => ({
                ...prevState,
                location: { 
                    ...prevState.location, 
                    latitude: null, 
                    longitude: null,
                    placeName: newSearchTerm // Update placeName to reflect the new search term
                }
            }));
        }
    
        if (newSearchTerm.trim().length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };
    
    
    const selectLocation = (suggestion) => {
        // Update the journalData with the selected location
        setJournalData(prevState => ({
            ...prevState,
            location: {
                latitude: suggestion.center[1],
                longitude: suggestion.center[0],
                placeName: suggestion.place_name,
            }
        }));
    
        // Update the searchTerm and hide the suggestions
        setSearchTerm(suggestion.place_name);
        setShowSuggestions(false);
    };
    


    const handleBlur = () => {
        // Use a timeout to delay the hiding of suggestions so that the click event on suggestions can be registered
        setTimeout(() => {
            setShowSuggestions(false);
        }, 100);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setJournalData(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    const fetchLocationSuggestions = async (searchTerm) => {
        try {
            const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(searchTerm)}.json`, {
                params: {
                    access_token: TOKEN,
                },
            });
            setSuggestions(response.data.features);
        } catch (error) {
            console.error('Error fetching location data:', error);
        }
    };

    const handleSessionTimeout = () => {
        // Close the popup
        setShowSessionTimeoutPopup(false);
    
        // Redirect to the home page
        localStorage.removeItem('token');
        navigate('/home/');
    };

    const showProcessingPopup = () => {
        setIsProcessing(true);
      };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let isValidForm = true;

        if (!journalData.title.trim()) {
            setIsTitleValid(false);
            isValidForm = false;
        } else {
            setIsTitleValid(true);
        }

        // Check if location is either blank or has valid coordinates
        const { latitude, longitude } = journalData.location;
        if (searchTerm && (!latitude || !longitude)) {
            setIsValidLocation(false);
            isValidForm = false;
        } else {
            setIsValidLocation(true);
        }

        // Proceed only if the form is valid
        if (!isValidForm) return;


        //const slugifiedTitle = slugifyTitle(journalData.title);
        const postData = {
            ...journalData,
            siteName: sitename,
        };

        try {
            showProcessingPopup();
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/create-journal/`, postData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}` 
                }
            });
            if (response.data.status === 'success') {
                navigate(`/site/${sitename}/journals/${response.data.slug}/manage-posts`);
            }
        } catch (error) {
            setIsProcessing(false);
            if (error.response && error.response.status === 401) {
                // Set state to true to show the session timeout popup
                setShowSessionTimeoutPopup(true);
            } else {
                const message = error.response.data.error || 'An unexpected error occurred.';
                setErrorMessage(message);
                setShowErrorModal(true);
            }
        } finally {
            setIsProcessing(false);
        }
    };
    
    // show the error modal when publishing/saving post fails
    const ErrorModal = ({ show, errorMessage, onClose, onRetry }) => {
        return (
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <button onClick={onClose}>Home</button>
                    <button onClick={onRetry}>Return to Post</button>
                </Modal.Footer>
            </Modal>
        );
    };

        
    
    return (
        <div className="page-container" style={{ backgroundColor: 'F8F9FA' }}>
            <HeaderManage />
            <div className="container-fluid">
                {!isMobile ? (
                    <div className="create-journal-header">
                        <h2><b>Create a New Journal</b></h2>
                        <p>Give your new journal a name, and if you want, you can add a description too! Don't worry about getting it perfect, you can come back and edit this at any time.</p>
                    </div>
                ) : ( 
                    <div className="create-journal-header">
                        <h2><b>Create Journal</b></h2>
                        <p>Give your new journal a name, and if you want, you can add a description too! Don't worry about getting it perfect, you can come back and edit this at any time.</p>
                    </div>
                )}
                <div className="row">
                    {/* Left Column for Journal Details */}
                    <div className="col-md-6">
                        <div className="create-journal-form">
                            {/* Journal Title Input */}
                            <div className="form-group">
                                <label htmlFor="title">Journal Title:</label>
                                {!isTitleValid && (
                                    <div className="text-danger-loc">
                                        Title cannot be blank.
                                    </div>
                                )}
                                <input
                                    type="text"
                                    id="title"
                                    value={journalData.title}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    spellCheck="true"
                                />
                            </div>

                            {/* Journal Description Input */}
                            <div className="form-group">
                                <label htmlFor="body">Journal Description (optional):</label>
                                <textarea
                                    id="body"
                                    value={journalData.body}
                                    onChange={handleInputChange}
                                    rows={4}
                                    className="form-control"
                                    spellCheck="true"
                                />
                            </div>

                            {/* Visibility Selector */}
                            <div className="form-group">
                                <label htmlFor="visibility">
                                    Visibility:
                                    <span className="info-icon" data-tooltip="This sets who can view your journal."> &#x3F;</span>
                                </label>
                                <select 
                                    id="visibility" 
                                    value={journalData.visibility} 
                                    onChange={handleInputChange} 
                                    className="form-control custom-select"
                                >
                                    <option value="private">Private - Only Me</option>
                                    <option value="public-url">Public - URL Only</option>
                                    <option value="public">Public</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {/* Right Column for Welcome Message and Location Search */}
                    <div className="col-md-6">
                        <div className="form-group location-search-container">
                            <label htmlFor="locationSearch">
                                Set Starting Location:
                                <span className="info-icon" data-tooltip="This sets where the map will open to when you create a new post."> &#x3F;</span>
                            </label>
                            {!isValidLocation && (
                                <div className="text-danger-loc">
                                    Select a location from the list, or leave this field blank.
                                </div>
                            )}
                            <input
                                type="text"
                                id="locationSearch"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onFocus={() => setShowSuggestions(true)}
                                onBlur={handleBlur}
                                placeholder="Start typing a location"
                                className="form-control"
                            
                            />
                            {showSuggestions && searchTerm && (
                                <ul className="list-group search-dropdown">
                                    {suggestions.map((suggestion) => (
                                        <li
                                            key={suggestion.id}
                                            className="list-group-item list-group-item-action"
                                            onClick={() => selectLocation(suggestion)}
                                        >
                                            {suggestion.place_name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="form-group welcome-msg">
                            <label htmlFor="welcome-message">
                                Welcome Message (not functional yet):
                                <span className="info-icon" data-tooltip="Set a message that will display when someone opens your journal."> &#x3F;</span>
                            </label>
                            <textarea
                                id="welcomeMessage"
                                value={journalData.welcomeMessage}
                                onChange={handleInputChange} 
                                className="form-control"
                                spellCheck="true"
                            />
                        </div>
                        {!isMobile &&
                            <button type="submit" className="journal-submit" onClick={handleSubmit}>Submit</button>
                        }       
                        </div>
                        {isMobile &&
                            <div className="mobile-button-container">
                                <button type="submit" className="journal-submit" onClick={handleSubmit}>Submit</button>
                            </div>
                        } 
                    {isProcessing && (
                        <div className="spinner-overlay" style={{ display: 'flex', flexDirection: 'column' }}>
                            <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`} alt="Loading..." className="spinner-logo" />
                            {!isMobile && <HelpfulHint />}
                        </div>
                    )}
                        {showErrorModal && (
                        <ErrorModal
                            show={showErrorModal}
                            errorMessage={errorMessage}
                            onClose={() => navigate('/home')}
                            onRetry={() => setShowErrorModal(false)}
                        />
                    )}
                    {
                        showSessionTimeoutPopup && (
                            <Modal show={true} onHide={() => setShowSessionTimeoutPopup(false)} centered>
                                <Modal.Header closeButton>
                                <Modal.Title>
                                    <h3>Session Timeout</h3>
                                </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Session timed out! You will be redirected to the home page.</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button className="btn btn-primary" onClick={handleSessionTimeout}>OK</button>
                                </Modal.Footer>
                            </Modal>
                        )
                    }
                    <WalkthroughPopup componentName="createJournal">
                        <p><b>Creating a Journal</b></p>
                        <p>A journal is what holds your Journal Entries. When you send someone a link, it will be to your journal. You can come back and change these settings at any time.</p>
                        <br></br>
                        <p>The journal description is visible to you and anyone who opens your journal. You can control who can open your journal with the visibility settings.</p>
                        <br></br>
                        <p>Choosing a starting location is just for you. When you go to create a Journal Entry later, the starting location is where the map will open to.</p>
                        <br></br>
                        <p>Lastly, you can enter a welcome message if you'd like. This message will pop up when anyone opens the link to your map.</p>
                    </WalkthroughPopup>
                </div>
            </div>
        </div>
    );

    
};

export default CreateJournal;
