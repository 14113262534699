// FormTitle.js
const TitleInput = ({ title, setTitle, titleError }) => {
    return (
      <div className="form-group">
        <input
          type="text"
          id="title-input"
          className="title-input"
          value={title}
          placeholder="My Journal Entry Title"
          onChange={(e) => setTitle(e.target.value)}
          spellCheck="true"
        />
        {titleError && <div className="error-message" style={{ color: 'red' }}>{titleError}</div>}
      </div>
    );
  };
  
  export default TitleInput;
  