import React from 'react';
import '../_Shared_ProjectWide/Styles/ProjectStyles.css';
import './HomeSplashPage.css'; 
import Header from './components/HeaderHome';
import HomeFooter from './components/FooterHome';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const HomePage = () => {

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  
  const backgroundImageUrls = [
      `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background20.jpg`,
      `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/home-3c.png`,
      `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/home-2c.png`,
      `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/home-4c.png`,
  ];

  const backgroundImageUrlsMobile = [
    `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background_mobile_9.jpg`,
    `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background_mobile_1a.jpg`,
    `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background_mobile_10.jpg`,
    `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background_mobile_5.jpg`,
  ];

  const currentBackgroundImageUrls = isMobile ? backgroundImageUrlsMobile : backgroundImageUrls;


  const navigateSignUp = (e) => {
    navigate('/signup')
  };

  return (
      <div className="d-flex flex-column">
          <Header />
          {!isMobile ? (
            <>
              {currentBackgroundImageUrls.map((url, index) => (
                index === 0 ? (
                  <>
                  <div class="home-1-container">
                    <div key={index} className="vh-100 d-flex justify-content-center align-items-center" style={{
                      backgroundImage: `url(${url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                      marginLeft: '15%',
                    }}>
                      
                    </div>
                    <div class="color-overlay"></div>
                  </div>
                    <div className="home-text-overlay" style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="header-logo home-logo">
                        <img
                          src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
                          alt="Mapylon"
                          className="small-image home-logo-img"
                          style={{ marginLeft: '0' }}
                        />
                        <p style={{ color: 'white', marginLeft: '0' }}><b>beta v0.4.0</b></p>
                      </div>
                      <div className="text-left text-white" style={{ position: 'absolute', top: '35vh', left: '8vw', width: '30vw', lineHeight: '1', height: '65vh' }}>
                          <h2 className="home-title"><b>Write Your Adventure</b></h2>
                          <p className="home-subheading"><b>Welcome to Mapylon, a map-based journal built to inspire and record your adventures and travels.</b></p>
                          <button className="home-signup-button home-main" style={{ marginLeft: '0', fontSize: '1.5rem' }} onClick={navigateSignUp}>Get Started...</button>
                          <div className="scroll-down-arrow" style={{ left: '5%' }}></div>
                      </div>

                    </div>
                  </>
                ) : (
                  <div key={index} className="vh-100 d-flex" style={{
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover'
                  }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0 20px' }}>
                      {index === 1 && (
                        <div className="text-center">
                          <h6 className="home-sub">mapylon</h6>
                          <h2 className="home-title-alt"><b>Record Your Adventure</b></h2>
                          <p className="home-subheading-alt">Mapylon makes it easy to store geo-tagged photos, reports and routes.</p>
                          <div className="home-btn-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <button className="home-signup-button alt-btn" onClick={navigateSignUp}>Get Started</button>
                            <button className="home-signup-button alt-btn-learn" onClick={navigateSignUp}>Learn More</button>
                          </div>
                        </div>
                      )}
                      {index === 2 && (
                        <div className="text-center">
                          <h6 className="home-sub">mapylon</h6>
                          <h2 className="home-title-alt"><b>Share Your Adventure</b></h2>
                          <p className="home-subheading-alt">With just one click, Mapylon turns your Journal Entries into a beautiful and easy to share map.</p>
                          <div className="home-btn-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <button className="home-signup-button alt-btn" onClick={navigateSignUp}>Get Started</button>
                            <button className="home-signup-button alt-btn-learn" onClick={navigateSignUp}>Learn More</button>
                          </div>
                        </div>
                      )}
                      {index === 3 && (
                        <div className="text-center">
                          <h6 className="home-sub">mapylon</h6>
                          <h2 className="home-title-alt"><b>Discover Your Next Adventure</b></h2>
                          <p className="home-subheading-alt">With Mapylon Explore's high quality reports you're sure to find the inspiration for your next adventure.</p>
                          <div className="home-btn-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <button className="home-signup-button alt-btn" onClick={navigateSignUp}>Get Started</button>
                            <button className="home-signup-button alt-btn-learn" onClick={navigateSignUp}>Learn More</button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div style={{ flex: 3, backgroundImage: `url(${url})`, backgroundSize: 'auto 100%', backgroundPosition: 'left center', backgroundRepeat: 'no-repeat' }}></div>
                  </div>
                )
              ))}
            </>

          ) : ( 
            <>
              {currentBackgroundImageUrls.map((url, index) => (
                <div key={index} className="vh-100 d-flex justify-content-center align-items-center" style={{
                  backgroundImage: `url(${url})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover'
                }}>
                  {index === 0 && (
                    <>
                      <div className="text-center text-white">
                          <h2 className="home-title"><b>Write Your Adventure</b></h2>
                          <p className="home-subheading">Welcome to Mapylon, a map-based journal built to inspire and record your adventures and travels.</p>
                          <button className="home-signup-button" onClick={navigateSignUp}>Get Started...</button>
                      </div>
                      <div className="scroll-down-arrow"></div>
                    </>
                  )}
                  {index === 1 && (
                    <div className="text-center">
                        <h2 className="home-title-alt"><b>Share Your Adventure</b></h2>
                        <p className="home-subheading-alt">Mapylon allows you to seamlessly create an easy to share map of all your travels and adventures.</p>
                        <button className="home-signup-button" onClick={navigateSignUp}>Get Started...</button>
                    </div>
                  )}
                  {index === 2 && (
                    <div className="text-center">
                        <h2 className="home-title-alt"><b>Record Your Adventure</b></h2>
                        <p className="home-subheading-alt" style={{ width: '100%' }}>Create journals with geotagged photos and routes.</p>
                        <button className="home-signup-button" onClick={navigateSignUp}>Get Started...</button>
                    </div>
                  )}
                  {index === 3 && (
                    <div className="text-center">
                        <h2 className="home-title-alt"><b>Discover Your Next Adventure</b></h2>
                        <p className="home-subheading-alt" style={{ width: '100%' }}>With Mapylon Explore you can find high quality trail reports and photos.</p>
                        <button className="home-signup-button" onClick={navigateSignUp}>Get Started...</button>
                    </div>
                  )}
                </div> 
              ))}
            </>
          )}
          <HomeFooter />
      </div>
    );
};


export default HomePage;
