// src/components/ExtendedInfoForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdditionalSignup.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation } from '../../../context/Context';

const AdditionalRegistration = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthorized, setIsAuthorized } = useNavigation();
    
    const { username, password } = location.state || {};

    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background32.jpg`;
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`
    };

    // State for each input field
    // const [siteName, setSiteName] = useState('');
    // const [siteDesc, setSiteDesc] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isAuthorized) {
            navigate('/home');
        }
    }, [isAuthorized, navigate]);

    useEffect(() => {
        const checkLoginStatus = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-user-site/`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    const sitename = response.data.sitename;
                    if (sitename) {
                        navigate(`/site/${sitename}/manage-journals/`, { replace: true });
                    } else {
                        console.error('Sitename not found');
                        // Handle case where sitename is not found
                    }
                } catch (error) {
                    console.error('Error fetching sitename', error);
                    // Handle error (e.g., show a message to the user)
                }
            }
        };
        
        checkLoginStatus();
    }, [navigate]);

    const autoLogin = async () => {
        const username_or_email = username
        try {
            const loginResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/login/`, { username_or_email, password });
            localStorage.setItem('token', loginResponse.data.access);
            navigate(`/site/${username}/manage-journals`); // Redirect after successful login
        } catch (error) {
            console.error('Auto login error', error);
        }
    };

    // const validateSiteName = () => {
    //     const regex = /^[a-zA-Z0-9]+$/; // Regular expression for alphanumeric characters
    //     if (!siteName.match(regex)) {
    //         setError('No spaces or special characters.');
    //         return false;
    //     }
    //     setError(''); // Clear any previous error
    //     return true;
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // if (!validateSiteName()) {
        //     return; 
        // }
        setIsLoading(true); 

        try {
            const token = localStorage.getItem('token');
            //console.log("Token:", token); // Debugging log
    
            if (token) {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/extended-user-info/`, {
                    username,  // Passed from previous component
                    firstName,
                    lastName,
                    // siteName,
                    // siteDesc
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
    
                //console.log("Response:", response); // Debugging log
    
                if (response.status === 201) {
                    autoLogin();
                    //navigate('/manage-books');
                }
            } else {
                //console.log("Token not found"); // Debugging log
            }
        } catch (error) {
            console.error("Error in form submission:", error);
            if (error.response && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('An error occurred');
            }
        } finally {
            setIsLoading(false); 
        }
    };
    
    return (
        <div className="extended-info-container" style={backgroundStyle}>
            <form onSubmit={handleSubmit} className="extended-info-form">
                <h4>
                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon4.png`}alt="Login" style={{ width: '50px', height: '60px' }} />
                    Welcome, {username}!
                </h4>
                <p>Thank you for joining Mapylon! Your adventure starts here.</p>
                {/* <label>Site Name</label>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <input type="text" value={siteName} onChange={(e) => setSiteName(e.target.value)} placeholder="ex. myadventurejournal" />
                <label>Site Description (optional)</label>
                <input type="text" value={siteDesc} onChange={(e) => setSiteDesc(e.target.value)} placeholder="ex. My site is a collection of journals describing my travels around North America." /> */}
                <label>First Name (optional)</label>
                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="ex. John" />
                <label>Last Name (optional)</label>
                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="ex. Journaler" />
                <p></p>
                {isLoading && (
                    <div className="spinner-overlay">
                        <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`} alt="Loading..." className="spinner-logo" />
                    </div>
                )}
                <button type="submit" class="auth-button">Submit</button>
            </form>
        </div>
    );
};

export default AdditionalRegistration;
