//ImageGalleryMobile.js

import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css'; 
import 'swiper/css/navigation'; 
import 'swiper/css/pagination'; 
import './ImageGalleryMobile.css'

const ImageGalleryMobile = ({images, blogPost, currentImageIndex, isImageLoaded, handleImageLoaded, handleImageChange}) => {

    const imagesRef = useRef([]);
    const swiperRef = useRef(null);


    useEffect(() => {
        // console.log(currentImageIndex)
        // Check if the swiper instance is available and if currentImageIndex is within bounds
        if (swiperRef.current && swiperRef.current.swiper && images.length > 0 && currentImageIndex < images.length) {
            swiperRef.current.swiper.slideTo(currentImageIndex);
        }
    }, [currentImageIndex, images.length]);

    useEffect(() => {
        // Apply styles to each image after it has loaded
        imagesRef.current.forEach(img => {
            img.onload = () => {
                // Here you can adjust the styles as needed
                img.style.width = 'auto'; // Example adjustment
            };
        });
    }, [images]); // Depend on images to re-apply this effect when images change

    useEffect(() => {
        const swiperInstance = swiperRef.current?.swiper;
        const onSlideChange = () => {
            const newIndex = swiperInstance.activeIndex;
            handleImageChange(newIndex); // Call the passed function with the new index
        };

        if (swiperInstance) {
            swiperInstance.on('slideChange', onSlideChange);
        }

        // Cleanup
        return () => {
            if (swiperInstance) {
                swiperInstance.off('slideChange', onSlideChange);
            }
        };
    }, [handleImageChange]);

    return (
        <div className="image-container-mobile">
            {images && images.length > 0 ? (
                <Swiper
                    // modules={[Pagination]}
                    ref={swiperRef}
                    spaceBetween={5} 
                    slidesPerView={'auto'} 
                    centeredSlides={true} 
                    pagination={{ clickable: true }}
                    freeMode={true} 
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index} style={{ maxWidth: '70%', height: '100%', objectFit: 'contain', flexShrink: '0' }}>
                            <div className="image-and-title-container" style={{ flexDirection: 'column', width: 'auto' }}>
                                <img
                                    src={image.imgurl}
                                    alt={image.imgtitle || `thumbnail-${index}`}
                                    ref={el => imagesRef.current[index] = el} 
                                    onLoad={() => handleImageLoaded()} 
                                    className="img-responsive img-thumbnail img-fluid"
                                    style={{ height: '25vh', objectFit: 'contain', marginTop: '1vh' }}
                                />
                                {image.imgtitle ? (
                                    <p className="photo-title" style={{
                                        color: 'black',
                                        textAlign: 'center',
                                        padding: '0px'
                                    }}>
                                        {image.imgtitle}
                                    </p>
                                ) : (
                                    <p className="photo-title" style={{
                                        color: 'black',
                                        textAlign: 'center',
                                        padding: '0px'
                                    }}>
                                        No Title
                                    </p>
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                    </Swiper>
            ) : (
                <>
                    {!blogPost ? (
                        <div className="loading-indicator">
                            <div className="spinner-img"></div>
                            <p>Loading Image...</p>
                        </div>
                    ) : (
                        <p>No images to display</p>
                    )}
                </>
            )}
        </div>
    );
};

export default ImageGalleryMobile;