
import './HelpfulHint.css'; 

const HelpfulHint = () => {

    const tips = [
        "When creating a Journal Entry, use the globe icon on an image to geo-tag that photo to the map!",
        "Set the 'Starting Location' for a Journal in the settings to have your Journal Entry map open there!",
        "Setting your Journal Visibility to 'Public - URL Only' means the Journal will only be visible to people you send the link to!",
        "Free-Draw (the pencil icon) in a Journal Entry lets you draw a route anywhere you want by clicking.",
        "To draw more accurate routes in a Journal Entry, try zooming in and clicking more often.",

    ];
    

    const randomTip = tips[Math.floor(Math.random() * tips.length)];
  
    return (
        <div className="helpful-hint">
           <p>Tip: {randomTip}</p>
        </div>
    );
  };
  
export default HelpfulHint;


