// App.js
import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavigationProvider } from './context/Context';
import 'bootstrap/dist/css/bootstrap.min.css'
// import { useActivityDetector } from './hooks/ActivityDetector';
// import { checkAndRefreshToken } from './utils/auth'
import CreateBlogPost from './components/SiteManagement/CreatePost/CreateBlogPost';
import HomePage from './components/HomePage/HomeSplashPage';
import ManagePosts from './components/SiteManagement/ManagePosts/ManagePosts';
import Login from './components/UserAuth/Login/Login';
import Signup from './components/UserAuth/SignUp/SignUp';
import Logout from './components/UserAuth/Logout/Logout';
import AdditionalRegistration from './components/UserAuth/AdditionalSignUp/AdditionalSignup';
import ManageJournals from './components/SiteManagement/ManageJournals/ManageJournals';
import CreateJournal from './components/SiteManagement/CreateJournal/CreateJournal';
import ViewMap from './components/FrontEnd/ViewMap/ViewMap';
import About from './components/FrontEnd/About/About';
import PrivacyPolicy from './components/FrontEnd/About/components/PrivacyPolicy';
import TermsOfUse from './components/FrontEnd/About/components/TermsOfUse';
import Contact from './components/FrontEnd/Contact/Contact';
import MyAccount from './components/FrontEnd/MyAccount/MyAccount';
import Pricing from './components/FrontEnd/Pricing/Pricing';
import Help from './components/FrontEnd/Help/Help';
import EditPost from './components/SiteManagement/EditPost/EditPost';
import PageNotFound from './components/FrontEnd/PageNotFound/PageNotFound';
import ErrorPage from './components/FrontEnd/Error/ErrorPage';
import { WalkthroughProvider } from './context/WalkthroughContext';

function App() {
  const [user, setUser] = useState(null);
  // const lastActivity = useActivityDetector(checkAndRefreshToken);

  //   useEffect(() => {
  //       const interval = setInterval(() => {
  //           checkAndRefreshToken();
  //       }, 5 * 60 * 1000); 

  //       return () => clearInterval(interval);
  //   }, []);

  const handleLogin = (userData) => {
      setUser(userData);
      // Save token in local storage or context
  };

  const handleLogout = () => {
      setUser(null);
      // Clear token from local storage or context
  };

  return (
    <WalkthroughProvider>
    <NavigationProvider>
      <Router>
        <Routes>
          <Route path="/create-post" element={<CreateBlogPost />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/help" element={<Help />} />
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/privacy" element={<PrivacyPolicy />} />
          <Route path="/about/terms-of-use" element={<TermsOfUse />} />
          <Route path="/about/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/logout" element={<Logout onLogout={handleLogout} />} />
          <Route path="/additional-info" element={<AdditionalRegistration />} />
          <Route path="/site/:sitename/posts/edit-post/:postid/" element={<EditPost />} />
          <Route path="/site/:sitename/journals/:slugifiedTitle/manage-posts/" element={<ManagePosts />} />
          <Route path="/site/:sitename/manage-journals" element={<ManageJournals />} />
          <Route path="/site/:sitename/create-journal" element={<CreateJournal />} />
          <Route path="/site/:sitename/journals/:slugifiedTitle/view/" element={<ViewMap />} />
          <Route path="/urltoken/site/:sitename/journals/:slugifiedTitle/view/:accessToken/" element={<ViewMap />} />
          <Route path="/error" element={<ErrorPage />} /> 
          <Route path="*" element={<PageNotFound />} /> 
        </Routes>
      </Router>
    </NavigationProvider>
    </WalkthroughProvider>
  );
}

export default App;
