import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useNavigation } from '../../../context/Context';
import axios from 'axios';
import '../../_Shared_ProjectWide/Styles/ProjectStyles.css';
import './ManagePosts.css'; 
import Modal from 'react-bootstrap/Modal';
import WalkthroughPopup from '../../_Shared_ProjectWide/Popups/WalkthroughPopup';
import HeaderManage from '../../_Shared_ProjectWide/Headers/HeaderManage';
import { useMediaQuery } from 'react-responsive';

const ManagePosts = () => {
    const navigate = useNavigate();
    const { setIsAuthorized } = useNavigation();
    const { sitename, slugifiedTitle } = useParams();
    const [showSessionTimeoutPopup, setShowSessionTimeoutPopup] = useState(false);
    const [posts, setPosts] = useState([]);
    const [journalName, setJournalName] = useState([]);
    const [visibility, setVisibility] = useState([]);
    const [accessToken, setAccessToken] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 768 });


    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            showProcessingPopup();
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/${sitename}/journal/${slugifiedTitle}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setPosts(response.data.posts); 
            setJournalName(response.data.journalName);
            setVisibility(response.data.visibility);
            setAccessToken(response.data.access_token);
            
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                if (error.response.status === 401) {
                    // Handle session timeout
                    setShowSessionTimeoutPopup(true);
                } else if (error.response.status === 403) {
                    // Handle forbidden access
                    alert('Forbidden Access!');
                    navigate('/home');
                } else if (error.response.status === 404) {
                    // Handle journal not found
                    alert('Journal not found!');
                    navigate('/home');
                }
            } else {
                console.error('Error fetching posts', error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSessionTimeout = () => {
        // Close the popup
        setShowSessionTimeoutPopup(false);
    
        // Redirect to the home page
        localStorage.removeItem('token');
        navigate('/home');
    };

    const handleCreateNewClick = () => {
        setIsAuthorized(true);
        navigate(`/create-post?site=${sitename}&title=${slugifiedTitle}`); 
    };

    const showProcessingPopup = () => {
        setIsLoading(true);
    };

    const handleViewAsMapClick = () => {

        const baseUrl = window.location.origin; 

        if (visibility === 'public-url') {
            const fullPath = `${baseUrl}/urltoken/site/${sitename}/journals/${slugifiedTitle}/view/${accessToken}`;
            window.open(fullPath, '_blank', 'noopener,noreferrer');

        } else {
            const fullPath = `${baseUrl}/site/${sitename}/journals/${slugifiedTitle}/view`;
            window.open(fullPath, '_blank', 'noopener,noreferrer');
        }

    };

    const handlePublish = async(post) => {
        const postId = post.id
        setIsLoading(true);
        try {
            showProcessingPopup();
            const token = localStorage.getItem('token');
            await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/publish/${postId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                if (error.response.status === 401) {
                    // Handle session timeout
                    setShowSessionTimeoutPopup(true);
                } else if (error.response.status === 403) {
                    // Handle forbidden access
                    alert('Forbidden Access!');
                    navigate('/home');
                } else if (error.response.status === 404) {
                    // Handle journal not found
                    alert('Journal not found!');
                    navigate('/home');
                }
            } else {
                console.error('Error fetching posts', error);
            }
        } finally {
            setIsLoading(false);
            fetchPosts();
        }
    }

    const handleDeleteClick = (post) => {
        setPostToDelete(post);
        setShowDeleteModal(true);
    };

    const handleViewPostClick = (post) => {

        const baseUrl = window.location.origin; 

        if (visibility === 'public-url') {
            const fullPath = `${baseUrl}/urltoken/site/${sitename}/journals/${slugifiedTitle}/view/${accessToken}?post=${post.id}`;
            window.open(fullPath, '_blank', 'noopener,noreferrer');

        } else {
            const fullPath = `${baseUrl}/site/${sitename}/journals/${slugifiedTitle}/view?post=${post.id}`;
            window.open(fullPath, '_blank', 'noopener,noreferrer');
        }
    
    };

    const handleEditPost = (post) => {
        navigate(`/site/${sitename}/posts/edit-post/${post.id}`);
    };

    const handleBackClick = () => {
        navigate(`/site/${sitename}/manage-journals/`);
    };

    const handleDeleteConfirm = async () => {
        if (!postToDelete) return;
    
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/delete-post/${postToDelete.id}/`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            alert("Post deleted successfully"); 
            fetchPosts();
        } catch (error) {
            console.error('Error deleting post:', error);
            alert("Failed to delete post"); 
        }
        setShowDeleteModal(false);
        setPostToDelete(null);
    };

    return (
        <div className="page-container mng-posts">
            <HeaderManage />
            <div className="manage-posts-header">
                {!isMobile ? (
                    <>
                        <div className="manage-posts-title">
                            <h5><b>Manage Journal Entries</b></h5>
                            <h2>{journalName}</h2>
                        </div>
                        <div className="header-buttons">
                            {/* <Link to={`/site/${sitename}/manage-journals/`} className="back-to-journals-link">Back to Journals</Link> */}
                            <button className="mobile-mng-jrnl-btn" onClick={handleBackClick}>
                                <i class="fas fa-arrow-left text-icon"></i>
                                Back
                            </button>
                            <button className="mobile-mng-jrnl-btn" onClick={handleViewAsMapClick}>
                                <i className="fas fa-eye text-icon"></i>
                                View
                            </button>
                            <button className="mobile-mng-jrnl-btn" onClick={handleCreateNewClick}>
                                <i className="fas fa-plus-circle text-icon"></i>
                                Create
                            </button>
                        </div>
                    </>
                ) : ( 
                    <>
                        <div className="manage-posts-title">
                            <h3>Journal Entries</h3>
                            <h5>{journalName}</h5>
                        </div>
                        <div className="header-buttons-posts">
                            <Link to={`/site/${sitename}/manage-journals/`} className="back-to-journals-link">Back to Journals</Link>
                            <button className="create-new-btn" onClick={handleViewAsMapClick}>
                                View
                                <i className="fas fa-eye jrnl-create"></i>
                            </button>
                            <button className="create-new-btn" onClick={handleCreateNewClick}>
                                Create
                                <i className="fas fa-plus-circle jrnl-create"></i>
                            </button>
                        </div>
                    </>
                )}
            </div>
            <div className="manage-posts">
                {posts.length > 0 ? (
                    <>
                        {!isMobile ? (
                            <div className="posts-list">
                                {posts.map(post => (
                                    <div key={post.id} className="post">
                                        <div className="post-header">
                                            <div className="post-title-container">
                                                <h3 className="post-title">{post.title}</h3>
                                                <h3 className="post-title-draft"> {post.poststatus === 'DRAFT' ? '  [DRAFT]': ''}</h3>
                                            </div>
                                            <p className="post-date">{post.datestart}</p>
                                        </div>
                                        <div className="post-body-posts">
                                            <p className="post-content">{post.content}</p>
                                        </div>
                                        {post.poststatus === 'DRAFT' && (
                                            <button className="mobile-mng-jrnl-btn view" onClick={() => handlePublish(post)}>
                                                <i className="fas fa-plus-circle text-icon"></i>
                                                Publish
                                            </button>
                                        )} 
                                        <button className="mobile-mng-jrnl-btn view" onClick={() => handleViewPostClick(post)}>
                                            <i className="fas fa-eye text-icon"></i>
                                            View 
                                        </button>
                                        <button className="mobile-mng-jrnl-btn edit" onClick={() => handleEditPost(post)}>
                                            <i className="fas fa-pencil-alt text-icon"></i>
                                            Edit
                                        </button>
                                        <button className="mobile-mng-jrnl-btn dlt" onClick={() => handleDeleteClick(post)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ) : ( 
                            <div className="posts-list">
                                {posts.map(post => (
                                    <div key={post.id} className="post">
                                        <div className="post-header">
                                            <div className="post-title-container">
                                                <h3 className="post-title">{post.title}</h3>
                                                <h3 className="post-title-draft"> {post.poststatus === 'DRAFT' ? '  [DRAFT]': ''}</h3>
                                                {post.poststatus === 'DRAFT' && (
                                                    <button className="create-new-btn publish-btn" style={{ marginTop: '0', marginBottom: '0', padding: '1vh 1vw' }} onClick={() => handlePublish(post)}>
                                                        Publish
                                                        <i class="fas fa-upload jrnl-create"></i>
                                                    </button>
                                                )} 
                                            </div>
                                            <p className="post-date">{post.datestart}</p>
                                        </div>
                                        <div className="post-body-posts">
                                            <p className="post-content">{post.content}</p>
                                        </div>
                                        <div className="mobile-journal-buttons">
                                            <button className="mobile-mng-jrnl-btn" onClick={() => handleViewPostClick(post)}>
                                                <i className="fas fa-eye"></i>
                                            </button>
                                            <button className="mobile-mng-jrnl-btn" onClick={() => handleEditPost(post)}>
                                                <i className="fas fa-pencil-alt"></i>
                                            </button>
                                            <button className="mobile-mng-jrnl-btn dlt" onClick={() => handleDeleteClick(post)}>
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                ) : (
                    <p>No Journal Entries to display. Click 'New Journal Entry +' to get started.</p>
                )}

            </div>
            {
                showSessionTimeoutPopup && (
                    <Modal show={true} onHide={() => setShowSessionTimeoutPopup(false)} centered>
                        <Modal.Header closeButton>
                        <Modal.Title>
                            <h3>Session Timeout</h3>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Session timed out! You will be redirected to the home page.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={handleSessionTimeout}>OK</button>
                        </Modal.Footer>
                    </Modal>
                )
            }
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Journal Entry?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete: <br></br><br></br> <b>{postToDelete?.title}</b>?<br></br><br></br> Deleting this journal entry will remove it permanently. You will not be able to recover this data.
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                    <button className="btn btn-danger" onClick={() => handleDeleteConfirm()}>Delete</button>
                </Modal.Footer>
            </Modal>
            {isLoading && (
                <div className="spinner-overlay">
                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`} alt="Loading..." className="spinner-logo" />
                </div>
            )}
            <WalkthroughPopup componentName="managePosts">
                <p><b>Welcome to Your Posts.</b></p>
                <p>Think of a post like a journal entry or a blog post, except they are tagged to different routes on a map.</p>
                <br></br>
                <p>In Manage Posts you can edit existing posts in your current journal, or create a new one. Use the 'View Post' and 'View as Map' buttons to view a specific post or your whole journal as a map.</p>
            </WalkthroughPopup>
        </div>
    );
};

export default ManagePosts;
