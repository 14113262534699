// FormMap.js
import React, { useEffect } from 'react';
import Map, { FullscreenControl, GeolocateControl, Marker, Source, Layer, Popup } from 'react-map-gl';
import './FormMap.css'
import { useMediaQuery } from 'react-responsive';

const JournalEntryMap = ({ setCurrentMapStyle, mapViewState, mapRef, handleMapClick, handleMapLoad, features, renderGeoTaggedPoints, currentMapStyle, selectedMarker, setSelectedMarker, routeGeoJSON, pointFeatures, polygonFeatures, onMapReady, configure3DTerrain, tempWaypoint }) => {

    const TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
    const isMobile = useMediaQuery({ maxWidth: 768 });
    // style for points
    // const pointStyle = {
    //     type: 'circle',
    //     paint: {'circle-radius': 6, 'circle-color': '#ffef00'}
    // }

    // // style for lines
    // const lineStyle = {
    //     type: 'line',
    //     layout: {'line-cap': 'round', 'line-join': 'round'},
    //     paint: {'line-color': '#ffef00', 'line-width': 3,}
    // }
    
    // // style for polygons
    // const polyStyle = {
    //     type: 'fill',
    //     paint: {'fill-color': '#00ff00', 'fill-opacity': 0.5}
    // }

    useEffect(() => {
        if (mapRef.current) {
          // Safe to access mapRef.current here
          const map = mapRef.current.getMap();
          // You can now use the map instance
        }
    }, [mapRef]);

    const handleLoad = (event) => {
        handleMapLoad(event); 
        onMapReady(mapRef.current); 
    };

    //basemap styles and tracker
    const baseMapStyles = {
        Outdoors: "mapbox://styles/mapbox/outdoors-v11",
        Satellite: "mapbox://styles/mapbox/satellite-streets-v12",
        Streets: "mapbox://styles/mapbox/streets-v11",
        Vintage: "mapbox://styles/twalker29/cls9f3cak00gl01pv0ykjf81d",
    };

    // change the basemap
    const handleBaseMapChange = (event) => {
        const styleName = event.target.value;
        const map = mapRef.current.getMap();
        const newMapStyle = baseMapStyles[styleName];
        if (newMapStyle) {
            setCurrentMapStyle(newMapStyle);
        }
        map.once('style.load', () => {
            configure3DTerrain(map);
        });
    };

    

    return (
        <Map
            ref={mapRef}
            initialViewState={mapViewState}
            mapStyle={currentMapStyle} 
            mapboxAccessToken={TOKEN}
            onClick={handleMapClick}
            onLoad={handleLoad}
        >
            {/* {Object.values(features).map(feature => {
                let style;
                switch (feature.geometry.type) {
                    case 'Point':
                        style = {...pointStyle};
                        break;
                    case 'LineString':
                        style = {...lineStyle};
                        break;
                    case 'Polygon':
                        style = {...polyStyle};
                        break;
                    default:
                        // Default style or throw an error
                }
                return (
                    <Source key={feature.id} type="geojson" data={feature}>
                        <Layer {...style} />
                    </Source>
                );
            })} */}
            {/* <FullscreenControl position="top-right" />
            <GeolocateControl /> */}
            {renderGeoTaggedPoints()}

            {/* adds a temp waypoint marker while the user is creating a new point feature */}
            {tempWaypoint && (
                <Marker longitude={tempWaypoint.longitude} latitude={tempWaypoint.latitude} color="#888888" />
            )}
            

            {!isMobile && (
                <select onChange={handleBaseMapChange} style={{ position: 'absolute', top: '1.5vh', right: '20vw', zIndex: 30, fontSize: '1rem' }}>
                    {Object.keys(baseMapStyles).map((style) => (
                    <option key={style} value={style}>{style}</option>
                    ))}
                </select>
            )}
            {selectedMarker && (
                <Popup
                    longitude={selectedMarker.longitude}
                    latitude={selectedMarker.latitude}
                    closeButton={true}
                    closeOnClick={false}
                    onClose={() => setSelectedMarker(null)}
                    anchor="bottom"
                >
                    <div>{selectedMarker.title}</div>
                </Popup>
            )}
            {/* renders in progress lines in grey as they are being drawn on the map */}
            {routeGeoJSON && (
                <Source id="route" type="geojson" data={routeGeoJSON}>
                <Layer
                    id="route"
                    type="line"
                    source="route"
                    layout={{
                    'line-join': 'round',
                    'line-cap': 'round'
                    }}
                    paint={{
                    'line-color': '#888',
                    'line-width': 8
                    }}
                />
                </Source>
            )}
            {Object.values(features)
                .filter(feature => feature.properties.mode !== 'Point' && feature.properties.mode !== 'polygon') 
                .map(feature => {

                    return (
                        <Source key={feature.id} id={`point-${feature.id}`} type="geojson" data={feature}>
                           <Layer
                                id={`line-layer-${feature.id}`}
                                type="line"
                                source="route"
                                layout={{
                                'line-join': 'round',
                                'line-cap': 'round'
                                }}
                                paint={{
                                'line-color': feature.properties.color,
                                'line-width': feature.properties.shapeSize,
                                ...(feature.properties.featureShape && { 'line-dasharray': feature.properties.featureShape }),
                                }}
                            />
                        </Source>
                    );
                }
            )}
            
            {Object.values(features)
                .filter(feature => feature.properties.mode === 'Point') 
                .map(feature => {
                    const iconName = feature.properties.selectedSvgIcon || 'map-marker.svg';
                    console.log(iconName) 
                    console.log (feature)
                    //const iconPath = `https://storage.googleapis.com/mapylon-images-static/map_icons/${iconName}`;
                    return (
                        <Source key={feature.id} id={`point-${feature.id}`} type="geojson" data={feature}>
                            <Layer
                                id={`point-layer-${feature.id}`}
                                type="symbol"
                                source={`point-${feature.id}`}
                                layout={{
                                    'icon-image': iconName.replace('.svg',''),
                                    'icon-size': 0.05,
                                }}
                            />
                        </Source>
                    );
                    // return (
                    //     <Source key={feature.id} id={`point-${feature.id}`} type="geojson" data={feature}>
                    //         <Layer
                    //             id={`point-layer-${feature.id}`}
                    //             type="circle"
                    //             source={`point-${feature.id}`}
                    //             paint={{
                    //                 'circle-radius': 6,
                    //                 'circle-color': feature.properties.color,
                    //             }}
                    //         />
                    //     </Source>
                    // );
                }
            )}
            {Object.values(features)
                .filter(feature => feature.properties.mode === 'polygon') 
                .map(feature => {
                    return(
                        <Source key={feature.id} id={`polygon-${feature.id}`} type="geojson" data={feature}>
                            <Layer
                                id={`polygon-layer-${feature.id}`}
                                type="fill"
                                source={`polygon-${feature.id}`}
                                paint={{
                                    'fill-color': feature.properties.color, 
                                    'fill-opacity': feature.properties.opacity,
                                }}
                            />
                        </Source>
                    )
                }
            )}

        </Map>
    );
  };
  
  export default JournalEntryMap;
  