// WalkthroughCreate.js
const WalkthroughCreate = ({ WalkthroughPopup }) => {
    return (
        <div>
            <WalkthroughPopup componentName="createPostPhotos">
                <p><b>Adding Photos</b></p>
                <p>Click the Upload Photos button to get started. Acceptable photo formats include .jpg, .png, and .HEIC (iPhone).</p>
                <br></br>
                <p>After adding photo(s) you can further improve your journal entry by adding a title and description to each photo and geo-tagging them on the map.</p>
                <br></br>
                <p>To give a photo a title and description, simply click on the image to pull up the naming popup. To geotag an image on the map, click the little globe icon on the image, then click 
                    on the map where you want to place the image. If you need to move the image location, you can always click on the globe again and move the image.</p>
            </WalkthroughPopup>
            <WalkthroughPopup componentName="createPostFeatures">
                <p><b>How to Add Routes to your Journal Entry</b></p>
                <p>Within the map you can choose to add a guided walking, cycling, or driving route, or free draw. Use the controls in the top left to create the route(s) you want to 
                    associate with this post. Walking, cycling, and driving drawing modes will auto-follow roads and trails. Free draw let's you draw a line anywhere on the map, great for
                    off trail adventures, or places where a trail isn't on the map.</p>
                <br></br>
                <p>If you're creating a journal entry for a long route, or just don't want to draw the route by hand, you can also import GeoJSON or GPX files with the import selector above the map.</p>
                <br></br>
                <p>After adding your route(s), you can name them and edit existing routes with the workbox at the bottom of the map.</p>
            </WalkthroughPopup>
            <WalkthroughPopup componentName="createPostMain">
                <p><b>Creating a Journal Entry</b></p>
                <p>Now it's time to start filling your journal with adventure, one entry at a time.</p>
                <br></br>
                <p>In order to create a Journal Entry, you need to have a title, a date and at least one feature drawn on the map.</p>
                <br></br>
                <p>Now let's look more closely at the map and uploading photos.</p>
            </WalkthroughPopup>
        </div>
    );
  };
  
  export default WalkthroughCreate;
  