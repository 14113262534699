// manageJournals.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import '../../_Shared_ProjectWide/Styles/ProjectStyles.css';
import './ManageJournals.css'; 
import './ManageJournalBooks.css';
import Modal from 'react-bootstrap/Modal';
import JournalSettingsModal from './components/JournalSettingsModal';
import WalkthroughPopup from '../../_Shared_ProjectWide/Popups/WalkthroughPopup';
import HeaderManage from '../../_Shared_ProjectWide/Headers/HeaderManage';
import { useMediaQuery } from 'react-responsive';

const ManageJournals = () => {

    
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const { sitename } = useParams();
    const [showSessionTimeoutPopup, setShowSessionTimeoutPopup] = useState(false);
    const [currentJournal, setCurrentJournal] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [journalToDelete, setJournalToDelete] = useState(null);
    const [copiedBlogURL, setCopiedBlogURL] = useState(null);
    


    useEffect(() => {
        fetchJournals();
    }, []);

    const fetchJournals = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('You must be logged in to view this page.');
            setIsLoading(false);
            return;
        }
    
        try {
            setIsLoading(true);
            showProcessingPopup();
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/view-journals/${sitename}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const sortedBlogs = response.data.sort((a, b) => {
                const dateA = a.most_recent_post_date ? new Date(a.most_recent_post_date) : new Date(0); 
                const dateB = b.most_recent_post_date ? new Date(b.most_recent_post_date) : new Date(0);
                return dateB - dateA; // Descending order
            });

            setBlogs(sortedBlogs)
    
        } catch (error) {
            if (error.response) {
                const status = error.response.status;
                switch (status) {
                    case 401:
                        // Set state to true to show the session timeout popup
                        setShowSessionTimeoutPopup(true);
                        break;
                    case 403:
                        // Handle forbidden access
                        alert('Forbidden Access! You do not have permission to view this resource.');
                        navigate('/home');
                        break;
                    case 404:
                        // Handle not found
                        alert('Resource not found. The journals you are trying to access do not exist.');
                        navigate('/home');
                        break;
                    default:
                        setError('Failed to load journals. Please try again later.');
                }
            } else {
                setError('An unexpected error occurred. Please check your network and try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const handleCreateNewClick = () => {
        navigate(`/site/${sitename}/create-journal`); 
    };

    const handleEditJournalClick = (slugifiedTitle) => {

        navigate(`/site/${sitename}/journals/${slugifiedTitle}/manage-posts`);
    };

    const handleViewJournalClick = (blog) => {
        const baseUrl = window.location.origin; 

        if(blog.visibility === 'public-url') {
            const fullPath = `${baseUrl}/urltoken/site/${sitename}/journals/${blog.slug}/view/${blog.access_token}`;
            window.open(fullPath, '_blank', 'noopener,noreferrer');
        } else {
            const fullPath = `${baseUrl}/site/${sitename}/journals/${blog.slug}/view`;
            window.open(fullPath, '_blank', 'noopener,noreferrer');
        }  

    };

    const handleCopyUrlClick = (blog) => {
        const baseUrl = window.location.origin; 
        setCopiedBlogURL(blog);
        if (blog.visibility === 'public-url') {
            const fullPath = `${baseUrl}/urltoken/site/${sitename}/journals/${blog.slug}/view/${blog.access_token}`;
            navigator.clipboard.writeText(fullPath)
                .then(() => {
                    console.log('Path copied to clipboard successfully!');
                })
                .catch(err => {
                    console.error('Failed to copy path to clipboard: ', err);
                });
        } else {
            const fullPath = `${baseUrl}/site/${sitename}/journals/${blog.slug}/view`;
            navigator.clipboard.writeText(fullPath)
                .then(() => {
                    console.log('Path copied to clipboard successfully!');
                })
                .catch(err => {
                    console.error('Failed to copy path to clipboard: ', err);
                });
        }
        
    }

    const showProcessingPopup = () => {
        setIsLoading(true);
    };

    const handleEditSettingsClick = async (journalId) => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('You must be logged in to perform this action.');
            return;
        }
    
        try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/journal-settings/${journalId}/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCurrentJournal(response.data); 
            setShowEditModal(true); 
        } catch (error) {
            setError('Failed to load journal settings. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const renderJournalList = () => (
        <div className="welcome-container">
            <div className="manage-posts-header">
                {!isMobile ? (
                    <>
                        <h2>My Journals</h2>
                        <div className="header-buttons">
                            <button className="mobile-mng-jrnl-btn jrnl-btn" onClick={handleCreateNewClick}>
                                <i className="fas fa-plus-circle text-icon"></i>
                                Create
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <h3>Journals</h3>
                        <div className="header-buttons">
                        <button className="create-new-btn" onClick={handleCreateNewClick}>
                            <i className="fas fa-plus-circle text-icon"></i>
                            Create
                        </button>
                        </div>
                    </>
                )}

            </div>
            <div className="posts-list">
                {blogs.map(blog => (
                    <div key={blog.id} className="post">
                        <div className="post-header">
                        <h3 className="post-title">
                            {blog.journalname}
                            {!isMobile && (
                                <button
                                    className={`copy-jrnl-url ${(copiedBlogURL === blog) ? 'copied' : ''}`} 
                                    onClick={() => handleCopyUrlClick(blog)}>
                                    {(copiedBlogURL === blog) ? (
                                        <>
                                            Copied
                                            <i className="fas fa-check copy-icon" ></i>
                                        </>
                                    ) : (
                                        <>
                                            Copy URL
                                            <i className="fas fa-copy copy-icon" ></i>
                                        </>
                                    )}
                                        
                                </button>
                            )} 
                        </h3>
                        <p className="most-recent-post">
                            {blog.most_recent_post_date ? 
                                <>Most Recent Journal Entry:{' '}{blog.most_recent_post_date}</> : 
                                <i>No Journal Entries Yet. Click 'Manage Entries' to open your Journal.</i>}
                        </p>
                        <p>{blog.journaldescription}</p>
                        </div>

                        {!isMobile ? (
                            <div className="post-body">
                                <button
                                        className="mobile-mng-jrnl-btn jrnl-btn" 
                                        onClick={() => handleEditSettingsClick(blog.id)}>
                                            <i className="fas fa-cog settings-icon text-icon" ></i>
                                            Settings
                                    </button>
                                    <button 
                                        className="mobile-mng-jrnl-btn jrnl-btn" 
                                        onClick={() => handleViewJournalClick(blog)}>
                                            <i className="fas fa-eye text-icon"></i>
                                            View
                                    </button>
                                    <button 
                                        className="mobile-mng-jrnl-btn jrnl-btn" 
                                        onClick={() => handleEditJournalClick(blog.slug)}>
                                            <i className="fas fa-pencil-alt text-icon"></i>
                                            Manage
                                    </button>
                                    <button 
                                        className="mobile-mng-jrnl-btn dlt" 
                                        onClick={() => handleDeleteClick(blog)}>
                                            <i className="fas fa-trash-alt text-icon"></i>
                                            Delete
                                    </button>
                            </div>
                        ) : ( 
                            <div className="post-body">
                                <div className="mobile-journal-buttons">
                                    <button
                                        className="mobile-mng-jrnl-btn" 
                                        onClick={() => handleEditSettingsClick(blog.id)}>
                                            <i className="fas fa-cog settings-icon" ></i>
                                    </button>
                                    <button 
                                        className="mobile-mng-jrnl-btn" 
                                        onClick={() => handleViewJournalClick(blog)}>
                                            <i className="fas fa-eye"></i>
                                    </button>
                                    <button 
                                        className="mobile-mng-jrnl-btn" 
                                        onClick={() => handleEditJournalClick(blog.slug)}>
                                            <i className="fas fa-pencil-alt"></i>
                                    </button>
                                    <button 
                                        className="mobile-mng-jrnl-btn dlt" 
                                        onClick={() => handleDeleteClick(blog)}>
                                            <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );


    const renderWelcomeMessage = () => (
        <div className="welcome-container">
            <div className="welcome-message">
                <h3>Welcome to Mapylon, and congratulations on creating your new site: { sitename }!</h3>
                <p>The next step is to create a new Journal. Get started by clicking the "Create New" button to create your first journal and write your adventure!</p>
                <p>How you organize you journals is completely up to you! Some people like to create a journal every season, every year, or maybe one for every activity. Other people like to stick with just one journal to keep all their travels and adventures in one place.</p>
                <p>Just remember, if you share your journal with friends and family they will be able to see everything marked 'public' in that journal!</p>
            </div>
            <div className="manage-posts-header">
                <h3>Your Journals</h3>
                <div className="header-buttons">
                    <button className="create-new-btn" onClick={handleCreateNewClick}>Create New</button>
                </div>
            </div>
        </div>
    );

    const handleSessionTimeout = () => {
        // Close the popup
        setShowSessionTimeoutPopup(false);
    
        // Redirect to the home page
        localStorage.removeItem('token');
        navigate('/home');
    };

    const handleDeleteClick = (journal) => {

        setJournalToDelete(journal);
        setShowDeleteModal(true);
    };
    
    const handleDeleteConfirm = async () => {
        if (!journalToDelete) return;
        setShowDeleteModal(false);
        setIsLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/delete-journal/${journalToDelete.id}/`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            alert("Journal deleted successfully"); 
            fetchJournals();
        } catch (error) {
            alert("Failed to delete journal"); 
        }
        setJournalToDelete(null);
        setIsLoading(false);
    };
    
    

    const saveJournalChanges = async (journalId, updatedJournal) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/journal-settings/update/${journalId}/`, updatedJournal, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
            // Update the state with the new journal data
            setBlogs(blogs.map(blog => blog.id === journalId ? { ...blog, ...updatedJournal } : blog));
            alert("Journal updated successfully"); 
            fetchJournals();

        } catch (error) {
            alert("Failed to update journal"); 
        }
    };

    return (
        <div className="page-container mng-posts">
            <HeaderManage />
            <div className="manage-posts">
                {isLoading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>Error: {error}</p>
                ) : blogs.length > 0 ? (
                    renderJournalList()
                ) : (
                    renderWelcomeMessage()
                )}
            </div>
            <JournalSettingsModal 
                show={showEditModal} 
                onHide={() => setShowEditModal(false)} 
                journal={currentJournal} 
                onSave={saveJournalChanges} 
            />
            {
                showSessionTimeoutPopup && (
                    <Modal show={true} onHide={() => setShowSessionTimeoutPopup(false)} centered>
                        <Modal.Header closeButton>
                        <Modal.Title>
                            <h3>Session Timeout</h3>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Session timed out! You will be redirected to the home page.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={handleSessionTimeout}>OK</button>
                        </Modal.Footer>
                    </Modal>
                )
            }
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
            <Modal.Header closeButton>
                    <Modal.Title>Delete Journal?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete: <br></br><br></br> <b>{journalToDelete?.journalname}</b>?<br></br><br></br> Deleting the journal will delete all posts within this journal. You will not be able to recover this data.
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={() => setShowDeleteModal(false)}>Cancel</button>
                    <button className="btn btn-danger" onClick={() => handleDeleteConfirm()}>Delete</button>
                </Modal.Footer>
            </Modal>
            {isLoading && (
                <div className="spinner-overlay">
                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`} alt="Loading..." className="spinner-logo" />
                </div>
            )}
            <WalkthroughPopup componentName="manageJournals">
                <p><b>Welcome to Your Journals.</b></p>
                <p>Journals are a collection of posts that you can easily share with family and friends.</p>
                <br></br>
                <p>If you've already created journals, you will see them listed here. You can edit settings, view the journal, or edit the posts within the journal here.</p>
            </WalkthroughPopup>
        </div>
    );
};

export default ManageJournals;
