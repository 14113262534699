export default class CustomControl {
    constructor(options) {
      this.options = options;
    }
  
    onAdd(map) {
      this.map = map;
      this.container = document.createElement('div');
      this.container.className = 'mapboxgl-ctrl custom-control';
      this.container.style.backgroundImage = `url(${this.options.backgroundImageUrl})`; 
      this.container.title = this.options.title; // Tooltip
      this.container.onclick = this.options.onClick;
      return this.container;
    }
  
    onRemove() {
      this.container.parentNode.removeChild(this.container);
      this.map = undefined;
    }
  }
  