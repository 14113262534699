// components/FrontEnd/PageNotFound/PageNotFound.js
import './PageNotFound.css'; 
import React from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/404_1.jpg`;
    const navigate = useNavigate();

    const navigateHome = (e) => {
        navigate('/home')
    };


    const pageStyle = {
        textAlign: 'center',
        height: '100vh', 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundImage: `url(${backgroundImageUrl})`
    };

    return (
        <div style={pageStyle}>
            <div className="not-found-page">
                <h1 className="not-found-title">404</h1>
                <p className="not-found-text">Wrong turn! We can't find that page.</p>
                <button className="home-signup-button" onClick={navigateHome}>Go Home</button>
            </div>
        </div>
    );
};

export default PageNotFound;
