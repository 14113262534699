// import React, { useState, useRef, useEffect } from 'react';
// import Map, { FullscreenControl, GeolocateControl, Source, Layer, Marker, Popup } from 'react-map-gl';
// import Modal from 'react-bootstrap/Modal';
// import toGeoJSON from '@mapbox/togeojson';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { useParams, useLocation, useNavigate } from 'react-router-dom';
// import 'mapbox-gl/dist/mapbox-gl.css';
// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// import axios from 'axios';
// import HeaderManage from '../../_Shared_ProjectWide/Headers/HeaderManage';
// import DrawControl from '../CreatePost/Utilities/DrawControl';
// import './EditPost.css'; 
// import bbox from '@turf/bbox';
// import heic2any from "heic2any";

import CreateEditJournalEntry from '../JournalEntryUtils/CreateEditJournalEntry'



const EditPost = () => {
    return <CreateEditJournalEntry  mode={'EDIT'}/>;
};

export default EditPost;
