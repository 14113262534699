import './Pricing.css'

const Pricing = () => {
    return (
        <div class="main-body">
        <div class="container">


            {/* <nav aria-label="breadcrumb" class="main-breadcrumb">
                <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Pricing</li>
                </ol>
            </nav> */}

            <div className="pricing-header">
                <div className="header-logo">
                    <img
                    src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`}
                    alt="Mapylon"
                    className="small-image"
                    />
                </div>
            </div>

            <div class="row gutters-sm">
                <div class="col-sm-6 col-xl-3 mb-3">
                <div class="card">
                    <div class="card-header border-bottom flex-column align-items-start p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box text-secondary h3 stroke-width-1 mb-2"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    <h4 class="text-secondary font-weight-light mb-2">Basic</h4>
                    <p class="font-size-sm mb-0">A great way to get to know Mapylon. Limited features and storage.</p>
                    </div>
                    <div class="card-header border-bottom justify-content-center py-4">
                    <h2 class="pricing-price">
                        <small class="align-self-start">$</small>
                        0
                        <small class="align-self-end">/month</small>
                    </h2>
                    </div>
                    <div class="card-body">
                    <ul class="list-unstyled font-size-sm mb-0">
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>3</strong><span class="text-secondary ml-1">Journals</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>10</strong><span class="text-secondary ml-1">Entries per Journal</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>4</strong><span class="text-secondary ml-1">Photos per Entry</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Unlimited</strong><span class="text-secondary ml-1">Email accounts</span></li>                        
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x text-danger mr-2"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg><strong>No</strong><span class="text-secondary ml-1">Access to Mapylon Explore</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x text-danger mr-2"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg><strong>No</strong><span class="text-secondary ml-1">Private Journals</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x text-danger mr-2"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg><strong>Mapylon</strong><span class="text-secondary ml-1">Logo on All Maps</span></li>
                    </ul>
                    </div>
                    <div class="card-footer justify-content-center p-3">
                    <button class="btn btn-outline-secondary">GET STARTED</button>
                    </div>
                </div>
                </div>
                <div class="col-sm-6 col-xl-3 mb-3">
                <div class="card border border-success">
                    <div class="card-header border-bottom flex-column align-items-start p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-package text-success h3 stroke-width-1 mb-2"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    <h4 class="text-success font-weight-light mb-2">Standard</h4>
                    <p class="font-size-sm mb-0">For explorers and travellers who want to keep a better journal of their adventures.</p>
                    </div>
                    <div class="card-header border-bottom justify-content-center py-4">
                    <h2 class="pricing-price">
                        <small class="align-self-start">$</small>
                        0.99
                        <small class="align-self-end">/month</small>
                    </h2>
                    </div>
                    <div class="card-body">
                    <ul class="list-unstyled font-size-sm mb-0">
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>10</strong><span class="text-secondary ml-1">Journals</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>25</strong><span class="text-secondary ml-1">Entries per Journal</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>8</strong><span class="text-secondary ml-1">Photos per Entry</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x text-danger mr-2"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg><strong>5MB</strong><span class="text-secondary ml-1">Max Image Size</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Access</strong><span class="text-secondary ml-1">to Mapylon Explore</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Create</strong><span class="text-secondary ml-1">Private and Public Journals</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x text-danger mr-2"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg><strong>Mapylon</strong><span class="text-secondary ml-1">Logo on All Maps</span></li>
                    </ul>
                    </div>
                    <div class="card-footer justify-content-center p-3">
                    <button class="btn btn-outline-success">GET STARTED</button>
                    </div>
                </div>
                </div>
                <div class="col-sm-6 col-xl-3 mb-3">
                <div class="card">
                    <div class="card-header border-bottom flex-column align-items-start p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers text-primary h3 stroke-width-1 mb-2"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                    <h4 class="text-primary font-weight-light mb-2">Pro</h4>
                    <p class="font-size-sm mb-0">Your camera is always close at hand when you're on the trail or on the road.</p>
                    </div>
                    <div class="card-header border-bottom justify-content-center py-4">
                    <h2 class="pricing-price">
                        <small class="align-self-start">$</small>
                        2.99
                        <small class="align-self-end">/month</small>
                    </h2>
                    </div>
                    <div class="card-body">
                    <ul class="list-unstyled font-size-sm mb-0">
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Unlimited</strong><span class="text-secondary ml-1">Journals</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Unlimited</strong><span class="text-secondary ml-1">Entries per Journal</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>15</strong><span class="text-secondary ml-1">Photos per Entry</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x text-danger mr-2"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg><strong>5MB</strong><span class="text-secondary ml-1">Max Image Size</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Access</strong><span class="text-secondary ml-1">to Mapylon Explore</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Create</strong><span class="text-secondary ml-1">Private and Public Journals</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Remove</strong><span class="text-secondary ml-1">or Customize Logo</span></li>
                    </ul>
                    </div>
                    <div class="card-footer justify-content-center p-3">
                    <button class="btn btn-outline-primary">GET STARTED</button>
                    </div>
                </div>
                </div>
                <div class="col-sm-6 col-xl-3 mb-3">
                <div class="card">
                    <div class="card-header border-bottom flex-column align-items-start p-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-gift text-danger h3 stroke-width-1 mb-2"><polyline points="20 12 20 22 4 22 4 12"></polyline><rect x="2" y="7" width="20" height="5"></rect><line x1="12" y1="22" x2="12" y2="7"></line><path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path><path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path></svg>
                    <h4 class="text-danger font-weight-light mb-2">Ultra Pro</h4>
                    <p class="font-size-sm mb-0">Every hike, ride, or adventure includes high quality photos and lots of them.</p>
                    </div>
                    <div class="card-header border-bottom justify-content-center py-4">
                    <h2 class="pricing-price">
                        <small class="align-self-start">$</small>
                        4.99
                        <small class="align-self-end">/month</small>
                    </h2>
                    </div>
                    <div class="card-body">
                    <ul class="list-unstyled font-size-sm mb-0">
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Unlimited</strong><span class="text-secondary ml-1">Journals</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Unlimited</strong><span class="text-secondary ml-1">Entries per Journal</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Unlimited</strong><span class="text-secondary ml-1">Photos per Entry</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>20MB</strong><span class="text-secondary ml-1">Max File Size</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Access</strong><span class="text-secondary ml-1">to Mapylon Explore</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Create</strong><span class="text-secondary ml-1">Private and Public Journals</span></li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check text-success mr-2"><polyline points="20 6 9 17 4 12"></polyline></svg><strong>Remove</strong><span class="text-secondary ml-1">or Customize Logo</span></li>
                    </ul>
                    </div>
                    <div class="card-footer justify-content-center p-3">
                    <button class="btn btn-outline-danger">GET STARTED</button>
                    </div>
                </div>
                </div>
            </div>

            </div>
            <div className="footer-copyright" style={{position: 'fixed', bottom: '0', width: '100%', textAlign: 'center', padding: '10px'}}>
                <p>Copyright 2024 Mapylon LLC. All Rights Reserved.</p>
            </div>
        </div>
    )
};

export default Pricing;
