import React from 'react';




const PrivacyPolicy = () => {


    return (
        <div className="container">
            <p>placeholder</p>
        </div>
    );
};

export default PrivacyPolicy;
