import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const DisplayPhotoThumbnails = ({photos, setPhotos, openModal, initiateGeotagging, deletePhoto}) => {

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          photos,
          result.source.index,
          result.destination.index
        );
    
        setPhotos(items);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-photos" direction="horizontal">
                {(provided) => (
                    <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="photo-thumbnails"
                    >   

                    {photos.map((photo, index) => (
                        <Draggable key={photo.id} draggableId={`photo-${photo.id}`} index={index}>
                        {(provided) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="photo-thumbnail-container"
                            >
                            <img
                                src={photo.file instanceof Blob ? URL.createObjectURL(photo.file) : photo.file}
                                alt={`thumbnail-${index}`}
                                className="photo-thumbnail"
                                onClick={() => openModal(index)}
                            />
                            <span className="globe-icon" onClick={(e) => { e.stopPropagation(); initiateGeotagging(index, photo.id); }}>🌐</span>
                            <span className="trash-icon" onClick={(e) => { e.stopPropagation(); deletePhoto(photo.id); }}>✖</span>
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
            </Droppable>
      </DragDropContext>
    );
};

export default DisplayPhotoThumbnails;