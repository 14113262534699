// FormValidator.js

const FormValidator = ({title, setTitleError, uiMode, setGeometryError, features, photos, geoTaggedPoints, date, setDateError}) => {
    let isValid = true;

        // Check if title is not null
        if (!title.trim()) {
            setTitleError('Title cannot be empty.');
            isValid = false;
        } else {
            setTitleError('');
        }

        if (uiMode === 'create-feature') {
            console.log("setting geometry error")
            setGeometryError('Please finish editing feature before submitting.');
            isValid = false;
        } else if (Object.keys(features).length < 1) {
            setGeometryError('At least one geometry must be uploaded, or drawn on the map.');
            isValid = false;
        } else {
            setGeometryError('');
        }

         // Validate Geotag Data
        photos.forEach((photo, index) => {
            const geotag = geoTaggedPoints[index];
            if (geotag) {
                const { longitude, latitude } = geotag;
                if (longitude < -180 || longitude > 180 || latitude < -90 || latitude > 90) {
                    alert(`Geotag data for photo ${index + 1} is invalid.`);
                    isValid = false;
                }
            }
        }); 
        

        if (!date) {
            setDateError('Date cannot be empty.');
            isValid = false;
        } else {
            const year = new Date(date).getFullYear();
            if (year < 1500 || year > 2100) {
                setDateError('Date must be between the years 1500 and 2100.');
                isValid = false;
            } else {
                setDateError('');
            }
        }

        if (isValid) {
            return true
        } else {
            return false
        }

    
  };
  
  export default FormValidator;
  