

const LineStringOptions = ({ featureColorRef, shapeRef, sizeRef }) => {
    return (
        <div className="point-options">
            <div className="color-picker-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <label htmlFor="feature-color-fill" className="feature-color-label">Select Icon Color:</label>
                <input 
                    type="color"
                    defaultValue='#ff0000'
                    ref={featureColorRef}
                    className="feature-color-fill"
                />
            </div>
            <div className="point-icon-container">
                <label htmlFor="shape">Line Type: </label>
                <select 
                    id="shape" 
                    ref={shapeRef} 
                    className="shape-control"
                >
                    <option value="solid">Solid</option>
                    <option value="dashed">Dashed</option>
                    <option value="dotted">Dotted</option>
                    <option value="dashDot">Dash Dot</option>
                </select>
            </div>
            <div className="point-icon-container">
                <label htmlFor="shape">Line Weight: </label>
                <select 
                    id="border" 
                    ref={sizeRef} 
                    className="border-control"
                    defaultValue="6"
                >
                    <option value="1">1</option>
                    <option value="3">2</option>
                    <option value="6">3</option>
                    <option value="8">4</option>
                    <option value="11">5</option>
                </select>
            </div>
        </div>


    );
};

export default LineStringOptions;