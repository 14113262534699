// UploadMultiplePhotos.js
import axios from 'axios';

const UploadMultiplePhotos = async (photos) => {
    // Collect file info
    const filesInfo = photos.map(photo => ({ 
        filename: photo.file.name, 
        contentType: photo.file.type,
        id: photo.id 
    }));
    // Get signed URLs from your backend
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/user-posts/generate-signed-urls/`, { files: filesInfo });
    const { signedUrls } = response.data;

    // Upload files in parallel`
    const uploadPromises = signedUrls.map((obj) => {
        const signedUrl = obj.signedUrl;
        //const modifiedFilename = obj.modifiedFilename;
        const file = photos.find(photo => photo.file.name === obj.filename).file;
        if (!file || !signedUrl) {
            console.error(`Missing file or signed URL for: ${obj.filename}`);
            return Promise.reject(new Error(`Missing file or signed URL for: ${obj.filename}`));
        }
        return fetch(signedUrl, {
            method: 'PUT',
            headers: { 'Content-Type': file.type },
            body: file,
        }).then(response => {
            // Handle the response text
            return response.text().then(text => {
                if (!response.ok) {
                    throw new Error(`Upload failed for ${obj.filename}`);
                }
                // Use the modified filename in the URL or any subsequent actions
                return {
                    url: `https://storage.googleapis.com/mapylon-images-1/photos/${encodeURIComponent(obj.modifiedFilename)}`,
                    id: obj.id 
                };
            });
        });
    });
    

    // Wait for all uploads to complete
    const results = await Promise.all(uploadPromises);

    // Process results (e.g., check for success, handle errors, collect public URLs)
    const idToUrlMap = results.reduce((acc, result) => {
        if (result) {
            acc[result.id] = result.url;
        } else {
            console.error('Upload failed for a file');
        }
        return acc;
    }, {});

    return idToUrlMap; 
};
  
export default UploadMultiplePhotos;
  