// MapGeometryInfo.js
import React, { useRef, useState } from 'react';
import './MapGeometryInfo.css'
import PolygonOptions from './FeatureEditUtils/Polygon';
import LineStringOptions from './FeatureEditUtils/LineString';
import PointOptions from './FeatureEditUtils/Point';

const MapGeometryInfo = ({ saveFeatureName, cancelEdit, openIconModal, selectedIcon, features, setFeatures, editFeature, featureNames, selectedFeature, uiMode, resetModes, handleEditFeatureClick, newFeatureName, setNewFeatureName, finishRouteCreation, cancelRouteCreation, undoLastWaypoint, waypoints, isGeoInfoVisible, toggleGeoInfoVisibility, routeMode }) => {
    
    const editInputRef = useRef(null);
    const colorPickerRef = useRef(null);
    //const fillColorRef = useRef(null);
    const shapeRef = useRef(null);
    const featureColorRef = useRef(null);
    const sizeRef = useRef(null);
    const opacityRef = useRef(null);
    const borderRef = useRef(null);
    const featureNameRef = useRef(null);

    const handleDeleteClick = (event, featureToDelete) => { 
        event.stopPropagation(); 
        console.log(featureToDelete);
        console.log(features);
    
        // Constructing a new object excluding the feature to delete
        const updatedFeatures = Object.entries(features).reduce((acc, [currentKey, currentValue]) => {
            if (currentKey !== String(featureToDelete.id)) { // Ensure both IDs are treated as strings
                acc[currentKey] = currentValue;
            }
            return acc;
        }, {});
    
        resetModes();
        setFeatures(updatedFeatures);
    };
    

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const modeIconMapping = {
        walk: "trailhead", 
        bike: "bike",  
        drive: "car",     
        free_draw: "pencil",
        Point: "location-dot-solid",
        polygon: "draw-polygon-solid",
    };

    const dashArrayMapping = {
        solid: null, 
        dashed: [2, 2],
        dotted: [0.2, 3],
        dashDot: [1.5, 3, 0.2, 3],
    };

    const routeCreation = () => {
        if (routeMode === 'polygon') {
            const newFeatureName = featureNameRef.current.value || "Unnamed Polygon";
            const fillColor = featureColorRef.current.value; //grab HTML fill color from create-feature
            const opacity = opacityRef.current.value / 100; //divide opacity value by 100 to get percent
            const formattingData = {
                newFeatureName: newFeatureName,
                fillColor: fillColor,
                opacity: opacity,
            }
            finishRouteCreation('polygon', formattingData)
        } else if (routeMode === 'point') {
            const newFeatureName = featureNameRef.current.value || "Unnamed Point";
            const fillColor = featureColorRef.current.value;
            const userSelectedIcon = selectedIcon || "park-2.svg";
            const selectedShape = shapeRef.current.value;
            const selectedSize = sizeRef.current.value;
            const borderOption = borderRef.current.value;
            const formattingData = {
                newFeatureName: newFeatureName,
                fillColor: fillColor,
                userSelectedIcon: userSelectedIcon,
                selectedShape: selectedShape,
                selectedSize: selectedSize,
                borderOption: borderOption,
            }
            finishRouteCreation('point', formattingData)
        } else {
            const newFeatureName = featureNameRef.current.value || "Unnamed Route";
            const fillColor = featureColorRef.current.value;
            const selectedShape = shapeRef.current.value;
            const lineDashArray = dashArrayMapping[selectedShape];
            const selectedSize = sizeRef.current.value;
            const formattingData = {
                newFeatureName: newFeatureName,
                fillColor: fillColor,
                lineDashArray: lineDashArray,
                selectedSize: selectedSize,
                selectedRoute: routeMode,
            }
            finishRouteCreation('line', formattingData)
        }


    }

    function capitalizeFirstLetter(string) {
        if(string === "free_draw") {
            return "Free Draw"
        } else {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
    

    
    return (
        <>
            <div className={`geometry-wrapper ${isGeoInfoVisible ? 'visible' : 'none'}`}>
                {isGeoInfoVisible && (
                    <div className={`geometry-info ${isGeoInfoVisible ? 'visible' : 'none'}`}>
                        <div className="left-geometry-info">
                            <div className="manage-routes-header" style ={{ height: '7vh' }}>
                                <h5>Manage Routes</h5>
                            </div>
                            {uiMode === 'create-feature' && (
                                <div className="create-naming" style={{ position: 'absolute', height: '35vh', backgroundColor: 'rgb(221,221,221)', zIndex: '150', width: '100%', top: '0' }}>
                                    <div className="create-feature">
                                        <h5>{`Create Feature - ${capitalizeFirstLetter(routeMode)}`}</h5>
                                        <input
                                            type="text"
                                            placeholder="Feature Name"
                                            ref={featureNameRef}
                                            value={newFeatureName}
                                            onChange={(e) => setNewFeatureName(e.target.value)}
                                            style={{ marginBottom: '10px', paddingLeft: '0.5vw', paddingTop: '0.5vh', paddingBottom: '0.5vh' }}
                                        />

                                        {/* conditionally render geometry customizing options based on type of feature user is creating */}
                                        {
                                            (routeMode === 'polygon') ? (
                                                <PolygonOptions 
                                                    featureColorRef={featureColorRef}
                                                    opacityRef={opacityRef}
                                                />
                                            ) : (routeMode === 'point') ? (
                                                <PointOptions
                                                    featureColorRef={featureColorRef}
                                                    selectedIcon={selectedIcon}
                                                    openIconModal={openIconModal}
                                                    shapeRef={shapeRef}
                                                    sizeRef={sizeRef}
                                                    borderRef={borderRef}
                                                />
                                            ) : (
                                                <LineStringOptions 
                                                    featureColorRef={featureColorRef}
                                                    shapeRef={shapeRef}
                                                    sizeRef={sizeRef}
                                                />
                                            )
                                        }
                                        <div className="create-feature-btn-container" style={{ marginTop: '2vh' }}>
                                            <button onClick={routeCreation}>Finish</button>
                                            <button onClick={cancelRouteCreation}>Cancel</button>
                                            {waypoints.length > 0 && <button onClick={undoLastWaypoint}>Undo</button>}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="geometry-table">
                                {Object.keys(features).length > 0 && (
                                <table>
                                    <tbody>
                                    {Object.values(features).map((feature, index) => (
                                        <>
                                        <tr>
                                            <td>   
                                                <button 
                                                    className="mobile-mng-jrnl-btn dlt" 
                                                    onClick={(event) => handleDeleteClick(event, feature)}>
                                                        <i className="fas fa-trash-alt"></i>
                                                </button>   
                                                <button 
                                                    className="mobile-mng-jrnl-btn edit" 
                                                    onClick={(event) => editFeature(feature)}>
                                                        <i className="fas fa-pencil-alt"></i>
                                                </button>   
                                            </td>                     
                                            <td>
                                                {feature.properties.mode && modeIconMapping[feature.properties.mode] ? (
                                                <img 
                                                    src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/${modeIconMapping[feature.properties.mode]}.svg`}
                                                    alt={feature.properties.mode}
                                                    style={{ width: '2vh', height: '2vh' }} 
                                                />
                                                ) : (
                                                <span>{feature.properties.mode}</span> 
                                                )}
                                            </td>
                                            <td style={{ maxWidth: '9vw', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{featureNames[feature.id] || 'Unnamed'}</td>
                                            <td>
                                                {feature.properties.area
                                                    ? numberWithCommas(feature.properties.area.toFixed(1)) + ' ac'
                                                    : feature.properties.length
                                                        ? numberWithCommas(feature.properties.length.toFixed(3)) + ' mi'
                                                        : 'N/A'}
                                            </td>
                                            <td>{feature.properties.gain ? numberWithCommas('+' + feature.properties.gain.toFixed(0)) + ' ft' : 'N/A'}</td>
                                            <td>{feature.properties.loss ? numberWithCommas('-' + feature.properties.loss.toFixed(0)) + ' ft' : 'N/A'}</td>
                                        </tr>
                                        {uiMode === 'edit-feature' && selectedFeature && selectedFeature.id === feature.id && (
                                            <tr className="edit-feature-row">
                                                <td colSpan="5">
                                                    <div className="edit-feature" style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <h5>Edit Feature</h5>
                                                        <input 
                                                            type="text"
                                                            defaultValue={featureNames[selectedFeature.id] || ''}
                                                            ref={editInputRef} 
                                                            className="feature-name-input"
                                                        />
                                                        <div className="color-picker-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                            <label htmlFor="feature-color-input" className="feature-color-label">Select Color:</label>
                                                            <input 
                                                                type="color"
                                                                defaultValue={selectedFeature.properties.color}
                                                                ref={colorPickerRef}
                                                                className="feature-color-input"
                                                            />
                                                        </div>
                                                        <div className="edit-feature-buttons" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <button className="route-edit-save" onClick={() => saveFeatureName(selectedFeature.id, editInputRef.current.value, colorPickerRef.current.value)}>Save</button>
                                                            <button className="route-edit-cxl" onClick={cancelEdit}>Cancel</button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                                )}
                            </div>
                        </div>
                    </div>
                )}

            </div>
            <div className={`hide-show-geo-pan ${!isGeoInfoVisible ? 'minimized' : ''}`}>
                <button className="routes-min-btn" onClick={toggleGeoInfoVisibility}>
                    {isGeoInfoVisible ? (
                        <i className="fas fa-window-minimize min"></i>
                    ) : (
                        <>
                            <p>Manage Routes</p>
                            <i className="fas fa-expand-arrows-alt max"></i>
                        </>
                    )} 
                </button>
            </div>
        </>

    );
};
export default MapGeometryInfo;
  