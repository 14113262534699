// AddMapControls.js
import CustomControl from '../../../mapbox/CustomControl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

const AddMapControls = (setRouteMode, updateCursorStyle, setUIMode, mapRef, controlsAdded, setControlsAdded, handleSearchButtonClick, isMobile, TOKEN) => {
    const selectMode = (mode) => {
        setRouteMode(mode);
        updateCursorStyle('crosshair');
        setUIMode('create-feature');
    };

    if (mapRef.current && !controlsAdded) {
        const map = mapRef.current.getMap();


        const controls = {
            walkControl: new CustomControl({
                mode: 'Walk',
                onClick: () => selectMode('walk'),
                title: 'Walk Mode',
                backgroundImageUrl: `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/trailhead.svg`
            }),
            bikeControl: new CustomControl({
                mode: 'Bike',
                onClick: () => selectMode('bike'),
                title: 'Bike Mode',
                backgroundImageUrl: `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/bike.svg`
            }),
            driveControl: new CustomControl({
                mode: 'Drive',
                onClick: () => selectMode('drive'),
                title: 'Drive Mode',
                backgroundImageUrl: `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/car.svg`
            }),
            freedrawControl: new CustomControl({
                mode: 'Draw',
                onClick: () => selectMode('free_draw'),
                title: 'Free Draw Mode',
                backgroundImageUrl: `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/pencil.svg`
            }),
            pointControl: new CustomControl({
                mode: 'Point',
                onClick: () => selectMode('point'),
                title: 'Point Mode',
                backgroundImageUrl: `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/location-dot-solid.svg`
            }),
            polygonControl: new CustomControl({
                mode: 'Draw',
                onClick: () => selectMode('polygon'),
                title: 'Polygon Mode',
                backgroundImageUrl: `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/draw-polygon-solid.svg`
            }),
        };

        if (!isMobile) {
            let geocoder = new MapboxGeocoder({
                accessToken: TOKEN,
                mapboxgl: mapRef,
                marker: false,
                id: 'geocoder' 
            });
            map.addControl(geocoder, 'top-right')
        } else {
            const searchButtonControl = new CustomControl({
                onClick: () => {
                    handleSearchButtonClick()
                },
                title: 'Search',
                backgroundImageUrl: `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/search.svg`
            });
            map.addControl(searchButtonControl, 'top-right');
        }

        // Add custom controls to the map
        Object.values(controls).forEach(control => map.addControl(control, 'top-right'));
    
        // Update state to indicate controls have been added
        setControlsAdded(true);
        }
};
  
  export default AddMapControls;
  