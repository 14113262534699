// FormGeometry.js
import toGeoJSON from '@mapbox/togeojson';
import bbox from '@turf/bbox';

const GeometryInput = ({ geometryError, generateUniqueId, setFeatureNames, setFeatures, mapRef }) => {

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
    
        if (file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = (e.target.result);
                try {
                    let geojsonData;
                    if (file.name.endsWith('.gpx')) {
                        const dom = new DOMParser().parseFromString(text, 'text/xml');
                        geojsonData = toGeoJSON.gpx(dom); 
                    } else {
                        geojsonData = JSON.parse(text); 
                    }
    
                    updateMapWithGeojsonData(geojsonData);
                } catch (error) {
                    console.error('Error parsing file:', error);
                }
            };
            reader.readAsText(file);
    
            const fileName = event.target.files[0].name;
            const label = document.querySelector('.custom-file-label');
            if (label) {
                label.textContent = fileName;
            }
        }
    };

    const updateMapWithGeojsonData = (geojsonData) => {
        const uploadedFeatures = geojsonData.features.map(feature => {
            return {
                id: generateUniqueId(), 
                ...feature
            };
        });
        
        const boundingBox = bbox(geojsonData);
        setFeatureNames(currNames => {
            return {
                ...currNames,
                ...uploadedFeatures.reduce((acc, feature) => {
                    acc[feature.id] = ''; 
                    return acc;
                }, {})
            };
        });

        const formattedFeatures = uploadedFeatures.map(feature => ({
            ...feature,
            type: 'Feature',
            geometry: feature.geometry,
        }));
    
        setFeatures(currFeatures => ({
            ...currFeatures,
            ...formattedFeatures.reduce((acc, feature) => {
                acc[feature.id] = feature;
                return acc;
            }, {})
        }));

        mapRef.current?.flyTo({
            center: [
                (boundingBox[0] + boundingBox[2]) / 2,
                (boundingBox[1] + boundingBox[3]) / 2
            ],
            essential: true 
        });
    };

    return (
        <div>
            <div className="input-group" style={{marginBottom: '0'}}> 
                <div className="custom-file">
                    <input type="file" className="custom-file-input" id="customFile" accept=".geojson,.gpx,.json" onChange={handleFileChange} />
                    <label className="custom-file-label" style={{ width: '90%' }} htmlFor="customFile">Optional: Upload GPX/GeoJSON file</label> 
                </div>
            </div>
            <div className="geometry-section">
                {geometryError && <div className="error-message" style={{ color: 'red' }}>{geometryError}</div>}
            </div>
        </div>
    );
  };
  
  export default GeometryInput;
  