// src/components/Logout.js
import React from 'react';
import '../_Shared_UserAuth/AuthStyles.css';

const Logout = ({ onLogout }) => {

    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background10.jpg`;
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`
    };

    return (
        <div className="auth-container" style={backgroundStyle}>
            <button onClick={onLogout} className="auth-form">Logout</button>
        </div>
    );
};

export default Logout;
