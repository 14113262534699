import heic2any from "heic2any";

const convertHEICToJPG = async (file) => {
    try {
      const convertedBlob = await heic2any({
        blob: file,
        toType: "image/jpeg",
      });
      return new File([convertedBlob], file.name.replace(/\.heic$/, ".jpg"), {
        type: "image/jpeg",
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  export default convertHEICToJPG;