import React, { useState } from 'react';
import { useWalkthrough } from '../../../context/WalkthroughContext'; 
import './WalkthroughPopup.css'; 
import { useMediaQuery } from 'react-responsive';

const WalkthroughPopup = ({ componentName, children }) => {
    const { walkthroughStatus, updateWalkthroughStatus } = useWalkthrough();
    const [isVisible, setIsVisible] = useState(true);
    const isMobile = useMediaQuery({ maxWidth: 768 });
  
    const handleClose = (e) => {
      // If the checkbox is checked, update status to not show this walkthrough again
      updateWalkthroughStatus(componentName, e.target.checked);
    };
  
    const handleHide = () => {
      // Simply hides the popup without affecting the walkthrough status
      setIsVisible(false);
    };
  
    if (!isVisible || walkthroughStatus[componentName]) {
      return null; // Don't show if already seen or manually closed
    }
  
    return (
        <div className="walkthrough-popup">
            <h5>mapylon</h5>
            {children}
            {!isMobile ? (
              <div className="walkthrough-footer">
                  <input type="checkbox" id="dontShowAgain" name="dontShowAgain" onChange={handleClose} />
                  <label htmlFor="dontShowAgain">Don't show me this again</label>
                  <button onClick={handleHide} className="walkthrough-close-btn">Close</button>
              </div>
            ) : ( 
              <div className="walkthrough-footer">
                <div className="no-show-check">
                    <input type="checkbox" id="dontShowAgain" name="dontShowAgain" onChange={handleClose} />
                    <label htmlFor="dontShowAgain">Don't show me this again</label>
                </div>
                <button onClick={handleHide} className="walkthrough-close-btn">Close</button>
              </div>
            )}
        </div>
    );
  };
  
export default WalkthroughPopup;


