import React from 'react';





const TermsOfUse = () => {


    return (
        <p>placeholder</p>
    );
};

export default TermsOfUse;
