// src/components/Signup.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from '../../../context/Context';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../_Shared_UserAuth/AuthStyles.css';

const Signup = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { isAuthorized, setIsAuthorized } = useNavigation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    
    const [error, setError] = useState('');

    const backgroundImageUrl = `${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/background29.jpg`;
    const backgroundStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundPosition: '50% 90%'
    };

    useEffect(() => {
        const checkLoginStatus = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-user-site/`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    const sitename = response.data.sitename;
                    if (sitename) {
                        navigate(`/site/${sitename}/manage-journals/`);
                    } else {
                        console.error('Sitename not found');
                        // Handle case where sitename is not found
                    }
                } catch (error) {
                    console.error('Error fetching sitename', error);
                    // Handle error (e.g., show a message to the user)
                }
            }
        };
        
        checkLoginStatus();
    }, [navigate]);

    const validateForm = () => {
        const emailRegex = /\S+@\S+\.\S+/; // Simple regex for basic email validation

        if (!email) {
            setError('Email is required');
            return false;
        }
        if (!username) {
            setError('Username is required');
            return false;
        }
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address');
            return false;
        }
        if (!password || !confirmPassword) {
            setError('Password and Confirm Password are required');
            return false;
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return false;
        }
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');
        
        if (!validateForm()) return;
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/register/`, { email, username, password });
            if (response.status === 201) {
                localStorage.setItem('token', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);

                setIsAuthorized(true);
                navigate('/additional-info', { state: { username, password } });
            }
        } catch (error) {
            if (error.response) {
                // Request made and server responded
                const errors = error.response.data;
                
                if (errors.email) {
                    // Specific error for email
                    setError('Email already in use!');
                } else if (errors.username) {
                    // Specific error for username
                    setError('Username taken!');
                } else if (errors.password) {
                    // Handle password-specific errors
                    const passwordErrors = errors.password.join(' '); 
                    setError(`Password error: ${passwordErrors}`);
                } else {
                    // Generic error message
                    const errorMessage = error.response.data.error || 'An error occurred';
                    setError(errorMessage);
                }
            } else if (!error.response) {
                // The request was made but no response was received
                setError('Server Error, please try again later.');
            } else {
                // Something happened in setting up the request that triggered an Error
                setError('Error: ' + error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && (
                <div className="spinner-overlay">
                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon6.png`} alt="Loading..." className="spinner-logo" />
                </div>
            )}
            <div className="auth-container" style={backgroundStyle}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 centered-content">
                            <form onSubmit={handleSubmit} className="auth-form p-4 shadow rounded">
                                <h2 className="text-center mb-4">
                                    <img src={`${process.env.REACT_APP_GCS_STATIC_IMG_BASE_URL}/mapylon4.png`} alt="" className="mb-2" style={{ width: '50px', height: '65px' }} />
                                    Signup
                                </h2>
                                <p className="text-center">Start writing your adventure with Mapylon.</p>
                                {error && <p style={{ color: 'red' }} className="text-center"><b>{error}</b></p>}
                                <div className="mb-3">
                                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="form-control" />
                                </div>
                                <div className="mb-3">
                                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" className="form-control" />
                                </div>
                                <button type="submit" className="btn btn-primary" disabled={isLoading}>Signup</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;
