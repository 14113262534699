// PhotoInfoPopup.js
import Modal from 'react-bootstrap/Modal';

const PhotoInfoPopup = ({ showModal, closeModal, currentPhoto, handleTitleChange, savePhotoInfo, handleDescriptionChange  }) => {

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault(); // Prevent the default form submission
          savePhotoInfo(); // Save the photo information
        }
    };

    return (
        <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="photo-popup-container">
                    {currentPhoto.file && (
                        <img src={URL.createObjectURL(currentPhoto.file)} alt="" className="naming-photo-popup" />
                    )}
                    </div>
                    <div className="row">
                        <div className="col-12 form-group">
                            <label>Title:</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                value={currentPhoto.title}
                                onChange={handleTitleChange}
                                onKeyDown={handleKeyPress}
                                spellCheck="true"
                            />
                        </div>
                        <div className="col-12 form-group">
                            <label>Description:</label>
                            <textarea 
                                className="form-control" 
                                value={currentPhoto.description}
                                onChange={handleDescriptionChange}
                                onKeyDown={handleKeyPress}
                                rows="3"
                                spellCheck="true"
                            />
                        </div>   
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={closeModal}>Close</button>
                <button className="btn btn-primary" onClick={savePhotoInfo}>Save Changes</button>
            </Modal.Footer>
        </Modal>
    );
  };
  
  export default PhotoInfoPopup;
  